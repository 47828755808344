import React from 'react';
import { Modal } from 'antd';
import {
  IUserAttributeToUpdate,
  IUserAttributesUpdatedResponse,
} from './editForm.interfaces';

export enum LiwwEmailDatabases {
  LIWW = 'liww',
  MAGENTO = 'magento',
  SALESFORCE = 'salesforce',
  MARKETING_CLOUD = 'marketing cloud',
}

export interface ILiwwUpdateResponse {
  action: string;
  data?: IUserAttributesUpdatedResponse;
  message?: string;
  status: number;
}

interface IParsedErrorMessage {
  platform: string;
  errorMessage: string;
}

function renderUpdateError(error: IParsedErrorMessage | string) {
  let displayMessage: string;
  let displayPlatform: string;

  if (typeof error === 'string') {
    displayMessage = error;
    displayPlatform = 'Error';
  } else {
    displayMessage = error.errorMessage;
    displayPlatform = error.platform;
  }

  return (
    <div className="edit-result-wrapper" key="email-update-modal">
      <div className="line">
        <div className={'tag error'}>{displayPlatform}</div>
        <div>{displayMessage}</div>
      </div>
    </div>
  );
}

function renderUpdateSuccess(data: IUserAttributesUpdatedResponse | undefined) {
  return (
    <div className="edit-result-wrapper" key="email-update-modal">
      {data?.attributes?.map(
        (attribute: IUserAttributeToUpdate, index: number) => (
          <>
            <div className="title" key={index}>
              {`The ${attribute.attributeName} has been successfully updated on the following platforms.`}
            </div>
            {attribute.attributeName === 'email' && <EmailUpdate />}
            {attribute.attributeName === 'status' && <StatusUpdate />}
          </>
        ),
      )}
    </div>
  );
}

function EmailUpdate() {
  return (
    <>
      {Object.values(LiwwEmailDatabases).map((database) => (
        <div className="line" key={database}>
          <div className={'tag success'}>{database}</div>
          <div>Email updated successfully.</div>
        </div>
      ))}
    </>
  );
}

function StatusUpdate() {
  return (
    <>
      <div className="line">
        <div className={'tag success'}>Status</div>
        <div>Status updated successfully.</div>
      </div>
    </>
  );
}

function LiwwUpdateInfoModal(modalInfo: ILiwwUpdateResponse) {
  const { data, message } = modalInfo;

  let errorInfo: IParsedErrorMessage | string;

  if (message) {
    try {
      errorInfo = JSON.parse(message);
    } catch (error) {
      errorInfo = message;
    }
  }

  function getContent() {
    if (message) {
      return renderUpdateError(errorInfo);
    }

    return renderUpdateSuccess(data);
  }

  Modal.info({
    title: 'Update result LIWW user',
    content: getContent(),
    className: 'custom-modal-update-liww-user',
  });
}

export default LiwwUpdateInfoModal;
