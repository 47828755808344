import { Button, Modal, notification, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { ComboData } from '../../combos/ComboInterfaces';
import { behaviorCombo } from '../../combosCustom';
import { isConPlatform } from '../../utils';
import { IResource, IResourceDependency } from '../ChallengeInterfaces';
import { ResourceTypeEnum } from '../Enums';

const {
  ACTIVATION_BUTTON,
  CHALLENGE_MATERIALS,
  CHALLENGE_VISIBILITY,
  CHECKOUT,
  WEBEAT_CHECKOUT,
  CHECKOUT_LOTTERY,
  CHECKOUT_DONATION,
  CHECKOUT_PAYMENT_GATEWAY,
  EVENT,
  EXPERIENCE_ACTIVATION,
  GENERIC_BUTTON,
  HIDDEN,
  LINKED_CHALLENGES,
  PEARL,
  QUIZ_TYPEFORM,
  QUIZ,
  QUIZ_DERMO,
  REDEEM_PRODUCT,
  SELL_IN,
  SELL_OUT,
  SHOPPING_CART,
  STEPS,
  TEXT_DURATION,
  TEXT_LINK,
  SURVEY,
  ARN_SURVEY,
  VIDEO,
  VIDEO_SECTION,
  BEAUTY_GUIDE,
  CHECKOUT_BOOKING_TIMIFY,
  EXPERTS_GROUP,
  CLAUSE,
  EXTERNAL_REDEEM_POINTS,
} = ResourceTypeEnum;

interface IDependencyModal {
  resource: IResource;
  challengeResources: IResource[];
  dependencyModalVisibility?: boolean;
  refreshChallengeData: () => Promise<void>;
  setDependencyModalVisibility: (visibility: boolean) => void;
}

const ALLOWED_RESOURCES = [
  //VIDEO_SECTION,
  ACTIVATION_BUTTON,
  CHALLENGE_MATERIALS,
  CHALLENGE_VISIBILITY,
  CHECKOUT,
  WEBEAT_CHECKOUT,
  CHECKOUT_LOTTERY,
  CHECKOUT_DONATION,
  CHECKOUT_PAYMENT_GATEWAY,
  EVENT,
  EXPERIENCE_ACTIVATION,
  GENERIC_BUTTON,
  LINKED_CHALLENGES,
  PEARL,
  QUIZ_TYPEFORM,
  QUIZ,
  QUIZ_DERMO,
  REDEEM_PRODUCT,
  SELL_IN,
  SELL_OUT,
  SELL_OUT,
  SELL_OUT,
  SHOPPING_CART,
  // STEPS,
  // TEXT_DURATION,
  TEXT_LINK,
  SURVEY,
  ARN_SURVEY,
  VIDEO,
  BEAUTY_GUIDE,
  CLAUSE,
  EXTERNAL_REDEEM_POINTS,
];

const getAvailableResource = (id: number, challengeResources: IResource[]) => {
  let availableArr: IResource[] = [];
  challengeResources.forEach((_resource) => {
    const {
      idResource,
      indResource,
      status,
      idResourceType: { idResourceType },
    } = _resource;
    if (
      indResource === null &&
      idResource !== id &&
      status &&
      ALLOWED_RESOURCES.includes(idResourceType)
    ) {
      availableArr.push(_resource);
    }
  });
  return availableArr;
};

export const getInitialParentStatus = async (
  depResource: number | undefined,
  availableResources: IResource[],
) => {
  if (!depResource) return [];
  const parentResource = availableResources.find(
    ({ idResource }) => idResource.toString() === depResource?.toString(),
  );

  if (!parentResource) return [];

  return await getComboType(parentResource.idResourceType.idResourceType);
};

export const getComboType = async (option: ResourceTypeEnum) => {
  let comboName = '';
  switch (option) {
    case QUIZ:
      comboName = 'quizStatus';
      break;
    case GENERIC_BUTTON:
    case ACTIVATION_BUTTON:
    case BEAUTY_GUIDE:
    case CHECKOUT:
    case WEBEAT_CHECKOUT:
    case CHECKOUT_LOTTERY:
    case CHECKOUT_DONATION:
    case CHECKOUT_BOOKING_TIMIFY:
    case CHECKOUT_PAYMENT_GATEWAY:
    case EXPERIENCE_ACTIVATION:
    case QUIZ_TYPEFORM:
    case QUIZ_DERMO:
    case REDEEM_PRODUCT:
    case SELL_IN:
    case SHOPPING_CART:
    case STEPS:
    case SURVEY:
    case ARN_SURVEY:
    case HIDDEN:
    case TEXT_DURATION:
    case TEXT_LINK:
    case VIDEO:
    case VIDEO_SECTION:
    case CLAUSE:
    case EXTERNAL_REDEEM_POINTS:
      comboName = 'genericResourceStatus';
      break;
    case EXPERTS_GROUP:
      comboName = 'iconType';
      break;
    case EVENT:
      comboName = 'eventStatus';
      break;
    case CHALLENGE_MATERIALS:
    case LINKED_CHALLENGES:
      comboName = 'partialResourceStatus';
      break;
    case CHALLENGE_VISIBILITY:
      comboName = 'uploadMediaStatus';
      break;
    case SELL_OUT:
      comboName = 'sellOutStatus';
      break;
    case PEARL:
      comboName = 'pearlStatus';
      break;
    default:
      break;
  }

  if (!comboName) return [];

  const response = await api.getCombo({ id: comboName });
  return response.data?.length ? response.data : [];
};

export default function AddDependencyModal({
  resource,
  challengeResources,
  dependencyModalVisibility,
  refreshChallengeData,
  setDependencyModalVisibility,
}: IDependencyModal) {
  const {
    depResource,
    depResourceStatus,
    behavior,
    idResource,
    resourceDependencyList,
  } = resource;
  const [availableResources, setAvailableResources] = useState<IResource[]>([]);
  const [selectedBehavior, setSelectedBehavior] = useState<string | null>(null);

  // TODO - remove when multi-dependencies is implemented
  const [parentStatus, setParentStatus] = useState<ComboData[]>([]);
  const [selectedParent, setSelectedParent] = useState<string | null>(null);
  const [selectedParentStatus, setSelectedParentStatus] = useState<
    string | null
  >(null);

  const [dependenciesList, setDependenciesList] = useState<
    IResourceDependency[]
  >([]);

  const { formatMessage } = useIntl();

  useEffect(() => {
    async function setModalInitialState() {
      const availableResources = getAvailableResource(
        idResource,
        challengeResources,
      );

      setAvailableResources(availableResources);
      setSelectedBehavior(behavior?.toString() || null);

      if (!isConPlatform()) {
        setSelectedParent(depResource?.toString() || null);
        setSelectedParentStatus(depResourceStatus?.toString() || null);

        const parents = await getInitialParentStatus(
          depResource,
          availableResources,
        );
        setParentStatus(parents);
      } else {
        const dependencyList =
          resourceDependencyList?.map((el) => {
            return { ...el, idResource };
          }) || [];

        for (let i = 0; i < dependencyList.length; i++) {
          const selectedResource = availableResources.find(
            ({ idResource }) => idResource === dependencyList[i].idDependency,
          );
          if (!selectedResource) return;

          const newOptions = await getComboType(
            selectedResource.idResourceType.idResourceType,
          );
          dependencyList[i].options = newOptions;
        }

        setDependenciesList(dependencyList);
      }
    }

    if (dependencyModalVisibility) setModalInitialState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyModalVisibility, resource]);

  // SINGLE DEPENDENCY HANDLERS
  const handleSelectParent = async (value: string) => {
    const parent = availableResources.find(
      ({ idResource }) => +idResource === +value,
    );

    if (parent)
      setParentStatus(await getComboType(parent.idResourceType.idResourceType));
  };

  const handleSelectParentStatus = (option: string) => {
    setSelectedParentStatus(option);
  };

  const handleSelectBehavior = (option: string) => {
    setSelectedBehavior(option);
  };

  // MULTI DEPENDENCY HANDLERS
  const handleSelectMultiParent = async (value: any, index: number) => {
    const selectedResource = availableResources.find(
      ({ idResource }) => idResource === Number(value),
    );

    if (!selectedResource) return;
    const newOptions = await getComboType(
      selectedResource.idResourceType.idResourceType,
    );

    setDependenciesList((current) => {
      const newList = [...current];
      for (let i = 0; i < newList.length; i++) {
        if (index !== i) continue;

        newList[i].idDependency = Number(value);
        newList[i].options = newOptions;
      }

      return newList;
    });
  };

  const handleSelectMultiParentStatus = (value: any, index: number) => {
    setDependenciesList((current) => {
      const newList = [...current];
      newList[index].trigger = value;
      return newList;
    });
  };

  const handleRemoveDependency = (index: number) => {
    const newDependenciesList = [...dependenciesList];
    newDependenciesList?.splice(index, 1);
    setDependenciesList(newDependenciesList);
  };

  // SHARED HANDLERS
  const handleClose = () => {
    setSelectedParent(null);
    setSelectedParentStatus(null);
    setSelectedBehavior(null);
    setDependencyModalVisibility(false);
  };

  const handleSubmit = async () => {
    try {
      let data: any = {
        idResource,
        depResource: selectedParent,
        depResourceStatus: selectedParentStatus,
        behavior: selectedBehavior,
      };

      if (isConPlatform()) {
        const isCorrectForm = dependenciesList?.every(
          ({ idResource, idDependency, trigger }) =>
            Boolean(idResource) && Boolean(idDependency) && Boolean(trigger),
        );

        if (!isCorrectForm || !selectedBehavior) {
          notification.error({
            message: 'Some configuration is missing.',
          });
          return;
        }

        data = {
          idResource,
          dependenciesList: dependenciesList.map((d) => {
            const { options, ...newDependency } = d;
            return newDependency;
          }),
          behavior: selectedBehavior,
        };
      }

      const response = await api.putDataCall({
        dataPath: apiPaths.CALL.RESOURCE_DEPENDENCY,
        data,
        callConfig: {},
      });

      if (response?.status === 200) refreshChallengeData();

      handleClose();
    } catch (err) {
      console.group(err);
    }
  };

  const conContent = (
    <>
      <Button
        onClick={() => {
          setDependenciesList((current) => [
            ...current,
            {
              idResource: resource.idResource,
              idDependency: null,
              trigger: null,
            },
          ]);
        }}
        style={{ marginBottom: '20px' }}>
        {formatMessage({ id: 'add_resource_dependency' })}
      </Button>
      {dependenciesList?.length > 0 && (
        <Select
          value={selectedBehavior?.toString()}
          onSelect={(value: any) => handleSelectBehavior(value)}
          style={{ float: 'right', width: '200px' }}>
          {behaviorCombo.map(({ value, description }) => (
            <Select.Option value={value.toString()} key={value.toString()}>
              {description}
            </Select.Option>
          ))}
        </Select>
      )}
      {dependenciesList?.map((dependency, index) => (
        <Row style={{ minHeight: '20vh' }} key={index}>
          {formatMessage({ id: 'parent_resource' })}
          <Select
            allowClear
            value={dependency.idDependency?.toString()}
            onSelect={(value: string) => {
              handleSelectMultiParent(value, index);
            }}>
            {availableResources.map(
              ({
                idResource,
                name,
                idResourceType: { name: typeName },
              }: IResource) => (
                <Select.Option
                  value={idResource.toString()}
                  key={idResource.toString()}
                  disabled={Boolean(
                    dependenciesList.find(
                      ({ idDependency: dependencyResource }) =>
                        dependencyResource === idResource,
                    ),
                  )}>
                  {typeName} : {_.capitalize(name)}
                </Select.Option>
              ),
            )}
          </Select>
          {formatMessage({ id: 'parent_status' })}
          <Select
            disabled={!Boolean(dependenciesList[index].idDependency)}
            value={dependenciesList[index].trigger?.toString()}
            onSelect={(value: string) => {
              handleSelectMultiParentStatus(value, index);
            }}>
            {dependency.options?.map((parent: ComboData) => (
              <Select.Option value={parent.value.toString()} key={parent.value}>
                {parent.description}
              </Select.Option>
            ))}
          </Select>
          <Button
            block
            type="danger"
            icon="delete-outlined"
            onClick={() => handleRemoveDependency(index)}
            style={{ marginTop: '10px', marginBottom: '40px' }}>
            {formatMessage({ id: 'remove_resource_dependency' })}
          </Button>
        </Row>
      ))}
    </>
  );

  return (
    <Modal
      visible={dependencyModalVisibility}
      onCancel={handleClose}
      onOk={handleSubmit}>
      <Row style={{ marginBottom: '60px', fontSize: '1.6rem' }}>
        {formatMessage({ id: 'set_dependency' })}
      </Row>
      {isConPlatform() ? (
        conContent
      ) : (
        <Row style={{ minHeight: '20vh' }}>
          {formatMessage({ id: 'parent_resource' })}
          <Select
            allowClear
            value={selectedParent?.toString()}
            onChange={(value: string) => {
              if (value) {
                setSelectedParent(value);
              } else {
                setSelectedBehavior(null);
                setSelectedParent(null);
                setSelectedParentStatus(null);
                setParentStatus([]);
              }
            }}
            onSelect={(value: string) => {
              handleSelectParent(value);
            }}>
            {availableResources?.map(
              ({
                idResource,
                name,
                idResourceType: { name: typeName },
              }: IResource) => (
                <Select.Option
                  value={idResource.toString()}
                  key={idResource.toString()}>
                  {typeName} : {_.capitalize(name)}
                </Select.Option>
              ),
            )}
          </Select>
          {formatMessage({ id: 'parent_status' })}
          <Select
            disabled={parentStatus?.length === 0}
            value={selectedParentStatus?.toString()}
            onSelect={(value: string) => {
              handleSelectParentStatus(value);
            }}>
            {parentStatus.map((parent: ComboData) => (
              <Select.Option value={parent.value.toString()} key={parent.value}>
                {parent.description}
              </Select.Option>
            ))}
          </Select>
          {formatMessage({ id: 'behavior' })}
          <Select
            value={selectedBehavior?.toString()}
            disabled={selectedParentStatus === null}
            onSelect={(value: string) => {
              handleSelectBehavior(value);
            }}>
            {behaviorCombo.map((val) => (
              <Select.Option value={val.value.toString()} key={val.value}>
                {val.description}
              </Select.Option>
            ))}
          </Select>
        </Row>
      )}
    </Modal>
  );
}
