import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConPlatform } from '../utils';

const MenuDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'menumenuSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'menu.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },

        {
          title: formatMessage({ id: 'menu.parent' }),
          key: 'parentId',
          type: 'combo',
          operand: 'equal',
          min: 0,
          comboId: 'menu',
          length: 0,
          mandatory: false,
          disabled: false,
        },

        {
          title: formatMessage({ id: 'menu.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          initialValue: 'true',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'menumenuList',
        rowKey: 'idMenu',
        panelHeader: formatMessage({ id: 'menu.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'name',
        // customButtons: ['menuI18nButton'],
      },
      fields: [
        {
          title: formatMessage({ id: 'userMenu.id' }),
          key: 'idMenu',
          position: 0,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'userMenu.order' }),
          key: 'order',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.route' }),
          key: 'route',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.type' }),
          key: 'type',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'menuType',
          width: MID,
        },
        {
          title: formatMessage({ id: 'userMenu.idContent' }),
          key: 'contentPageId',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'contentPage',
          width: MID,
        },
        {
          title: formatMessage({ id: 'userMenu.parentId' }),
          key: 'parentId.idMenu',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'bomenu',
          width: SMALL,
        },
        isConPlatform() && {
          title: 'Role',
          key: 'menuRolesConcat',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'menumenuEdit',
        panelHeader: formatMessage({ id: 'menu.edit' }),
        primaryKey: 'idMenu',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },

                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'order',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 3,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'absolute',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'route',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'disabled',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'visible',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'contentPageId',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: false,
                  width: 6,
                },
                !isConPlatform() && {
                  key: 'specialties',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: '',
          key: 'privateFooterOrder',
          type: 'combo',
          comboId: 'privateFooterOrder',
        },
        {
          title: '',
          key: 'publicFooterOrder',
          type: 'combo',
          comboId: 'publicFooterOrder',
        },
        {
          title: '',
          key: 'menuPages',
          type: 'combo',
          comboId: 'menuPages',
        },
        {
          title: formatMessage({ id: 'userMenu.disabled' }),
          key: 'disabled',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'type',
                value: '2',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '4',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '6',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          title: 'Absolute route',
          key: 'absolute',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          hideClear: true,
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'type',
                value: '2',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '4',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '6',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'userMenu.order' }),
          key: 'order',
          type: 'customField',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'userMenu.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.parentId' }),
          key: 'parentId',
          type: 'combo',
          comboId: 'menuParents',
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          parentValue: '',
          behaviours: [
            [
              {
                key: 'type',
                value: '4',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '6',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                type: 'combo',
                key: 'type',
                params: true,
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'userMenu.route' }),
          key: 'route',
          type: 'customField',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          mandatory: true,
          initialDisabled: true,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'id_menu',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
            [
              {
                key: 'type',
                operand: 'notEmpty',
                type: 'visible',
              },
            ],
          ],
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'userMenu.visible' }),
          key: 'visible',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'type',
                value: '2',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '4',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '6',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'userMenu.type' }),
          key: 'type',
          type: 'combo',
          comboId: 'menuType',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.idContent' }),
          key: 'contentPageId',
          type: 'combo',
          comboId: 'contentPage',
          mandatory: false,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'type',
                value: '2',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '4',
                operand: 'like',
                type: 'visible',
              },
            ],
            [
              {
                key: 'type',
                value: '6',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        !isConPlatform() && {
          title: formatMessage({ id: 'userMenu.idSpecialty' }),
          key: 'specialties',
          type: 'combo',
          comboId: 'specialty',
          multiSelectId: 'idSpecialty',
          multiSelect: true,
          placeholder: formatMessage({
            id: 'userMenu.idSpecialty.placeholder',
          }),
          tooltip: formatMessage({ id: 'userMenu.idSpecialty.tooltip' }),
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'type',
                value: '2',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'MenuDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default MenuDashboard;
