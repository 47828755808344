import { Col, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow } from '../../app/AppInterfaces';
import { ComboData } from '../../combos/ComboInterfaces';
import config from '../../config';
import { ReducersState } from '../../reducers';
import '../Challenge.css';
import { SetChallengeLoading } from '../challengeActions';
import { IResource, IWebeatResource } from '../ChallengeInterfaces';
import { ResourceTypeEnum } from '../Enums';
import {
  getValidScore,
  getWebeatAccumulateScore,
  getWebeatMaxChildScore,
  getWebeatValidScore,
} from './utils';
import WebeatAdminPointsForm from './WebeatAdminPointsForm';
import { isWebeatPlatform } from '../../utils';

const {
  CHECKOUT,
  CHECKOUT_PRODUCT,
  WEBEAT_CHECKOUT,
  WEBEAT_CHECKOUT_PRODUCT,
  EVENT,
  QUESTION,
  QUIZ,
  CHALLENGE_MATERIALS,
  MATERIAL,
  SELL_OUT,
  SELL_OUT_POINT_RANGE,
  QUIZ_DERMO,
  REDEEM_PRODUCT,
} = ResourceTypeEnum;

const isWebeat = isWebeatPlatform();

interface IWebeatAdminPoints {
  pointsModalVisible: boolean;
  setPointsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: IRow;
  constructResources: (resources: IRow) => IResource[];
  setLoading: (
    isLoading: boolean,
  ) => (dispatch: Dispatch<SetChallengeLoading>) => void;
  isLoading: boolean;
  combos: any;
  refreshChallengeData: () => Promise<void>;
}

const WebeatAdminPoints = (props: IWebeatAdminPoints) => {
  const {
    isLoading,
    pointsModalVisible,
    selectedRow,
    setLoading,
    setPointsModalVisible,
    combos,
  } = props;
  const [resources, setResources] = useState<
    null | IResource[] | IWebeatResource[]
  >(null);
  const [challengePoints, setChallengePoints] = useState<number>(
    selectedRow?.score || 0,
  );
  const [pointsHaveChanged, setPointsHaveChanged] = useState(false);
  const intl = useIntl();

  const specialtyCombo: ComboData[] =
    combos.combos.challengechallengeEdit?.specialty?.specialty?.data || [];
  const { idChallengeType } = selectedRow?.idChallengeType;

  useEffect(() => {
    if (pointsModalVisible) {
      setResources(selectedRow.resourceList);
    }
  }, [pointsModalVisible, selectedRow.resourceList]);

  const handleCloseModal = () => {
    if (pointsHaveChanged)
      Modal.confirm({
        title: intl.formatMessage({
          id: 'pop.title.changes-will-not-be-saved-are-you-sure',
        }),
        okText: intl.formatMessage({ id: 'pop.accept' }),
        cancelText: intl.formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          setPointsModalVisible(false);
          setPointsHaveChanged(false);
        },
        onCancel() {},
      });
    else setPointsModalVisible(false);
  };

  const handleSavePoints = async () => {
    if (!pointsHaveChanged) return;

    setLoading(true);

    const updateResourcesUrl = apiPaths.WEBEAT.CALL.ASSIGN_POINTS.replace(
      '{idChallenge}',
      selectedRow?.idChallenge,
    );
    try {
      await api.putDataCall({
        dataPath: updateResourcesUrl,
        data: { score: challengePoints, resources },
        callConfig: {},
      });

      await props.refreshChallengeData();
      setPointsModalVisible(false);
      setPointsHaveChanged(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getChallengeTotalPoints = (resourceList: IWebeatResource[]): number => {
    let totalScore = 0;
    let higherEventScore = 0;
    let higherSurveyScore = 0;
    let minimumRedeemScore = 0;
    let previousScoreIsZero = false;

    resourceList.forEach((_resource: IWebeatResource) => {
      const { idResourceType } = _resource.idResourceType;
      switch (idResourceType) {
        case EVENT:
          let eventScore = 0;
          if (_resource.status) {
            _resource.resourceList?.forEach((eventResource) => {
              eventScore += getValidScore(eventResource);
            });
            if (Math.abs(eventScore) > Math.abs(higherEventScore))
              higherEventScore = eventScore;
          }
          break;
        case QUIZ:
        case QUIZ_DERMO:
          totalScore += getWebeatAccumulateScore(_resource, QUESTION);
          break;
        case SELL_OUT:
          totalScore += getWebeatAccumulateScore(
            _resource,
            SELL_OUT_POINT_RANGE,
          );
          break;
        case CHALLENGE_MATERIALS:
          totalScore += getWebeatAccumulateScore(
            _resource,
            CHALLENGE_MATERIALS,
          );
          break;
        case CHECKOUT:
        case WEBEAT_CHECKOUT:
          totalScore += getWebeatMaxChildScore(_resource);
          break;
        case MATERIAL:
        case CHECKOUT_PRODUCT:
        case WEBEAT_CHECKOUT_PRODUCT:
          break;
        case REDEEM_PRODUCT:
          if (isWebeat && _resource.status) {
            if (_resource.score || _resource.score === 0) {
              if (previousScoreIsZero) {
                minimumRedeemScore = 0;
              } else {
                if (
                  Math.abs(_resource.score) < Math.abs(minimumRedeemScore) ||
                  minimumRedeemScore === 0
                )
                  minimumRedeemScore = _resource.score;
                if (_resource.score === 0) {
                  previousScoreIsZero = true;
                }
              }
            }
          }
          break;
        default:
          totalScore += getWebeatValidScore(_resource);
          break;
      }
    });
    return (
      totalScore +
      higherEventScore +
      higherSurveyScore +
      minimumRedeemScore +
      (challengePoints !== null ? challengePoints : 0)
    );
  };

  return (
    selectedRow && (
      <Modal
        className="pointsModal"
        style={{
          minWidth:
            window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '800px',
          maxHeight:
            window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '60vw',
          minHeight:
            window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '400px',
        }}
        visible={pointsModalVisible}
        onOk={handleSavePoints}
        onCancel={handleCloseModal}
        cancelText={intl.formatMessage({ id: 'form.edit.cancel' })}
        okText={intl.formatMessage({ id: 'form.save' })}>
        <Row>
          <Row className="pointsModal__title">
            {intl.formatMessage({ id: 'points-admin.title' })}
          </Row>
          {isLoading ? (
            <Row className="pointsModal__loading" />
          ) : (
            <>
              {/* !isConPlatform() && (
                        <Row className="pointsModal__likePoints">
                          <Col xs={16}>
                            {intl.formatMessage({ id: 'points-admin.like' })}
                          </Col>
                          <Col xs={8}>
                            <InputNumber
                                style={{
                                  backgroundColor: 'transparent',
                                  fontWeight: 700,
                                  width: '98%',
                                  color: 'black',
                                }}
                                value={likePoints}
                                size="small"
                                onChange={(value: number | undefined) =>
                                    handleEditLikePoints(value)
                                }
                            />
                          </Col>
                        </Row>
                    ) */}
              <Row className="pointsModal__totalPoints">
                <Col xs={16}>
                  {intl.formatMessage({ id: 'points-admin.total' })}
                </Col>
                <Col xs={8}>
                  <InputNumber
                    style={{
                      backgroundColor: 'transparent',
                      fontWeight: 700,
                      width: '98%',
                      color: 'black',
                    }}
                    disabled
                    value={
                      resources !== null
                        ? getChallengeTotalPoints(
                            resources as IWebeatResource[],
                          )
                        : 0
                    }
                    size="small"
                  />
                </Col>
              </Row>
              <Row className="pointsModal__totalPoints">
                <Col xs={16}>
                  {intl.formatMessage({ id: 'points-admin.challenge' })}
                </Col>
                <Col xs={8}>
                  <InputNumber
                    style={{
                      backgroundColor: 'transparent',
                      fontWeight: 700,
                      width: '98%',
                      color: 'black',
                    }}
                    onChange={(value: number | undefined) => {
                      setPointsHaveChanged(true);
                      setChallengePoints(value || 0);
                    }}
                    value={challengePoints !== null ? challengePoints : 0}
                    size="small"
                  />
                </Col>
              </Row>
              {resources && (
                <WebeatAdminPointsForm
                  {...{
                    specialtyCombo,
                    resourceList: resources as IResource[],
                    idChallengeType,
                    setResources,
                    setPointsHaveChanged,
                  }}
                />
              )}
            </>
          )}
        </Row>
      </Modal>
    )
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos,
  };
};

export default connect(mapStateToProps)(WebeatAdminPoints);
