import { Button, Input, Modal, Row } from 'antd';
import React, { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import config from '../../config';
import {
  IResource,
  IResourceDetail,
  IResourceTree,
} from '../ChallengeInterfaces';
import { ResourceTypeEnum } from '../Enums';
import BasicResourceModal from './BasicModalResource/BasicResourceModal';
import BeautyGuide from './BeautyGuide/BeautyGuide';
import ChallengeMaterials from './ChallengeMaterials/ChallengeMaterials';
import Checkout from './Checkout/Checkout';
import CheckoutBookingTimify from './CheckoutBookingTimify/CheckoutBookingTimify';
import CheckoutDonation from './CheckoutDonation/CheckoutDonation';
import Event from './Event/Event';
import Hidden from './Hidden/Hidden';
import IncludedItemsList from './IncludedItemsList/IncludedItemsList';
import LinkedChallenges from './LinkedChallenges/LinkedChallenges';
import CheckoutPaymentGateway from './CheckoutPaymentGateway/CheckoutPaymentGateway';
import Quiz from './Quiz/Quiz';
import './Resource.css';
import {
  isAddComponentDisabled,
  isOnlyOneExpertsGroupsInEvent,
} from './Resource.utils';
import {
  blankCheckoutDonation,
  blankCheckoutProduct,
  blankCheckoutSupplementaryProduct,
  blankIncludedItem,
  blankLinkedChallenge,
  blankMaterial,
  blankQuestion,
  blankSellInImagesRange,
  blankSellOutPoitnsRange,
  blankShoppingGroup,
  blankSpeaker,
  blankExpertsGroup,
  blankStepsDetails,
  blankTextLinkDetails,
  blankCheckoutPaymentGatewayProduct,
  blankWebeatCheckoutProduct,
  blankExternalRedeemPointsPoints,
} from './ResourceConfigs';
import SellIn from './SellIn/SellIn';
import SellOut from './SellOut/SellOut';
import ShoppingCart from './ShoppingCart/ShoppingCart';
import Steps from './Steps/Steps';
import TextDuration from './TextDuration/TextDuration';
import TextLink from './TextLink/TextLink';
import ResourceChildren from './TreeResource/ResourceChild';
import VideoSection from './VideoSection';
import WebeatCheckout from './WebeatCheckout/WebeatCheckout';
import ExternalRedeemPoints from './ExternalRedeemPoints/ExternalRedeemPoints';

const {
  BEAUTY_GUIDE,
  CHALLENGE_MATERIALS,
  CHECKOUT,
  CHECKOUT_DONATION,
  CHECKOUT_SUPPLEMENTARY_PRODUCT,
  CHECKOUT_PAYMENT_GATEWAY,
  EVENT,
  HIDDEN,
  LINKED_CHALLENGES,
  QUIZ,
  QUIZ_DERMO,
  SELL_IN,
  SELL_OUT,
  STEPS,
  TEXT_LINK,
  TEXT_DURATION,
  SHOPPING_CART,
  VIDEO_SECTION,
  INCLUDED_ITEMS_LIST,
  CHECKOUT_BOOKING_TIMIFY,
  EXPERTS_GROUP,
  WEBEAT_CHECKOUT,
  EXTERNAL_REDEEM_POINTS,
} = ResourceTypeEnum;

const ResourceModal: FC<IResourceTree> = (props) => {
  const { formatMessage } = useIntl();
  const {
    editResource,
    form,
    handleDeleteDetail,
    handleAddDetail,
    handleAddParentResource,
    handleDeleteImages,
    handleEditTitle,
    handleResetResource,
    handleSaveResource,
    renderExtraFields,
    resource: {
      idResource,
      idResourceType: { idResourceType = 1 } = {},
      name,
      resourceList,
    },
  } = props;

  let resourceComponent: JSX.Element | null = null;

  let addParentMessage: string = '';
  let parentResource:
    | ((
        idResource: number,
        resourceList?: IResource[] | undefined,
      ) => IResource)
    | null = null;

  let parentDetail:
    | ((
        idResource: number,
        resourceDetailList?: IResourceDetail | undefined,
      ) => IResourceDetail)
    | null = null;

  let addSupplementaryMessage: string = '';
  let supplementaryProductDetail:
    | ((
        idResource: number,
        resourceList?: IResource[] | undefined,
      ) => IResource)
    | null = null;

  switch (idResourceType) {
    // TODO: Remove custom component and use "BasicResourceModal" to get custom combo data
    case BEAUTY_GUIDE:
      resourceComponent = <BeautyGuide {...props} />;
      break;
    case CHECKOUT:
      resourceComponent = <Checkout {...props} />;
      addParentMessage = 'challenge.addCheckoutProduct';
      parentResource = blankCheckoutProduct;
      addSupplementaryMessage = 'challenge.addCheckoutSupplementaryProduct';
      supplementaryProductDetail = blankCheckoutSupplementaryProduct;
      break;
    case CHECKOUT_PAYMENT_GATEWAY:
      resourceComponent = <CheckoutPaymentGateway {...props} />;
      if (resourceList?.[0] === undefined) {
        addParentMessage = 'challenge.addCheckoutProduct';
        parentResource = blankCheckoutPaymentGatewayProduct;
      }
      break;
    case CHECKOUT_DONATION:
      resourceComponent = <CheckoutDonation {...props} />;
      addParentMessage = 'challenge.addIncludedPoints';
      parentResource = blankCheckoutDonation;
      break;
    case EVENT:
      resourceComponent = <Event {...props} />;
      if (!isOnlyOneExpertsGroupsInEvent(resourceList)) {
        addParentMessage = '+ Add Experts group';
        parentResource = blankExpertsGroup;
      }
      break;
    case HIDDEN:
      resourceComponent = <Hidden {...props} />;
      break;
    case QUIZ:
    case QUIZ_DERMO:
      resourceComponent = <Quiz {...props} />;
      addParentMessage = 'challenge.addQuestion';
      parentResource = blankQuestion;
      break;
    case SHOPPING_CART:
      resourceComponent = <ShoppingCart {...props} />;
      addParentMessage = 'challenge.addShoppingGroup';
      parentResource = blankShoppingGroup;
      break;
    case CHALLENGE_MATERIALS:
      resourceComponent = <ChallengeMaterials {...props} />;
      parentResource = blankMaterial;
      addParentMessage = 'challenge.addMaterial';
      break;
    case LINKED_CHALLENGES:
      resourceComponent = <LinkedChallenges {...props} />;
      parentDetail = blankLinkedChallenge;
      addParentMessage = 'challenge.addChallenge';
      break;
    case SELL_IN:
      resourceComponent = <SellIn {...props} />;
      parentResource = blankSellInImagesRange;
      addParentMessage = 'challenge.addImageRangePoints';
      break;
    case SELL_OUT:
      resourceComponent = <SellOut {...props} />;
      parentResource = blankSellOutPoitnsRange;
      addParentMessage = 'challenge.addRangePoints';
      break;
    case STEPS:
      resourceComponent = <Steps {...props} />;
      parentResource = blankStepsDetails;
      addParentMessage = 'challenge.addStepsContent';
      break;
    case TEXT_LINK:
      resourceComponent = <TextLink {...props} />;
      parentResource = blankTextLinkDetails;
      addParentMessage = 'challenge.addTextLink';
      break;
    case INCLUDED_ITEMS_LIST:
      resourceComponent = <IncludedItemsList {...props} />;
      parentResource = blankIncludedItem;
      addParentMessage = 'challenge.addIncludedItem';
      break;
    case VIDEO_SECTION:
      resourceComponent = <VideoSection {...props} />;
      break;
    case TEXT_DURATION:
      resourceComponent = <TextDuration {...props} />;
      break;
    case CHECKOUT_BOOKING_TIMIFY:
      resourceComponent = <CheckoutBookingTimify {...props} />;
      break;
    case EXPERTS_GROUP:
      addParentMessage = 'challenge.addSpeaker';
      parentResource = blankSpeaker;
      resourceComponent = <BasicResourceModal {...props} />;
      break;
    case WEBEAT_CHECKOUT:
      resourceComponent = <WebeatCheckout {...props} />;
      addParentMessage = 'challenge.addCheckoutProduct';
      parentResource = blankWebeatCheckoutProduct;
      break;
    case EXTERNAL_REDEEM_POINTS:
      addParentMessage = 'external-redeem-points.addPoints';
      parentResource = blankExternalRedeemPointsPoints;
      resourceComponent = <ExternalRedeemPoints {...props} />;
      break;
    default:
      resourceComponent = <BasicResourceModal {...props} />;
      break;
  }

  if (!resourceComponent) return <Fragment />;

  return (
    <Fragment key={idResource}>
      <Modal
        className="Quiz__modal"
        visible={editResource}
        centered
        closable
        onCancel={() => handleResetResource()}
        width={window.innerWidth < config.BREAKPOINTS.MD ? '100%' : '70%'}
        footer={null}>
        <Row
          className="Quiz__title"
          type="flex"
          justify="space-between"
          align="middle">
          <Input
            className="Quiz__editableTitle"
            defaultValue={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditTitle({ value: e.target.value })
            }
          />
          <div>
            <Button onClick={() => handleResetResource()}>
              {formatMessage({ id: 'form.edit.cancel' })}
            </Button>
            <Button
              type="primary"
              className="Quiz__modal--saveButton"
              onClick={() => handleSaveResource(true)}>
              {formatMessage({ id: 'form.save' })}
            </Button>
          </div>
        </Row>
        <Row className="Quiz__content">
          <Row type="flex" gutter={[24, 0]}>
            {renderExtraFields}
            {resourceComponent}
            <ResourceChildren
              handleDeleteDetail={handleDeleteDetail}
              handleAddDetail={handleAddDetail}
              renderExtraFields={renderExtraFields}
              editResource={editResource}
              form={form}
              handleDeleteImages={handleDeleteImages}
              idResourceType={idResourceType as ResourceTypeEnum}
              mainIdResource={idResource}
              resourceList={resourceList}
              values={props.values}
            />
          </Row>
          {editResource && (
            <>
              <div />
              <Row
                className="Quiz__addQuestion"
                type="flex"
                justify="space-around">
                {(parentResource || parentDetail) && (
                  <Button
                    disabled={isAddComponentDisabled(
                      form.getFieldValue(`activate${idResource}`),
                      resourceList,
                    )}
                    type="primary"
                    onClick={() => {
                      idResourceType === LINKED_CHALLENGES
                        ? handleAddDetail(parentDetail!(idResource))
                        : handleAddParentResource(
                            parentResource!(idResourceType, resourceList),
                          );
                    }}>
                    {formatMessage({
                      id: addParentMessage,
                    })}
                  </Button>
                )}
                {supplementaryProductDetail && (
                  <Button
                    disabled={isAddComponentDisabled(
                      form.getFieldValue(
                        `activate${CHECKOUT_SUPPLEMENTARY_PRODUCT}`,
                      ),
                      resourceList,
                    )}
                    type="primary"
                    onClick={() => {
                      idResourceType === CHECKOUT_SUPPLEMENTARY_PRODUCT
                        ? handleAddDetail(parentDetail!(idResource))
                        : handleAddParentResource(
                            supplementaryProductDetail!(
                              idResourceType,
                              resourceList,
                            ),
                          );
                    }}>
                    {formatMessage({
                      id: addSupplementaryMessage,
                    })}
                  </Button>
                )}
              </Row>
            </>
          )}
        </Row>
      </Modal>

      {!editResource && (
        <Row className="Quiz" type="flex" gutter={[24, 0]}>
          {renderExtraFields}
          {resourceComponent}
          <ResourceChildren
            renderExtraFields={renderExtraFields}
            editResource={editResource}
            form={form}
            handleDeleteImages={handleDeleteImages}
            idResourceType={idResourceType}
            mainIdResource={idResource}
            resourceList={resourceList}
            values={props.values}
          />
        </Row>
      )}
    </Fragment>
  );
};

export default ResourceModal;
