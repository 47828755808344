import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConPlatform } from '../utils';

const ContentPageDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;
  const customButtonStaticPage = isConPlatform()
    ? null
    : ['staticPagePreviewButton', 'duplicateStaticPageButton'];
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'contentPagecontentPageSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'slug',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'contentPage.id' }),
          key: 'idContentPage',
          type: 'number',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'contentPage.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'contentPage.slug' }),
          key: 'slug',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'contentPage.type' }),
          key: 'type',
          type: 'combo',
          operand: 'equal',
          comboId: 'contentPageType',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'contentPagecontentPageList',
        rowKey: 'idContentPage',
        panelHeader: 'Pages List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'slug',
        customButtons: customButtonStaticPage,
      },
      fields: [
        {
          title: formatMessage({ id: 'contentPage.id' }),
          key: 'idContentPage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'contentPage.slug' }),
          key: 'slug',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'contentPage.type' }),
          key: 'type',
          position: 3,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          width: SMALL,
          render: 'combo',
          comboId: 'contentPageType',
        },
        {
          title: formatMessage({ id: 'challenge.publicPage' }),
          key: 'publicPage',
          render: 'comboCustom',
          comboId: 'optional',
          position: 4,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.header' }),
          key: 'header',
          render: 'comboCustom',
          comboId: 'optional',
          position: 5,
          mustRender: true,
          align: 'center',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.footer' }),
          key: 'footer',
          render: 'comboCustom',
          comboId: 'optional',
          position: 6,
          mustRender: true,
          align: 'center',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.idRoleType' }),
          key: 'idRoleType.idRoleType',
          render: 'combo',
          comboId: 'roleType',
          position: 7,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        !isConPlatform() && {
          title: formatMessage({ id: 'contentPage.idSpecialty' }),
          key: 'idSpecialty',
          render: 'combo',
          comboId: 'specialty',
          position: 8,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 9,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'contentPagecontentPageEdit',
        panelHeader: 'Page Edit',
        primaryKey: 'idContentPage',
        tabIndex: 0,
        isPageContent: true,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idContentPage',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'roleTypeKey',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'idSpecialty',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'contentPage.id' }),
          key: 'idContentPage',
          type: 'number',
          unique: true,
          min: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'contentPage.type' }),
          key: 'type',
          type: 'combo',
          comboId: 'contentPageType',
        },
        {
          key: 'roleTypeKey',
          type: 'combo',
          comboId: 'roleType',
        },
        {
          key: 'idSpecialty',
          type: 'combo',
          comboId: 'specialty',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'ContentPageDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ContentPageDashboard;
