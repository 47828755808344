import React from 'react';
import { Button, Switch, Row, Popconfirm, Icon, Modal } from 'antd';
import { isEmpty } from 'lodash';
import config from '../config';

import { appComponents } from '../components';
import { checkBehaviour } from '../forms/behaviourUtils';
import customButtonsFile from '../extensions/customButtons';
import DataExport from '../export/DataExport';
import M2MEditButton from '../m2m/M2MEditButton';
import SmartColumns from './SmartColumns';
import './SmartTable.css';

import { IComponentBehaviour } from '../forms/formInterfaces';
import { IEditField } from '../fields/FieldsInterfaces';
import { ISmartTableListProps } from './SmartTable';
import { IRecord } from '../app/AppInterfaces';
import { ITableRenderListProps } from './TableRender';
import { TableData, IButtonPermission } from './tableInterfaces';
import { isPINPlatform } from '../utils';

export default function SmartTableButtons({
  parentProps,
  dataSource,
  columns,
  loading,
}: {
  parentProps: ITableRenderListProps;
  dataSource: TableData;
  columns: any[];
  loading: boolean;
}) {
  const {
    props,
    handleReloadData,
    handleChangeDrawerVisibility,
    handleSetM2MSwitch,
    handleDeleteData,
    onChangeColumnVisibility,
  } = parentProps;
  const {
    params,
    settings,
    combos,
    selectedRowKeys,
    selectedRow,
    intl,
    fields,
    queryParams,
    layoutType,
    history,
    isAdvancedSearch,
    isModalDashboard,
    userPermissions,
    isM2M,
    parentSelectedRow,
    setSelectedTab,
    dashboardId,
  } = props;
  const { rowKey, componentId, isMainTable, customButtons, navigationId } =
    params;
  const { multiple, canDelete } = settings;
  let rowSelection = {
    selectedRowKeys: selectedRowKeys || [],
    onChange: parentProps.onChangeMultipleRows,
    onSelectAll: parentProps.onSelectAllRows,
    onSelect: parentProps.onSelectMultipleRows,
  };
  const checkButtonPermissions = (
    button: IButtonPermission,
    userPermissions: string[],
  ) => {
    let disabled;
    userPermissions.forEach((permission) => {
      button.permissions.forEach((perm) => {
        if (permission === perm.name) {
          disabled = perm.disabled;
        }
      });
    });
    return disabled;
  };

  const checkButtonBehaviours = (
    button: {
      defaultDisabled: boolean;
      behaviours: IComponentBehaviour[];
    },
    values: IRecord,
  ) => {
    let disabled;
    button.behaviours.forEach((behaviour: IComponentBehaviour) => {
      if (checkBehaviour(behaviour, values)) disabled = !button.defaultDisabled;
    });
    if (disabled === undefined) return button.defaultDisabled;
    else return disabled;
  };

  const checkDisabledButton = (
    button: boolean | IButtonPermission | undefined,
    values: IRecord,
    userPermissions: string[],
  ) => {
    let disabled;

    if (typeof button === 'object') {
      button.permissions
        ? (disabled = checkButtonPermissions(button, userPermissions))
        : (disabled = checkButtonBehaviours(button, values));
      if (disabled === undefined) disabled = button.defaultDisabled;
    } else disabled = !button;

    return disabled;
  };

  const NavigationButton = (parentProps: ITableRenderListProps) => {
    const { props, handleChangeDrawerVisibility } = parentProps;
    const {
      params,
      queryParams,
      parentSelectedRow,
      currentPath,
      parentRowKey,
      parentFormHasChanged,
      navigate,
      intl,
    } = props;
    const {
      navigationRoute,
      navigationId,
      navigationExtensions,
      returnParams,
      parentRowBehaviour,
    } = params;
    let path = '';
    let returnExtension = '';
    if (queryParams.q) {
      const id = queryParams.q.substring(
        queryParams.q.lastIndexOf(config.QUERY.ID_OPERATOR) + 2,
        queryParams.q.length,
      );
      path = `/${navigationRoute}?q=${navigationId}${config.QUERY.ID_OPERATOR}${id}`;

      if (navigationExtensions) {
        navigationExtensions.forEach((element: { [key: string]: string }) => {
          if (element.hasOwnProperty('value'))
            path =
              path +
              `${config.QUERY.AND}${element.key}${config.QUERY.ID_OPERATOR}${element.value}`;
          else {
            path =
              path +
              `${config.QUERY.AND}${element.key}${config.QUERY.ID_OPERATOR}${
                parentSelectedRow[element.key]
              }`;
            if (returnParams)
              returnExtension =
                returnExtension +
                `${config.QUERY.AND}${element.key}${config.QUERY.ID_OPERATOR}${
                  parentSelectedRow[element.key]
                }`;
          }
        });
      }
    }

    let disabled;
    // if (navigationPermission) {
    //   const button = { permissions: [] };
    //   button.permissions.push([navigationPermission]);
    //   disabled = checkButtonPermissions(button, userPermissions);
    // }
    // if (parentRowBehaviour && !disabled) {
    //   disabled = checkButtonBehaviours(parentRowBehaviour, parentSelectedRow);
    // }
    if (parentRowBehaviour) {
      disabled = checkButtonBehaviours(parentRowBehaviour, parentSelectedRow);
    }

    return (
      <Button
        icon="edit"
        type="primary"
        className="single-btn"
        onClick={() =>
          confirmNavigation({
            intl,
            path,
            gobackPath: `${currentPath}?q=${parentRowKey}${
              config.QUERY.ID_OPERATOR
            }${parentSelectedRow[parentRowKey!]}`,
            parentFormHasChanged: parentFormHasChanged!,
            navigate,
            handleChangeDrawerVisibility,
            returnExtension,
          })
        }
        disabled={disabled || isEmpty(parentSelectedRow)}>
        {intl.formatMessage({ id: 'table.editRecords' })}
      </Button>
    );
  };

  let deleteDisable = checkDisabledButton(
    canDelete,
    selectedRow,
    userPermissions!,
  );
  const isPINUsersLIWWTable =
    dashboardId === 'UsersDashboard' && isPINPlatform();

  return (
    <Row>
      <Button.Group className="smartTable__upperButtons">
        <Button
          icon="reload"
          onClick={handleReloadData}
          disabled={loading}
          title={intl.formatMessage({ id: 'form.update' })}
          // loading={loading}
        />
        {config.FEEDBACK.CONFIRM.DELETE_RECORD
          ? !isPINUsersLIWWTable &&
            canDelete !== undefined && (
              <Popconfirm
                disabled={
                  isEmpty(selectedRow) || deleteDisable || !selectedRow.status
                }
                title={intl.formatMessage({ id: 'pop.title.delete' })}
                icon={
                  <Icon type="exclamation-circle" style={{ color: 'red' }} />
                }
                onConfirm={handleDeleteData}
                okText={intl.formatMessage({ id: 'pop.accept' })}
                cancelText={intl.formatMessage({ id: 'pop.cancel' })}>
                <Button
                  type="danger"
                  icon="delete"
                  disabled={
                    isEmpty(selectedRow) || deleteDisable || !selectedRow.status
                  }
                  title={intl.formatMessage({ id: 'form.delete' })}
                />
              </Popconfirm>
            )
          : !isPINUsersLIWWTable &&
            canDelete !== undefined && (
              <Button
                type="danger"
                icon="delete"
                disabled={
                  isEmpty(selectedRow) || deleteDisable || !selectedRow.status
                }
                title={intl.formatMessage({ id: 'form.delete' })}
                onClick={handleDeleteData}
              />
            )}
        {/* {canDelete && (
          <Button
            type="primary"
            icon="delete"
            disabled={isEmpty(dataSource.content) || isEmpty(selectedRowKeys)}
            title={intl.formatMessage({ id: 'form.delete' })}
            onClick={showDeleteConfirm}
            // loading={loading}
          />
        )} */}
      </Button.Group>
      {isAdvancedSearch && <AdvancedSearchButton {...parentProps.props} />}
      {(layoutType === 0 || layoutType === 1) && isMainTable !== false && (
        <Button
          type="primary"
          icon="edit"
          className="single-btn"
          disabled={settings.editable === false ? true : isEmpty(selectedRow)}
          title={intl.formatMessage({ id: 'table.editRecord' })}
          onClick={() =>
            setSelectedTab({
              dashboardId,
              tabIndex: '0',
              componentId: '',
            }) && handleChangeDrawerVisibility(true)
          }
          // loading={loading}
        >
          {intl.formatMessage({ id: 'table.edit' })}
        </Button>
      )}
      {customButtons &&
        customButtons.map((customButton) => (
          <span key={customButton} style={{ marginLeft: 12 }}>
            {customButtonsFile[customButton](parentProps)}
          </span>
        ))}
      {params.hasOwnProperty('navigationRoute') && !isAdvancedSearch && (
        <NavigationButton {...parentProps} />
      )}
      {!isModalDashboard && <ReturnButton {...parentProps.props} />}
      {props.hasOwnProperty('m2m') && (
        <M2MEditButton
          parentSelectedRow={parentSelectedRow}
          {...props.m2m}
          history={history}
          parentComponentId={componentId}
          navigationId={navigationId}
          disabled={isEmpty(queryParams.q)}
          dataPath={appComponents[componentId].path}
          foreignKey={rowKey}
          intl={intl}
          customButtonTitle={props.m2m.editButtonTitle}
          mainKeyValue={
            queryParams.q
              ? queryParams.q.split(config.QUERY.ID_OPERATOR)[1]
              : null
          }
        />
      )}
      {isM2M && (
        <>
          <span style={{ marginLeft: 10 }}>
            {intl.formatMessage({ id: 'table.showSelected' })}
          </span>
          <Switch onChange={handleSetM2MSwitch} loading={loading} />
        </>
      )}
      <SmartColumns
        columnsConfig={columns}
        onClick={onChangeColumnVisibility}
      />
      {!isM2M && (
        <DataExport
          componentId={componentId}
          disabled={isEmpty(dataSource.content)}
          data={dataSource.content}
          selectedRowKeys={selectedRowKeys}
          rowKey={rowKey}
          multiple={multiple && rowSelection}
          columns={fields}
          combos={combos}
          intl={intl}
        />
      )}
    </Row>
  );
}

const confirmNavigation = ({
  path,
  gobackPath,
  parentFormHasChanged,
  navigate,
  intl,
  handleChangeDrawerVisibility,
  returnExtension,
}: {
  path: string;
  gobackPath: string;
  parentFormHasChanged: boolean;
  navigate: Function;
  intl: any;
  handleChangeDrawerVisibility: Function;
  returnExtension: string;
}) => {
  if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && parentFormHasChanged) {
    Modal.confirm({
      title: intl.formatMessage({ id: 'pop.title.select' }),
      content: intl.formatMessage({ id: 'pop.title.select.warn' }),
      okText: intl.formatMessage({ id: 'pop.accept' }),
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      // icon: 'warning',
      maskClosable: true,
      onOk() {
        navigate({
          path,
          gobackPath,
          returnExtension,
        });
        handleChangeDrawerVisibility(false);
      },
      onCancel() {},
    });
  } else {
    navigate({
      path,
      gobackPath,
      returnExtension,
    });
    handleChangeDrawerVisibility(false);
  }
};

const ReturnButton = (parentProps: ISmartTableListProps) => {
  const { breadcrumbs, intl, params, returnToParent, setDrawerVisibility } =
    parentProps;
  const { tableType } = params;
  if (breadcrumbs.length > 1 && tableType === 0) {
    return (
      <Button
        className="single-btn"
        onClick={() =>
          returnToParent({ breadcrumbs, setDrawerVisibility, key: undefined })
        }>
        {intl.formatMessage({ id: 'table.return' })}
      </Button>
    );
  }
  return null;
};

const setAdvancedSearchValues = async (
  parentProps: ISmartTableListProps,
  newValues: IRecord,
) => {
  const { advancedSearchConfig, setFormData, setFormStateFlag } = parentProps;
  const { componentId, handleShowModal, mainTableId } = advancedSearchConfig;

  let selectedValues = { ...newValues };

  setFormData({ componentId, values: selectedValues }) &&
    handleShowModal(false) &&
    setFormStateFlag({
      componentId: mainTableId,
      formHasChanged: true,
    });
};

const AdvancedSearchButton = (parentProps: ISmartTableListProps) => {
  const { selectedRow, advancedSearchConfig, params } = parentProps;
  const { rowKey } = params;
  const { values, field, fields, importValues, isModalTable } =
    advancedSearchConfig;

  let newValues = {} as IRecord;
  if (!isEmpty(selectedRow)) {
    newValues = { ...values };

    const checkFields = (
      fields: IEditField[],
      newValues: IRecord,
      key: string,
    ) => {
      let checkedValued = { ...newValues };
      fields.forEach((field: IEditField) => {
        if (field.key === key) {
          checkedValued = { ...checkedValued, [key]: selectedRow[key] };
        }
      });
      return checkedValued;
    };

    for (let key in selectedRow) {
      if (key !== rowKey) {
        const checkedValues = checkFields(fields, newValues, key);
        newValues = { ...newValues, ...checkedValues };
      }
    }
    //Copy values from advancedSearch referenced entity into advancedSearch field
    newValues = importValues({
      newValues,
      selectedRow,
      field,
      rowKey,
    });
  }

  return isModalTable ? (
    <></>
  ) : (
    <Button
      type="primary"
      disabled={isEmpty(selectedRow)}
      className="selectRecord-btn"
      onClick={() => setAdvancedSearchValues(parentProps, newValues)}
      icon="select">
      Seleccionar Registro
    </Button>
  );
};
