export enum ContentPageEnum {
  HOME = 1,
  ONLY_WYSWYG = 2,
  STATIC_MEDIA = 3,
  HOME_PUBLIC = 4, //!DEPRECATED
  STATIC_FAQ = 5,
}

export enum PageComponentEnum {
  TEXT = 1, //text_area
  CAROUSEL = 2,
  BANNER = 3,
  LINKED_IMAGE = 4,
  WYSWYG = 6,
  REDEEM_POINTS = 7,
  GRID = 8,
  CHALLENGE_TYPE = 9,
  USER_POINTS = 10,
  SCAN_CTA = 11,
  REGISTER_SECTION = 12,
  FAQ = 13,
  PRODUCT_SECTION = 47,
  VIDEO_SECTION = 48,
  WEBEAT_CTA = 84,
  WEBEAT_TEXT = 85,
  WEBEAT_FAQ = 86,
}

export enum WebeatCommunityPageComponentEnum {
  TEXT = 1,
  CAROUSEL = 2,
  BANNER = 3,
  LINKED_IMAGE = 4,
  WYSWYG = 6,
  REDEEM_POINTS = 7,
  GRID = 8,
  CHALLENGE_TYPE = 9,
  USER_POINTS = 10,
  SCAN_CTA = 11,
  REGISTER_SECTION = 12,
  FAQ = 13,
  PRODUCT_SECTION = 47,
  VIDEO_SECTION = 48,
  WEBEAT_CTA = 84,
  WEBEAT_TEXT = 85,
  WEBEAT_FAQ = 86,
  EXPERIENCE = 95,
}

export enum PageComponentChallenge {
  TRAINING_COMPONENT = 91,
  EVENT_COMPONENT = 92,
  CAMPAIGN_COMPONENT = 93,
  CAMPAIGN_ANNUAL_COMPONENT = 94,
  EXPERIENCE_COMPONENT = 95,
  FORMATION_COMPONENT = 910,
}

export enum PageComponentChallengeRestrictions {
  ANNUAL_CAMPAIGN = 4,
}

export enum PageDetailEnum {
  IMAGE = 1,
  VIDEO = 2,
  TEXT = 3,
  LINK = 4,
  ICON = 5,
  WYSWYG = 6,
  PRODUCT = 7,
  PRODUCT_ITEM = 8,
  USER_POINTS = 10,
  FAQ_ITEM = 11,
  WEBEAT_CTA_DETAIL = 12,
  MARKDOWN = 13,
  WEBEAT_FAQ_DETAIL = 14,
}

export enum ComponentIconEnum {
  LEARN = 1,
  EVENT = 2,
  REQUEST_ORDER = 3,
  DOCUMENT = 4,
  GIFT = 5,
}

export enum VideoTypesEnum {
  NORMAL = '0',
  AUTOPLAY = '1',
  LIGHT = '2',
}

export enum ContentPageRoleTypeEnum {
  CUSTOMER = 'Customer',
  DERMO = 'Dermo',
}

export const isTextDetail = (type: number) => {
  if (type === PageDetailEnum.TEXT || type === PageDetailEnum.WYSWYG)
    return true;
  else return false;
};
