import { Button, Col, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { SetQuiz } from '../../challengeActions';
import { IResource, IResourceDetail } from '../../ChallengeInterfaces';
import { getDetail } from '../../ChallengeUtils';
import { ResourceTypeDetailEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const {
  SHOPPING_CART_PRODUCT_ID: PRODUCT_ID,
  SHOPPING_CART_PRODUCT_MAX_ITEMS: MAX_ITEMS,
  SHOPPING_CART_PRODUCT_MIN_ITEMS: MIN_ITEMS,
  SHOPPING_CART_PRODUCT_DEFAULT_ITEMS: DEFAULT_ITEMS,
} = ResourceTypeDetailEnum;

interface IProductsList {
  combo?: ComboData[];
  editRecursiveResource: ({
    value,
    parentArrayPosition,
    childArrayPosition,
    detailArrayPosition,
  }: {
    value: any;
    parentArrayPosition?: number;
    childArrayPosition?: number;
    detailArrayPosition?: number;
  }) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleRemoveChildResource: ({
    idAnswer,
    questionArrayPosition,
    answerArrayPosition,
  }: {
    idAnswer: number;
    idQuestion: number;
    questionArrayPosition: number;
    answerArrayPosition: number;
  }) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  groupIndex: number;
  mainIdResource: number;
  resource: IResource;
  values: IRow;
}

interface IProduct {
  resource: IResource;
  productIndex: number;
}

const GroupProducts: FC<IProductsList> = ({
  combo,
  editResource,
  editRecursiveResource,
  form,
  handleRemoveChildResource,
  groupIndex,
  mainIdResource,
  resource,
  values,
}) => {
  const { resourceList, idResource: groupIdResource } = resource;
  const renderProduct = ({
    resource: { idResource, resourceDetailList },
    productIndex,
  }: IProduct) => {
    if (!resourceDetailList?.length) return;

    const [productDetail, productDetailIndex] = getDetail(
      resourceDetailList,
      PRODUCT_ID,
    );
    const [maxDetail, maxDetailIndex] = getDetail(
      resourceDetailList,
      MAX_ITEMS,
    );
    const [minDetail, minDetailIndex] = getDetail(
      resourceDetailList,
      MIN_ITEMS,
    );
    const [defaultDetail, defaultDetailIndex] = getDetail(
      resourceDetailList,
      DEFAULT_ITEMS,
    );

    if (!productDetail || !maxDetail || !minDetail || !defaultDetail) return;

    const renderProductDetail = ({
      combo,
      detail,
      detailArrayPosition,
    }: {
      combo?: ComboData[];
      detail: IResourceDetail;
      detailArrayPosition?: number;
    }) =>
      RenderDetail({
        combo,
        detail,
        editRecursiveResource,
        editResource,
        form,
        parentArrayPosition: groupIndex,
        childArrayPosition: productIndex,
        detailArrayPosition,
        resource,
        mainIdResource,
        values,
      });

    return (
      <Row gutter={[0, 24]} className="shopping-product">
        <Row type="flex" justify="end" className="delete-button-row">
          {editResource && (
            <Button
              icon="close"
              className="buttonNoFrame delete-button"
              onClick={() => {
                handleRemoveChildResource({
                  idAnswer: idResource,
                  idQuestion: groupIdResource,
                  questionArrayPosition: groupIndex,
                  answerArrayPosition: productIndex,
                });
              }}
            />
          )}
        </Row>

        <Row type="flex" justify="center">
          <div className="no-image" />
        </Row>
        <Col span={23}>
          {renderProductDetail({
            combo,
            detail: productDetail,
            detailArrayPosition: productDetailIndex!,
          })}
        </Col>

        <Col span={8}>
          {renderProductDetail({
            detail: minDetail,
            detailArrayPosition: minDetailIndex!,
          })}
        </Col>
        <Col span={8}>
          {renderProductDetail({
            detail: maxDetail,
            detailArrayPosition: maxDetailIndex!,
          })}
        </Col>
        <Col span={8}>
          {renderProductDetail({
            detail: defaultDetail,
            detailArrayPosition: defaultDetailIndex!,
          })}
        </Col>
      </Row>
    );
  };

  return (
    <Col xs={24}>
      <Row gutter={[12, 24]} className="shopping-product-row">
        {resourceList?.map(
          (resource, productIndex) =>
            resource.status && (
              <Col key={productIndex} span={6}>
                {renderProduct({ resource, productIndex })}
              </Col>
            ),
        )}
      </Row>
    </Col>
  );
};

export default GroupProducts;
