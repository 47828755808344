import { Button, Modal, notification, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { FC, Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { IRow } from '../../app/AppInterfaces';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ReducersState } from '../../reducers';
import { ITableRenderListProps } from '../../tables/TableRender';
import { getFrontUrl } from '../../utils';
import { isTableProps } from '../../utils/propsUtils';

export default function ImpersonateButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  return <ButtonNavigateSurvey selectedRow={selectedRow} />;
}

interface OwnButtonProps {
  selectedRow: IRow;
}

type ButtonProps = ReturnType<typeof mapStateToProps> & OwnButtonProps;

const ButtonNavigateSurveyComponent: FC<ButtonProps> = ({
  selectedRow,
  platforms,
  currentPlatform,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { formatMessage } = useIntl();

  const handleOpen = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const getSurveyLink = (): string => {
    const FRONT_URL = getFrontUrl(currentPlatform, platforms);

    return `${FRONT_URL}survey/${selectedRow.idSurvey}`;
  };

  const handleOpenLink = () => {
    window.open(getSurveyLink(), '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(getSurveyLink());

    notification.success({
      message: formatMessage({ id: 'survey.copy-link-success' }),
      duration: 3,
    });
  };

  return (
    <>
      <Button
        onClick={() => handleOpen()}
        disabled={isEmpty(selectedRow)}
        type="primary">
        <FormattedMessage id="survey.button.show-user-link" />
      </Button>

      <Modal
        footer={null}
        visible={modalVisible}
        onCancel={handleClose}
        onOk={handleClose}
        centered>
        <Row
          style={{ height: '40px' }}
          type="flex"
          justify="center"
          align="middle">
          <div className="breadcrumb">
            <span className="breadcrumb__main--bold">
              <FormattedMessage id="tree-resource.survey_id" />
            </span>
          </div>
        </Row>

        <Row
          style={{ height: '40px', fontWeight: 'bold' }}
          type="flex"
          justify="center"
          align="middle">
          {getSurveyLink()}
        </Row>

        <Row
          justify="space-around"
          type="flex"
          style={{ height: '40px' }}
          align="middle">
          <Button
            onClick={handleOpenLink}
            disabled={isEmpty(selectedRow)}
            type="primary">
            <FormattedMessage id="survey.button.open-link" />
          </Button>

          <Button
            onClick={handleCopyLink}
            disabled={isEmpty(selectedRow)}
            type="primary">
            <FormattedMessage id="survey.button.copy-link" />
          </Button>
        </Row>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};

const ButtonNavigateSurvey = connect(
  mapStateToProps,
  {},
)(ButtonNavigateSurveyComponent);
