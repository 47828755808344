import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import apiPaths from '../apiPaths';
import { IRecord } from '../app/AppInterfaces';
import { ComponentModal, CustomDashboard } from '../isdinCustomDashboards';

interface IExtraFields extends ColumnProps<IRecord> {
  type?: 'multiSelect';
}

export default function IsdinCentersDashboard(props: any) {
  const columns: ColumnProps<IRecord>[] = [
    {
      title: 'Center Name',
      dataIndex: 'centerName',
      defaultSortOrder: 'descend',
      sorter: false,
    },
    {
      title: 'City',
      dataIndex: 'centerDirectionCity',
      sorter: false,
    },
    {
      title: 'State',
      dataIndex: 'centerDirectionState',
      sorter: false,
    },
    {
      title: 'CP',
      dataIndex: 'centerDirectionCP',
      sorter: false,
    },
    {
      title: 'Address',
      dataIndex: 'centerDirectionName',
      sorter: false,
    },
  ];

  function DisplayCenterInfo(selectedRow: IRecord, loading: boolean) {
    const [modalVisible, setModalVisible] = useState(false);

    const handleShowUserInfo = async () => {
      setModalVisible(true);
    };

    const extraFields: IExtraFields[] = [
      {
        title: 'Center ID',
        dataIndex: 'centerID',
      },
      {
        title: 'Salesforce ID',
        dataIndex: 'saleforceID',
      },
      {
        title: 'Center Managers',
        dataIndex: 'centerManagers',
        type: 'multiSelect',
      },
    ];

    return (
      <Fragment key="user-info_button">
        <Button
          loading={loading}
          style={{ marginRight: '20px' }}
          onClick={() => handleShowUserInfo()}
          disabled={isEmpty(selectedRow)}
          type="primary">
          Center Info
        </Button>
        <ComponentModal
          {...{
            columns,
            extraFields,
            modalVisible,
            selectedRow,
            setModalVisible,
            title: 'Center info',
            reloadData: async () => {},
          }}
        />
      </Fragment>
    );
  }

  return (
    <CustomDashboard
      searchPlaceHolder="Introduce center name..."
      intl={props.intl}
      path={apiPaths.CALL.CENTERS_BULK}
      columns={columns}
      customButtons={[DisplayCenterInfo]}
      rowKey="saleforceID"
      searchField="centerName"
      entityKey="centers"
      filter="notExistLI:false,AND,"
    />
  );
}
