import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ComboData } from '../../combos/ComboInterfaces';
import { ReducersState } from '../../reducers';
import { IndexedDetail, IResourceTree } from '../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../Enums';
import RenderDetail from './TreeResource/TreeDetails';
// import BasicResource from './BasicResource';
import ResourceDetail from './ResourceDetail';

const { VIDEO_SECTION_TYPE, UPLOAD_MEDIA_NEW, VIDEO_SECTION_ID } =
  ResourceTypeDetailEnum;

type Props = ReturnType<typeof mapStateToProps> & IResourceTree;

const VideoSection = ({
  accessToken,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  values,
  handleEditResourceDetail,
  renderExtraFields,
  intl,
}: Props): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  const oldVideoID = details.find(
    ({ detail }) => detail.idResourceTypeD.idResourceTypeD === VIDEO_SECTION_ID,
  );

  return (
    <>
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          const { value, idResourceD, idResourceTypeD } = detail;
          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD.idResourceTypeD === VIDEO_SECTION_TYPE) {
            combo =
              combos.challengechallengeEdit?.videoSectionType?.videoSectionType
                ?.data ?? [];
          }

          if (idResourceTypeD.idResourceTypeD === VIDEO_SECTION_ID) {
            return <></>;
          }

          if (idResourceTypeD.idResourceTypeD === UPLOAD_MEDIA_NEW) {
            const oldVideoIDValue = oldVideoID?.detail.value ?? '';
            return (
              <ResourceDetail
                key={idResourceD}
                {...{
                  handleDeleteImages,
                  editResource,
                  form,
                  idResourceTypeD: idResourceTypeD,
                  idResourceD,
                  handleEditResourceDetail,
                  resource,
                  resourceType: resource.idResourceType.name,
                  value: value ? value : oldVideoIDValue,
                  intl,
                }}
              />
            );
          }

          return RenderDetail({
            accessToken,
            combo,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            handleDeleteImages,
            resource,
            mainIdResource: resource.idResource,
            values,
          });
        })}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};
export default connect(mapStateToProps, {})(VideoSection);
