import { Button, Modal, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import * as api from '../../api';
import apiPaths from '../../apiPaths';

export default function ValidateUserEmailButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const { formatMessage } = useIntl();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  if (!isTableProps(parentProps)) return <Fragment />;

  const { selectedRow } = parentProps.props;

  const tableProps: ITableRenderListProps = parentProps;

  const handleValidateUser = async () => {
    try {
      const response = await api.putDataCall({
        dataPath: `${apiPaths.CALL.VALIDATE_USER_EMAIL}/${selectedRow.idUser}`,
        data: {
          idUser: selectedRow.idUser,
        },
        callConfig: {},
      });

      if (response?.status === 200) {
        notification.info({
          message: formatMessage({
            id: 'validateUser.notification.title',
          }),
          description: formatMessage({
            id: 'validateUser.notification.description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: formatMessage({
          id: 'validateUser.notification.error.title',
        }),
        description: formatMessage({
          id: 'validateUser.notification.error.description',
        }),
      });
    } finally {
      await tableProps.handleReloadData();
      setShowConfirmModal(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        disabled={isEmpty(selectedRow) || selectedRow.validatedEmail}
        onClick={() => setShowConfirmModal(true)}>
        {formatMessage({ id: 'validateUser.button' })}
      </Button>
      <Modal
        className="modalConfirm"
        visible={showConfirmModal}
        closable={false}
        centered
        onCancel={() => setShowConfirmModal(false)}
        onOk={handleValidateUser}>
        <p>{formatMessage({ id: 'validateUser.modal-description' })}</p>
      </Modal>
    </>
  );
}
