import { Button } from 'antd';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import {
  getM2MTableData,
  resetM2M,
  setM2MModalVisibility,
  setM2MPathExtended,
  setM2MTableParams,
} from '../../m2m/m2mActions';
import { ReducersState } from '../../reducers';
import { ITableRenderListProps } from '../../tables/TableRender';
import { getTableData } from '../../tables/tableActions';
import { isTableProps } from '../../utils/propsUtils';

export default function DisclaimerSpecialtiesButtons<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;

  return <DisclaimerSpecialtiesButtonsComponent tableProps={tableProps} />;
}

interface OwnButtonProps {
  tableProps: ITableRenderListProps;
}

type ButtonProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnButtonProps;

const _disclaimerSpecialtiesButtonsComponent: FC<ButtonProps> = (
  buttonProps,
) => {
  const {
    tableProps: { props },
    specialties,
    setM2MPathExtended,
    setM2MModalVisibility,
  } = buttonProps;

  const handleChangeM2MModalVisibility = (
    isVisible: boolean,
    idSpecialty: string,
  ) => {
    setM2MPathExtended({
      key: 'associate-with-specialty',
      value: idSpecialty,
    });
    setM2MModalVisibility(isVisible);
  };

  return (
    <>
      {specialties.map((specialty) => {
        return (
          <Button
            icon="usergroup-add"
            type="dashed"
            style={{ marginLeft: '10px' }}
            onClick={() =>
              handleChangeM2MModalVisibility(true, specialty.value)
            }
            disabled={props.disabled}>
            {specialty.description}
          </Button>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    specialties:
      state.combos.combos.disclaimersEdit.specialty.specialty.data ?? [],
    isM2MModalVisible: state.m2m.visible,
    selectedM2MDashboard: state.m2m.activeDashboard,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setM2MModalVisibility,
      resetM2M,
      getTableData,
      setM2MTableParams,
      getM2MTableData,
      setM2MPathExtended,
    },
    dispatch,
  );

// TODO: Move this to a separate file
const DisclaimerSpecialtiesButtonsComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_disclaimerSpecialtiesButtonsComponent);
