import {
  Card,
  Collapse,
  Form,
  Button,
  message,
  Radio,
  Col,
  Icon,
  Typography,
  Input,
} from 'antd';
import React, { useState } from 'react';
import { ICompleteField } from '../forms/formInterfaces';
import fieldRender from '../forms/fieldRender';
import UploadComponent from '../challenge/Components/UploadComponent';
import { UPLOAD_ACTION_IMAGE, UPLOAD_ACTION_VIDEO } from '../shared';

const { Panel } = Collapse;
const { Text } = Typography;
const { Item } = Form;

export const WebeatCollapsedListInput = (
  field: ICompleteField,
  parentProps: any,
) => {
  const [, forceUpdate] = useState<number>(0);
  const isBannerFeature = field.key === 'gridBanners';
  if (!field.objectArrayFields)
    return <p>This component need objectArrayFields</p>;
  const mustChoseType = field.objectArrayFields.length > 1;
  const { handleChangeField, props } = parentProps;
  const { form, values, intl, accessToken, params } = props;
  const { getFieldDecorator } = form;

  const moveItemUp = (index: number) => {
    if (index === 0) {
      return;
    }

    const newValues = [...values[field.key]];
    const temp = newValues[index];
    newValues[index] = newValues[index - 1];
    newValues[index - 1] = temp;

    handleChangeField({
      id: [field.key],
      value: newValues,
    });
  };

  const moveItemDown = (index: number) => {
    if (index === values[field.key].length - 1) {
      return;
    }

    const newValues = [...values[field.key]];
    const temp = newValues[index];
    newValues[index] = newValues[index + 1];
    newValues[index + 1] = temp;

    handleChangeField({
      id: [field.key],
      value: newValues,
    });
  };

  const addItem = () => {
    if (
      !values[field.key] ||
      (field.arrayMaxSize && values[field.key].length < field.arrayMaxSize) ||
      !field.arrayMaxSize
    ) {
      const previousBanners = values[field.key] || [];
      handleChangeField({
        id: [field.key],
        value: [
          ...previousBanners,
          {
            type: 'image',
          },
        ],
      });
    } else {
      message.error('Ya has alcanzado el límite de ' + field.title);
    }
  };

  const removeItem = (index: number) => {
    let tempBanners = [...values[field.key]];
    tempBanners.splice(index, 1);
    handleChangeField({
      id: [field.key],
      value: tempBanners,
    });
  };

  const handleFieldChange = (innerField: string, value: any, index: number) => {
    const newValues = values[field.key];
    newValues[index][innerField] = value;
    handleChangeField({
      id: [field.key],
      value: newValues,
    });
  };

  const onItemTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const finedItem = field.objectArrayFields?.find(
      (objectField) => objectField.value === e.target.value,
    );
    if (!finedItem)
      return message.error('There was a problem adding your type');

    handleFieldChange('type', finedItem.value, index);
    finedItem.fields.forEach((innerField) => {
      handleFieldChange(innerField.key, innerField.initialValue, index);
    });
  };

  return (
    <Card
      title={field.title}
      bodyStyle={{ padding: '0px' }}
      style={{ marginBottom: '10px' }}
      extra={<Button onClick={addItem}>Add {field.title}</Button>}>
      <Collapse>
        {values[field.key] &&
          values[field.key].map((item: any, index: number) => (
            <Panel
              extra={
                isBannerFeature ? (
                  [1, 4, 7].includes(index + 1) ? (
                    <Text code type="danger">
                      Banner doble
                    </Text>
                  ) : (
                    <Text code type="secondary">
                      Banner simple
                    </Text>
                  )
                ) : undefined
              }
              header={`${field.title} ${index + 1}`}
              key={index}>
              <Form layout="vertical">
                {mustChoseType ? (
                  <Item label={`Type of ${field.title}`}>
                    {getFieldDecorator(`${field.key}[${index}].type`, {
                      initialValue: undefined,
                    })(
                      <Radio.Group
                        onChange={(e: any) => onItemTypeChange(e, index)}>
                        {field.objectArrayFields?.map((objectField) => (
                          <Radio.Button value={objectField.value}>
                            {objectField.title}
                          </Radio.Button>
                        ))}
                      </Radio.Group>,
                    )}
                  </Item>
                ) : null}
                {field.objectArrayFields
                  ?.find(
                    (objectField) =>
                      objectField.value === item.type || !mustChoseType,
                  )
                  ?.fields.map((innerField) => {
                    if (
                      innerField.type === 'image' ||
                      innerField.type === 'video'
                    ) {
                      return (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item label={innerField.title}>
                            {!values[field.key][index].fromUrl ? (
                              <UploadComponent
                                format={innerField.type}
                                token={accessToken}
                                uploadActions={
                                  innerField.type === 'image'
                                    ? [UPLOAD_ACTION_IMAGE]
                                    : [UPLOAD_ACTION_VIDEO]
                                }
                                value={item[innerField.key]}
                                module="banner"
                                primaryEntityId={values[params.primaryKey]}
                                handleChangeField={(data: any) => {
                                  handleFieldChange(
                                    innerField.key,
                                    data.value,
                                    index,
                                  );
                                  forceUpdate(Math.random());
                                }}
                                setValue={function (
                                  value?: string | null | undefined,
                                ): void {
                                  handleFieldChange(
                                    innerField.key,
                                    value,
                                    index,
                                  );
                                  forceUpdate(Math.random());
                                }}>
                                <Button
                                  className="uploadButton uploadButton__margin-top"
                                  disabled={innerField.disabled}>
                                  <Icon type="upload" />
                                  {intl.formatMessage({
                                    id: 'upload.buttonTitle',
                                  })}
                                </Button>
                              </UploadComponent>
                            ) : (
                              <Input
                                type="text"
                                title={field.title}
                                defaultValue={item[innerField.key]}
                                disabled={innerField.disabled || false}
                                onBlur={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) =>
                                  handleFieldChange(
                                    innerField.key,
                                    e.target.value,
                                    index,
                                  )
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      );
                    }
                    return fieldRender(
                      {
                        ...innerField,
                        key: `${field.key}[${index}].${innerField.key}`,
                      },
                      {
                        ...parentProps,
                        handleChangeField: (data: any) => {
                          handleFieldChange(innerField.key, data.value, index);
                        },
                      },
                    );
                  }) || null}

                <Button
                  disabled={index === 0}
                  icon="up"
                  onClick={() => moveItemUp(index)}></Button>
                <Button
                  disabled={index === values[field.key].length - 1}
                  icon="down"
                  onClick={() => moveItemDown(index)}></Button>
                <Button
                  type="danger"
                  icon="delete"
                  onClick={() => removeItem(index)}></Button>
              </Form>
            </Panel>
          ))}
      </Collapse>
    </Card>
  );
};
