import config from '../../config';
import { Column, ITableComponent } from '../../tables/tableInterfaces';
import { ISearchComponent } from '../../forms/formInterfaces';

export const userSearch = (intl: any): ISearchComponent => {
  const { formatMessage } = intl;

  return {
    params: {
      type: 'search',
      componentId: 'challengeusersSearch',
    },
    groups: [
      {
        index: 0,
        title: '',
        mustRender: true,
        subgroups: [
          {
            index: 0,
            title: '',
            mustRender: true,
            yPosition: 0,
            xPosition: 0,
            width: 24,
            fields: [
              {
                key: 'status',
                yPosition: 0,
                xPosition: 0,
                mustRender: true,
                width: 12,
              },
              {
                key: 'idUser.email',
                yPosition: 0,
                xPosition: 1,
                mustRender: true,
                width: 12,
              },

              {
                key: 'idResource.idResourceType.idResourceType',
                yPosition: 1,
                xPosition: 0,
                mustRender: true,
                width: 12,
              },
            ],
          },
        ],
      },
    ],
    fields: [
      {
        title: formatMessage({ id: 'users.status' }),
        key: 'status',
        type: 'comboCustom',
        comboId: 'status',
        operand: 'like',
        min: 0,
        length: 0,
        mandatory: false,
        disabled: false,
        initialValue: 'true',
        globalSearch: true,
      },
      {
        title: 'Email',
        key: 'idUser.email',
        type: 'text',
        operand: 'like',
        min: 0,
        length: 50,
        mandatory: false,
        disabled: false,
        globalSearch: true,
      },
      {
        title: 'Resource Type',
        key: 'idResource.idResourceType.idResourceType',
        type: 'combo',
        comboId: 'resource_type_byChallenge',
        operand: 'equal',
        min: 0,
        length: 0,
        mandatory: false,
        disabled: false,
      },
    ],
    settings: {
      fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
      fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
    },
  };
};

export const userTable = ({
  intl,
  isChallengeTalk,
}: {
  intl: any;
  isChallengeTalk: boolean;
}): ITableComponent => {
  const { MID } = config.COMPONENT.COLUMN;
  const { formatMessage } = intl;
  const extraFields: Column[] = [];

  if (isChallengeTalk) {
    extraFields
      .push
      // {
      //   title: formatMessage({ id: 'component-type.event' }),
      //   key: 'eventData.title',
      //   position: 1,
      //   mustRender: true,
      //   align: 'left',
      //   sorter: false,
      //   visible: true,
      //   defaultSortOrder: 'ascend',
      //   width: MID,
      // },
      // {
      //   title: formatMessage({ id: 'tree-resource.location' }),
      //   key: 'eventData.location',
      //   position: 1,
      //   mustRender: true,
      //   align: 'left',
      //   sorter: false,
      //   visible: true,
      //   defaultSortOrder: 'ascend',
      //   width: MID,
      // },
      // {
      //   title: formatMessage({ id: 'tree-resource.start date' }),
      //   key: 'eventData.date',
      //   position: 1,
      //   mustRender: true,
      //   align: 'left',
      //   sorter: false,
      //   visible: true,
      //   defaultSortOrder: 'ascend',
      //   width: MID,
      // },
      ();
  }

  return {
    params: {
      type: 'table',
      componentId: 'challengeusersList',
      rowKey: 'idUserResource',
      panelHeader: 'Users List',
      navigationParams: '',
      isMainTable: true,
      tableType: 0,
      prettierKey: 'name',
      customButtons: ['assignUsersButton'],
    },
    fields: [
      ...extraFields,
      {
        title: formatMessage({ id: 'users.id' }),
        key: 'idUser.idUser',
        position: 0,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: false,
      },
      {
        title: formatMessage({ id: 'idUserResource' }),
        key: 'idUserResource',
        position: 0,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: false,
      },
      {
        title: formatMessage({ id: 'users.email' }),
        key: 'idUser.email',
        position: 1,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: true,
        defaultSortOrder: 'ascend',
      },
      {
        title: formatMessage({ id: 'users.specialty' }),
        key: 'idUser.idSpecialty.name',
        position: 2,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: true,
        width: MID,
      },
      {
        title: formatMessage({ id: 'users.userStatus' }),
        key: 'idUser.userStatus',
        render: 'combo',
        comboId: 'userStatus',
        position: 4,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: true,
        width: MID,
      },
      {
        title: formatMessage({ id: 'users.challengeStatus' }),
        key: 'statusResource',
        isArray: true,
        arrayPosition: 0,
        render: 'combo',
        comboId: 'resourceStatus',
        position: 4,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: true,
        width: MID,
      },
      {
        title: formatMessage({ id: 'users.country' }),
        key: 'idUser.idCountry.name',
        position: 6,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: false,
        width: MID,
      },
      {
        title: formatMessage({ id: 'users.language' }),
        key: 'idUser.idLanguage.name',
        position: 7,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: false,
        width: MID,
      },
      {
        title: formatMessage({ id: 'users.status' }),
        key: 'status',
        render: 'comboCustom',
        comboId: 'status',
        position: 8,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: false,
        width: MID,
      },
      {
        title: formatMessage({ id: 'resourceName' }),
        key: 'idResource.idResourceType.name',
        isArray: true,
        arrayPosition: 0,
        position: 9,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: true,
        width: MID,
      },
      {
        title: formatMessage({ id: 'users.activation-date' }),
        key: 'createDate',
        arrayPosition: 0,
        position: 9,
        mustRender: true,
        align: 'left',
        sorter: true,
        visible: true,
        width: MID,
      },
    ],
    settings: {
      size: config.COMPONENT.TABLE.SIZE,
      bordered: config.COMPONENT.TABLE.BORDERED,
      editable: false,
      multiple: false,
      canCreate: false,
      // canDelete: false,
      scroll: { x: true },
    },
    pagination: {
      defaultPageSize: 50,
      pageSizeOptions: ['5', '10', '20', '50'],
      showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
      showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
      size: config.COMPONENT.TABLE.SIZE,
    },
  };
};
