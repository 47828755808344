import React, { FC, Fragment } from 'react';

import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Row,
} from 'antd';
import {
  IndexedDetail,
  IResource,
  IResourceDetail,
} from '../ChallengeInterfaces';
import { useIntl } from 'react-intl';
import { SetQuiz } from '../challengeActions';
import { Dispatch } from 'redux';
import { ReducersState } from '../../reducers';
import { blankEmailWithDate, blankEmailWithDelay } from './ResourceConfigs';
import { DetailsTypesEnum, ResourceTypeEnum } from '../Enums';
import { TreeDate, TreeSelector } from './TreeResource/TreeDetailsFields';
import { FormComponentProps } from 'antd/lib/form';
import { getDetailInitialValue } from './ResourceDetails.utils';

interface IAutomaticEmailModal extends FormComponentProps {
  automaticEmailVisibility?: boolean;
  handleAddParentResource: (
    newResource: IResource,
  ) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  editRecursiveResource: any;
  resetQuiz: any;
  handleRemoveParentResource: any;
  updateResource: any;
  setAutomaticEmailVisibility: (visibility: boolean) => void;
  refreshChallengeData: any;
  resourceHasChanged: boolean;
  resource: IResource;
  combos: any;
  selectedRow: any;
}

const WebeatAddAutomaticEmailModal: FC<IAutomaticEmailModal> = ({
  form,
  automaticEmailVisibility,
  handleAddParentResource,
  editRecursiveResource,
  resetQuiz,
  handleRemoveParentResource,
  updateResource,
  setAutomaticEmailVisibility,
  refreshChallengeData,
  resourceHasChanged,
  resource,
  combos,
  selectedRow,
}) => {
  type IBlankConfigType = (
    idResource: number,
    resourceList: IResource[] | undefined,
  ) => IResource;

  const { formatMessage } = useIntl();
  const { idResource, resourceList } = resource;

  const emailConfig = [
    {
      id: 'email_with_delay',
      type: ResourceTypeEnum.EMAIL_WITH_DELAY,
      blankFunction: blankEmailWithDelay,
    },
    {
      id: 'email_with_date',
      type: ResourceTypeEnum.EMAIL_WITH_DATE,
      blankFunction: blankEmailWithDate,
    },
  ];

  const handleAdd = (blankFunc: IBlankConfigType) => {
    handleAddParentResource(blankFunc(idResource, resourceList));
  };

  const handleSave = async () => {
    form.validateFields(async (err, formValues) => {
      const isCorrectForm = !err;

      if (!isCorrectForm)
        return notification.error({
          message: formatMessage({
            id: 'challenge.required',
          }),
          duration: 3,
        });

      if (!resourceHasChanged) {
        form.resetFields();
        setAutomaticEmailVisibility(false);
        return;
      }
      const response = await updateResource(selectedRow.idChallenge, {
        ...resource,
        idChallenge: selectedRow.idChallenge,
      });
      if (response?.status === 200 && response.data) {
        refreshChallengeData();
      }
      setAutomaticEmailVisibility(false);
      form.resetFields();
    });
  };

  const handleClose = () => {
    Modal.confirm({
      title: formatMessage({
        id: 'pop.title.changes-will-not-be-saved-are-you-sure',
      }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        resetQuiz();
        setAutomaticEmailVisibility(false);
        form.resetFields();
      },
      onCancel() {},
    });
  };

  const EmailResource = ({
    details,
    mainIndex,
  }: {
    details: IndexedDetail[];
    mainIndex: number;
  }) => (
    <div key={mainIndex}>
      <Row type="flex" justify="space-between">
        <Col xs={2} order={2}>
          <Form.Item className="Quiz__input">
            <Popconfirm
              title={formatMessage({ id: 'pop.title.delete' })}
              icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
              okText={formatMessage({ id: 'pop.accept' })}
              cancelText={formatMessage({ id: 'pop.cancel' })}
              onConfirm={() => handleRemoveParentResource(mainIndex)}>
              <Button className="QuizQuestion__deleteButton" icon="close" />
            </Popconfirm>
          </Form.Item>
        </Col>
        <Col xs={22} order={1}>
          <Row type="flex" justify="space-between">
            {renderDetails(details, mainIndex)}
          </Row>
        </Col>
      </Row>
      <Divider className="resourceDivider" />
    </div>
  );

  const EmailsContainer = () => {
    return (
      <>
        {emailConfig.map((props) => (
          <Fragment key={props.id}>
            <Divider className="resourceDivider" />
            <h5 className="Quiz__editableTitle">
              {formatMessage({ id: `component-type.${props.id}` })}
            </h5>
            {renderEmails(props.type)}
            <Row className="Quiz__addQuestion" type="flex" justify="center">
              <Button
                type="primary"
                onClick={() => handleAdd(props.blankFunction)}>
                {formatMessage({
                  id: `challenge.add.${props.id}`,
                })}
              </Button>
            </Row>
          </Fragment>
        ))}
      </>
    );
  };

  const renderEmails = (type: ResourceTypeEnum) => {
    return resourceList?.map((_resource, mainIndex) => {
      if (_resource.idResourceType.idResourceType !== type || !_resource.status)
        return;
      const details: IndexedDetail[] =
        _resource?.resourceDetailList?.map((detail, index) => {
          return { index, detail };
        }) || [];

      return (
        <EmailResource
          key={mainIndex}
          details={details}
          mainIndex={mainIndex}
        />
      );
    });
  };

  const getComponentData = (
    detail: IResourceDetail,
    index: number,
    mainIndex: number,
  ) => {
    let component;
    let colSpan;
    const combo =
      combos.challengechallengeEdit?.challengeMails?.challengeMails?.data ?? [];

    const { idResourceTypeD: resourceDetailType } = detail;
    const { type: detailType, value: detailTypeValue } = resourceDetailType;
    switch (detailType) {
      case DetailsTypesEnum.MAIL:
        colSpan = 12;
        component = TreeSelector({
          combo,
          detail,
          detailArrayPosition: index,
          editRecursiveResource,
          editResource: true,
          idResourceD: detail.idResourceD,
          parentArrayPosition: mainIndex,
          value: detail.value,
        });
        break;
      case DetailsTypesEnum.DATE:
        colSpan = 10;
        component = TreeDate({
          detailArrayPosition: index,
          editRecursiveResource,
          editResource: true,
          idResourceD: detail.idResourceD,
          parentArrayPosition: mainIndex,
          value: detail.value,
        });
        break;
      case DetailsTypesEnum.NUMBER:
        colSpan = 5;
        component = (
          <InputNumber
            className="QuestionInput"
            precision={0}
            min={0}
            key={detail.idResourceD}
            onChange={(value) =>
              editRecursiveResource({
                value,
                parentArrayPosition: mainIndex,
                childArrayPosition: undefined,
                detailArrayPosition: index,
              })
            }
          />
        );
        break;
      default:
        break;
    }
    return { component, colSpan, detailTypeValue };
  };

  const renderDetails = (details: IndexedDetail[], mainIndex: number) => {
    return details
      .sort(
        ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
          orderA - orderB,
      )
      .map(({ index, detail }) => {
        const { component, colSpan, detailTypeValue } = getComponentData(
          detail,
          index,
          mainIndex,
        );

        return (
          <Col key={index} xs={22} md={colSpan}>
            <Form.Item
              label={formatMessage({
                id: `automatic.email.${detailTypeValue?.toLowerCase()}`,
              })}
              className="Quiz__tree-input">
              {form.getFieldDecorator(`_parent_${mainIndex}_detail_${index}`, {
                initialValue: getDetailInitialValue(
                  detail.idResourceTypeD,
                  detail.value,
                ),
                rules: [{ required: true, message: 'Required' }],
              })(component)}
            </Form.Item>
          </Col>
        );
      });
  };

  return (
    <Modal
      className="Quiz__modal"
      visible={automaticEmailVisibility}
      footer={null}>
      <Form>
        <Row className="Quiz__title" type="flex" justify="end" align="middle">
          <div>
            <Button onClick={handleClose}>
              {formatMessage({ id: 'form.edit.cancel' })}
            </Button>
            <Button
              type="primary"
              className="Quiz__modal--saveButton"
              onClick={handleSave}>
              {formatMessage({ id: 'form.save' })}
            </Button>
          </div>
        </Row>
        <EmailsContainer />
      </Form>
    </Modal>
  );
};

export default Form.create<any>({ name: 'mail_resource_form' })(
  WebeatAddAutomaticEmailModal,
);
