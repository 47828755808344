import { Modal, notification, Row, Checkbox, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Collapsible from '../../challenge/Components/Collapsible';
import { ComboData } from '../../combos/ComboInterfaces';
import '../ContentPage.css';
import {
  iconCombo,
  IKey,
  IPageComponent,
  IPageDetail,
  isChallengeComponent,
  PageComponentEnum,
  PageDetailEnum,
  renderSelector,
  WebeatCommunityPageComponentEnum,
  _blankDetail,
  setFaqComboValue,
  ContentPageEnum,
} from '../shared';
import { componentButtons } from './ComponentButtons';
import WebeatComponentDetail from './WebeatComponentDetail';
import { ComponentProps } from './WebeatComponentForm';
import { linkedImageValidations } from './componentsUtils';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { getPlatformBaseUrl, isWebeatPlatform } from '../../utils';

const { REDEEM_POINTS, CHALLENGE_TYPE, PRODUCT_SECTION, WEBEAT_FAQ } =
  PageComponentEnum;

const { STATIC_FAQ } = ContentPageEnum;

export default function WebeatComponent({
  accessToken,
  addDetail,
  component,
  componentHasChanged,
  deleteComponent,
  deleteDetail,
  editDetail,
  editComponent,
  form,
  formHasChanged,
  resetComponent,
  refreshPageData,
  selectedComponent,
  setComponentHasChanged,
  setEditComponent,
  setSelectedComponent,
  sortingComponents,
  updateComponent,
  values,
}: ComponentProps) {
  const { icon, idContentComponent, title, type, featured } = component;
  const [activeComponent, setActiveComponent] = useState(false);
  const [componentTitle, setComponentTitle] = useState(title);
  const [componentIcon, setComponentIcon] = useState(icon?.toString() ?? '');
  const [isFeaturedChecked, setIsFeaturedChecked] = useState(featured);
  const [challengesByTypeCombo, setChallengesByTypeCombo] = useState<
    ComboData[]
  >([]);
  const [faqSectionCombo, setFaqSectionCombo] = useState<ComboData[]>([]);
  const [deleteImages, setDeleteImages] = useState<string[]>([]);
  const [deletedLinkIDs, settDeletedLinkIds] = useState<number[]>([]);

  const currentPlatform = getPlatformBaseUrl();

  const { formatMessage } = useIntl();

  /* ----------  COMPONENT FUNCTIONS ---------- */

  const getChallengeType = (type: number): number | null => {
    if (!isChallengeComponent(type)) return null;

    const multiplier = type < 900 ? 10 : 100;

    const filterNumber = CHALLENGE_TYPE * multiplier;

    return type - filterNumber;
  };

  const getChallengesByTypeCombo = async (param: number) => {
    try {
      let response;
      if ((param = WebeatCommunityPageComponentEnum.EXPERIENCE)) {
        response = await api.getDataCall({
          dataPath: 'static-pages/experiences',
          callConfig: {},
        });
      } else {
        response = await api.getCombo({
          id: 'challengeByType',
          param,
        });
      }
      setChallengesByTypeCombo(response.data);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getFaqSectionCombo = async () => {
    try {
      const response = await api.getDataCall({
        dataPath: 'faq-section/all',
        callConfig: {},
      });

      setFaqSectionCombo(response.data);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // TODO this has to be fixed - Se está guardando localmente un combo (de tipo challenge_type) para cada componente
  // Los combos deberian de compartirse para que no se hagan llamadas innecesarias
  useEffect(() => {
    const challengeTypeId = getChallengeType(type);
    if (type === PageComponentEnum.WEBEAT_FAQ) {
      (async () => await getFaqSectionCombo())();
    }

    if (!challengeTypeId) return;

    (async () => await getChallengesByTypeCombo(challengeTypeId))();
  }, [type]);

  useEffect(() => {
    setComponentTitle(title);
    setComponentIcon(icon?.toString() ?? '');
    setIsFeaturedChecked(featured);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, activeComponent]);

  const handleEditTitle = ({ value }: { value: string }) => {
    setComponentTitle(value);
    setComponentHasChanged(true);
  };

  const handleEditIcon = (value: string) => {
    setComponentIcon(value);
    setComponentHasChanged(true);
  };

  const handleEditComponent = (component: IPageComponent) => {
    setSelectedComponent({ ...component });
    setActiveComponent(true);
    setEditComponent(true);
  };

  const handleResetComponent = () => {
    Modal.confirm({
      title: formatMessage({
        id: 'pop.title.changes-will-not-be-saved-are-you-sure',
      }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        setActiveComponent(false);
        resetComponent();
      },
      onCancel() {},
    });
  };

  const handleSaveResource = async () => {
    let _component: any;
    const icon = isNaN(parseInt(componentIcon)) ? 0 : parseInt(componentIcon);

    let data: IPageComponent = {
      ...selectedComponent,
      title: componentTitle,
      icon,
      featured: isFeaturedChecked,
    };

    form.validateFields(async (err, formValues) => {
      if (!err) {
        if (componentHasChanged) {
          _component = await updateComponent({
            ...data,
            idContent: values.idContentPage,
          });

          if (
            'status' in (await _component) &&
            _component.status === 200 &&
            _component.data
          )
            if (deleteImages.length) {
              await api
                .deleteCall({
                  dataPath: `${currentPlatform}${apiPaths.UPLOAD_MEDIA}`,
                  callConfig: { data: { path: deleteImages } },
                })
                .catch((err) => {
                  console.error(err.message);
                });
            }
          refreshPageData();
          setEditComponent(false);
          setActiveComponent(false);
        } else {
          setEditComponent(false);
          setActiveComponent(false);
        }
        form.resetFields();
      } else {
        notification.error({
          message: formatMessage({
            id: 'challenge.required',
          }),
          duration: 0,
        });
      }
    });
  };

  const handleDeleteComponent = (idContentComponent: number) => {
    Modal.confirm({
      title: formatMessage({
        id: 'pop.title.component-cannot-be-restored-are-you-sure',
      }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      async onOk() {
        const _resource: any = await deleteComponent(
          values.idContentPage,
          idContentComponent,
        );

        if ('status' in _resource && _resource.status === 200) {
          refreshPageData();
          setEditComponent(false);
          setActiveComponent(false);
        }
      },
      onCancel() {},
    });
    return true;
  };

  const handleAddDetail = (component: IPageComponent, type: number) => {
    const { idContentComponent } = component;
    addDetail(_blankDetail(idContentComponent, type));
  };

  const handleEditDetail = ({
    index,
    key,
    value,
  }: {
    index: number;
    key: IKey;
    value: any;
  }) => {
    editDetail({ index, key, value });
  };

  const handleDeleteDetail = (index: number) => {
    deleteDetail(index);
    form.resetFields();
  };

  const handleFeatured = (status: boolean) => {
    setIsFeaturedChecked(status);
    setComponentHasChanged(true);
    status ? deleteLinksDetails() : restoreLiknsDetails();

    componentValue.featured = !isFeaturedChecked;
  };

  const deleteLinksDetails = () => {
    const _deletedLinks: number[] = [];
    component.componentDetails.forEach(({ type, value }, index) => {
      if ([PageDetailEnum.LINK].includes(type)) {
        _deletedLinks.push(index);
        handleDeleteDetail(index);
      }
    });

    settDeletedLinkIds(_deletedLinks);
  };

  const restoreLiknsDetails = () => {
    deletedLinkIDs.forEach((index) =>
      handleEditDetail({ index: index, key: 'status', value: true }),
    );
    settDeletedLinkIds([]);
  };

  const handleDeleteImages = (image: string, index: number, key: IKey) => {
    setDeleteImages([...deleteImages, image]);
    handleEditDetail({ index: index, key: key, value: null });
  };

  /* ----------  COMPONENT RENDER COMPONENTS ---------- */
  const renderDetails = (component: IPageComponent) => {
    let { componentDetails, type: componentType } = component;
    let details = formatMessage({
      id: 'contentPage.components.this-component-has-no-elements',
    });

    let comboItems: ComboData[] = [];
    let faqSectionItems: ComboData[] = [];
    let isStaticFAQType =
      isWebeatPlatform() &&
      component.type === WEBEAT_FAQ &&
      values?.type === STATIC_FAQ;

    let isChallengeType = false;

    const check = (
      <>
        <Col xs={2}> </Col>
        <Col>
          <Checkbox
            className="Component__checkbox-autoplay"
            checked={isFeaturedChecked}
            onChange={(e) => handleFeatured(e.target.checked)}
            disabled={!activeComponent}
          />
          <text style={{ margin: '10px', textTransform: 'uppercase' }}>
            {formatMessage({ id: 'challenge.featured' })}
          </text>
        </Col>
      </>
    );

    const webeatFaqCheck = (
      <>
        <Col xs={2}> </Col>
        <Col style={{ paddingBottom: '20px' }}>
          <Checkbox
            className="Component__checkbox-autoplay"
            checked={isFeaturedChecked}
            onChange={(e) => handleFeatured(e.target.checked)}
            disabled={!activeComponent}
          />
          <text style={{ margin: '10px', textTransform: 'uppercase' }}>
            {formatMessage({ id: 'contentPage.webeat-faq-message' })}
          </text>
        </Col>
      </>
    );

    if (!componentDetails.length) {
      if (isChallengeComponent(componentType)) return check;

      if (isStaticFAQType) return webeatFaqCheck;
      return details;
    }

    if (isChallengeComponent(componentType)) componentType = CHALLENGE_TYPE;
    switch (componentType) {
      case CHALLENGE_TYPE:
        comboItems = challengesByTypeCombo;
        isChallengeType = true;
        break;
      case WEBEAT_FAQ:
        faqSectionItems = setFaqComboValue(faqSectionCombo);
        isChallengeType = false;
        break;
    }

    return (
      <>
        {isChallengeType && check}
        {isStaticFAQType && webeatFaqCheck}
        {componentDetails
          .filter(({ status }) => status)
          .map((detail: IPageDetail, index: number) => (
            <WebeatComponentDetail
              key={index}
              {...{
                handleDeleteImages,
                accessToken,
                activeComponent,
                componentType,
                detail,
                form,
                handleDeleteDetail,
                handleEditDetail,
                index,
                comboItems,
                faqSectionItems,
                challengeType: getChallengeType(type),
                idContentPage: values.idContentPage,
              }}
            />
          ))}
      </>
    );
  };

  const renderIcon = (
    <div className="Component__iconSelector">
      <div className="Component__iconSelector--title">
        {formatMessage({ id: 'menu.icon' })}
      </div>
      {renderSelector({
        combo: iconCombo,
        disabled: !activeComponent,
        form,
        handleEdit: handleEditIcon,
        formatMessage,
        key: 'icon',
        required: false,
        value: componentIcon,
      })}
    </div>
  );

  const componentValue = activeComponent ? selectedComponent : component;
  const getComponentTooltip = () => {
    if (type === REDEEM_POINTS) {
      return formatMessage({ id: 'component.tooltip.redeem_points' });
    }
    if (type === PRODUCT_SECTION) {
      return formatMessage({ id: 'component.tooltip.product_section' });
    }
  };

  let collapsibleType = isChallengeComponent(type)
    ? type < 900
      ? `challenge-type-${type - CHALLENGE_TYPE * 10}`
      : `challenge-type-${type - CHALLENGE_TYPE * 100}`
    : PageComponentEnum[type];

  return (
    <div className="Component__collapse">
      <Collapsible
        title={componentTitle ?? ''}
        typeToolTip={getComponentTooltip()}
        type={collapsibleType}
        forceCollapse={sortingComponents ?? undefined}
        editable={{
          disabled: formHasChanged || editComponent,
          canEdit: activeComponent,
          editableFunction: handleEditTitle,
          canDelete: true,
          deleteFunction: handleDeleteComponent,
          deleteId: idContentComponent,
          setEditing: () => handleEditComponent(component),
          saveChanges: handleSaveResource,
          discardChanges: handleResetComponent,
          isEditVisible: true,
        }}
        extra={renderIcon}>
        <Row>
          {linkedImageValidations({
            componentType: component.type,
            details: componentValue.componentDetails,
          })}
          <Row>{renderDetails(componentValue)}</Row>
          {activeComponent && (
            <Row type="flex" justify="center">
              {componentButtons({
                component: componentValue,
                handleAddDetail,
                formatMessage,
              })}
            </Row>
          )}
        </Row>
      </Collapsible>
    </div>
  );
}
