import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ListOfValuesDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'listOfValuesSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idList',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: false,
                  width: 16,
                },
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'listOfValues.type' }),
          key: 'type',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'listOfValues.description' }),
          key: 'description',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'id',
          key: 'idList',
          type: 'number',
          operand: 'like',
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: true,
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'listOfValuesList',
        rowKey: 'idList',
        panelHeader: 'List of Values',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'type',
        // customButtons: ['listOfValuesI18nButton']
      },
      fields: [
        {
          title: 'Id Country',
          key: 'idCountry',
          position: 0,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'listOfValues.type' }),
          key: 'type',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ascend',
          width: MID,
        },
        {
          title: formatMessage({ id: 'listOfValues.value' }),
          key: 'value',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'listOfValues.description' }),
          key: 'description',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'listOfValuesEdit',
        panelHeader: 'Country Edit',
        primaryKey: 'idList',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idList',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: false,
                  width: 6,
                },
                {
                  key: 'type',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'value',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'description',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'listOfValues.idList' }),
          key: 'idList',
          type: 'number',
        },
        {
          title: formatMessage({ id: 'listOfValues.type' }),
          key: 'type',
          type: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'listOfValues.value' }),
          key: 'value',
          type: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'listOfValues.description' }),
          key: 'description',
          type: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: true,
        },
        {
          title: 'Clauses',
          key: 'salesforceClauses',
          type: 'combo',
          comboId: 'salesforceClauses',
          mandatory: false,
          disabled: false,
          reloadComponentIds: ['challengechallengeEdit'],
          visible: false,
          mustRender: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'ListOfValuesDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ListOfValuesDashboard;
