import { isEmpty } from 'lodash';
import { IFetchedMenu, IUserPermission, MenuItem } from '../app/AppInterfaces';

export const formattedMenu = (
  menuItems: IFetchedMenu[],
  permissions: string[],
) => {
  let formattedMenuItems: MenuItem[] = [];
  menuItems
    .filter((m) => m.status)
    .sort((prev, next) => {
      return prev.order - next.order;
    })
    .forEach((menuItem: IFetchedMenu) => {
      let menu: MenuItem = {
        id: menuItem.id,
        nameOfMenu: menuItem.nameOfMenu.includes('.')
          ? menuItem.nameOfMenu.split('.').slice(-1).toString()
          : menuItem.nameOfMenu,
        route: menuItem.route ? `/${menuItem.route.split('/')[1]}` : '',
        welcome: menuItem.welcome,
        disabled: menuItem.disabled,
        mustRender: menuItem.visible,
        permission: menuItem.idPermissions,
        icon: menuItem.icon,
        order: menuItem.order,
        subMenu: [],
      };

      if (!isEmpty(menuItem.subMenu)) {
        menuItem.subMenu
          .filter((m) => m.status)
          .forEach((sub: IFetchedMenu) => {
            menu.subMenu.push({
              id: sub.id,
              nameOfMenu: sub.nameOfMenu.includes('.')
                ? sub.nameOfMenu.split('.').slice(-1).toString()
                : sub.nameOfMenu,
              route: sub.route ? `/${sub.route.split('/')[1]}` : '',
              welcome: sub.welcome,
              disabled: sub.disabled,
              mustRender: sub.visible,
              permission: sub.idPermissions,
              icon: sub.icon,
              order: sub.order,
              subMenu: [],
            });
          });
      }

      formattedMenuItems.push(menu);
    });
  // return formattedMenuItems;
  return securedMenu(formattedMenuItems, permissions);
};

export const securedMenu = (menuItems: MenuItem[], permissions: string[]) => {
  let newMenu: MenuItem[] = [];

  menuItems.forEach((menuItem) => {
    if (userHasMenuPermission(menuItem.permission, permissions)) {
      if (!isEmpty(menuItem.subMenu)) {
        let newSubMenu: MenuItem[] = [];
        menuItem.subMenu.forEach((subMenuItem) => {
          if (userHasMenuPermission(subMenuItem.permission, permissions)) {
            newSubMenu.push(subMenuItem);
          }
        });
        if (
          !isEmpty(newSubMenu) ||
          (isEmpty(newSubMenu) && menuItem.route !== '')
        ) {
          const newMenuItem = {
            ...menuItem,
            subMenu: newSubMenu,
          };
          newMenu.push(newMenuItem);
        }
      } else if (menuItem.route !== '') newMenu.push({ ...menuItem });
    }
  });

  return newMenu;
};

export const userHasMenuPermission = (
  menuPermission: IUserPermission | null,
  permissions: string[],
): boolean => {
  if (menuPermission !== null) {
    const menuWithPermission = permissions.find(
      (permission) => menuPermission.name === permission,
    );
    return menuWithPermission !== undefined;
  } else return true;
};
