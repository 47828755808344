import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatMenuChildDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatMenuChildSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],

      fields: [
        {
          title: 'Parent Id',
          key: 'parentId',
          value: 'parentId',
          type: 'text',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatMenuChildViewList',
        rowKey: 'idMenu',
        panelHeader: formatMessage({ id: 'menu.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'userMenu.id' }),
          key: 'idMenu',
          position: 0,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'userMenu.route' }),
          key: 'route',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.order' }),
          key: 'order',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.type' }),
          key: 'type',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'selector',
          comboId: 'menuTypes',
          selectorPath: '/menu/types',
          width: MID,
        },
        {
          title: formatMessage({ id: 'userMenu.parentId' }),
          key: 'parentId',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'selector',
          comboId: 'menuParents',
          selectorPath: '/menu/parents',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatMenuChildEdit',
        panelHeader: formatMessage({ id: 'menu.edit' }),
        primaryKey: 'idMenu',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: formatMessage({ id: 'menu' }),
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 23,
                },
                {
                  key: 'illustration',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'pageType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'webeat-route',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: false,
                  width: 4,
                },
                {
                  key: 'name',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 11,
                },
                {
                  key: 'type',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'openInNewTab',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'platform',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'order-optional',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 3,
                },
                {
                  key: 'showInMenu',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'online',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'idGrid.type',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
              ],
            },
          ],
        },
        {
          index: 1,
          title: formatMessage({ id: 'menu.edit.grid.banners' }),
          mustRender: true,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'GRID',
              },
              {
                key: 'idMenu',
                operand: 'exist',
                type: 'visible',
              },
            ],
          ],
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'gridBanners',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
        {
          index: 2,
          title: formatMessage({ id: 'menu.edit.grid.footer.seo' }),
          mustRender: true,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'GRID',
              },
              {
                key: 'idMenu',
                operand: 'exist',
                type: 'visible',
              },
            ],
          ],
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idGrid.seoFooterTitle',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoSubtitle1',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoDescription1',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoSubtitle2',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoDescription2',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoSubtitle3',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoDescription3',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoSubtitle4',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoDescription4',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoSubtitle5',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.seoDescription5',
                  yPosition: 5,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
        {
          index: 3,
          title: formatMessage({ id: 'menu.edit.grid.custom.seo' }),
          mustRender: true,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'GRID',
              },
              {
                key: 'idMenu',
                operand: 'exist',
                type: 'visible',
              },
            ],
          ],
          subgroups: [
            {
              index: 3,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idGrid.pageTitle',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.pageDescription',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idGrid.pageKeywords',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: '',
          key: 'webeatMenuPages',
          type: 'selector',
          comboId: 'webeatMenuPages',
          selectorPath: '/menu/pages',
        },
        {
          title: formatMessage({ id: 'userMenu.parentId' }),
          key: 'parentId',
          value: 'parentId',
          type: 'text',
          unique: true,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'userMenu.illustration' }),
          key: 'illustration',
          type: 'SVGSelector',
          comboId: 'menuIcons',
          selectorPath: '/design-resources/illustration',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.type' }),
          key: 'type',
          type: 'selector',
          mandatory: true,
          disabled: false,
          comboId: 'type-child',
          selectorPath: '/menu/type-childs',
          behaviours: [
            [
              {
                key: 'parentId',
                type: 'combo',
                params: true,
              },
            ],
          ],
        },
        {
          title: 'Platform',
          key: 'platform',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          initialValue: props.currentPlatform,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'userMenu.order' }),
          key: 'order-optional',
          type: 'customField',
          tooltip: formatMessage({ id: 'userMenu.order.tooltip' }),
        },
        {
          title: formatMessage({ id: 'userMenu.openInNewTab' }),
          key: 'openInNewTab',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          initialVisibility: false,
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
          behaviours: [
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'ABSOLUTE_ROUTE',
              },
            ],
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'STATIC_PAGE',
              },
            ],
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'FRONT_PAGE',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'userMenu.pageType' }),
          key: 'pageType',
          type: 'selector',
          comboId: 'pageTypes',
          selectorPath: '/menu/page-types',
          mandatory: true,
          disabled: false,
          initialValue: 'GRID',
        },
        {
          title: formatMessage({ id: 'userMenu.grid.type' }),
          key: 'idGrid.type',
          type: 'selector',
          comboId: 'gridTypes',
          selectorPath: '/grid/types',
          mandatory: true,
          disabled: true,
          initialValue: '0',
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'GRID',
              },
            ],
          ],
        },
        {
          title: 'Status',
          key: 'status',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 100,
          hideClear: true,
          default: true,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.route' }),
          key: 'webeat-route',
          type: 'customField',
          mandatory: false,
        },
        {
          title: formatMessage({ id: 'userMenu.online' }),
          key: 'online',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          initialVisibility: false,
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
          behaviours: [
            [
              {
                key: 'pageType',
                operand: 'like',
                type: 'visible',
                value: 'GRID',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'userMenu.showInMenu' }),
          key: 'showInMenu',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.banner' }),
          singleTitle: 'Banner',
          key: 'gridBanners',
          type: 'customField',
          module: 'grid',
          index: 1,
          isObjectArray: true,
          arrayMaxSize: 9,
          objectArrayFields: [
            {
              title: 'Image',
              value: 'image',
              fields: [
                {
                  key: 'fromUrl',
                  title: formatMessage({ id: 'userMenu.banner.fromUrl' }),
                  type: 'check',
                  initialValue: false,
                },
                {
                  key: 'imageUrl',
                  title: formatMessage({ id: 'userMenu.banner.imageUrl' }),
                  type: 'image',
                  mandatory: false,
                  disabled: false,
                },
                {
                  title: formatMessage({
                    id: 'userMenu.banner.imageBannerUrl',
                  }),
                  key: 'imageBannerUrl',
                  type: 'text',
                  validationType: 'text',
                  disabled: false,
                  mandatory: false,
                  length: 200,
                },
                {
                  key: 'imageBannerSubtitle',
                  title: formatMessage({
                    id: 'userMenu.banner.imageBannerTitle',
                  }),
                  type: 'text',
                  length: 20,
                },
                {
                  key: 'imageBannerTitle',
                  title: formatMessage({
                    id: 'userMenu.banner.imageBannerSubtitle',
                  }),
                  type: 'text',
                  length: 30,
                },
                {
                  key: 'imageBannerDescription',
                  title: formatMessage({
                    id: 'userMenu.banner.imageBannerDescription',
                  }),
                  type: 'text',
                  length: 100,
                  tooltip:
                    'Este campo solo se aplicará al banner simple, el banner doble no mostrará descripción',
                },
                {
                  key: 'imageBannerTextButton',
                  title: formatMessage({
                    id: 'userMenu.banner.imageBannerTextButton',
                  }),
                  type: 'text',
                  length: 12,
                },
                {
                  key: 'imageTextColumns',
                  title: formatMessage({
                    id: 'userMenu.banner.imageTextColumns',
                  }),
                  initialValue: 12,
                  type: 'number',
                  min: 1,
                },
                {
                  key: 'imageBannerTypeButton',
                  title: formatMessage({
                    id: 'userMenu.banner.imageBannerTypeButton',
                  }),
                  type: 'selector',
                  objectArraySelector: true,
                  comboId: 'designResourcesButton',
                  selectorPath: '/design-resources/button',
                },
                {
                  key: 'imageTextAlign',
                  title: formatMessage({
                    id: 'userMenu.banner.imageTextAlign',
                  }),
                  type: 'selector',
                  objectArraySelector: true,
                  comboId: 'designResourcesBannerAlign',
                  selectorPath: '/design-resources/bannerAlign',
                },
                {
                  key: 'color',
                  title: formatMessage({ id: 'userMenu.banner.color' }),
                  type: 'selector',
                  objectArraySelector: true,
                  comboId: 'designResourcesColor',
                  selectorPath: '/design-resources/color',
                },
              ],
            },
          ],
        },
        {
          title: formatMessage({ id: 'menu.grid.seo.footer.title' }),
          key: 'idGrid.seoFooterTitle',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: false,
          length: 200,
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.subtitle' },
            { number: 1 },
          ),
          key: 'idGrid.seoSubtitle1',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.description' },
            { number: 1 },
          ),
          key: 'idGrid.seoDescription1',
          type: 'wyswyg',
          validationType: 'text',
          length: 5000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'idGrid.seoSubtitle1',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.subtitle' },
            { number: 2 },
          ),
          key: 'idGrid.seoSubtitle2',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.description' },
            { number: 2 },
          ),
          key: 'idGrid.seoDescription2',
          type: 'wyswyg',
          validationType: 'text',
          length: 5000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'idGrid.seoSubtitle2',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.subtitle' },
            { number: 3 },
          ),
          key: 'idGrid.seoSubtitle3',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.description' },
            { number: 3 },
          ),
          key: 'idGrid.seoDescription3',
          type: 'wyswyg',
          validationType: 'text',
          length: 5000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'idGrid.seoSubtitle3',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.subtitle' },
            { number: 4 },
          ),
          key: 'idGrid.seoSubtitle4',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.description' },
            { number: 4 },
          ),
          key: 'idGrid.seoDescription4',
          type: 'wyswyg',
          validationType: 'text',
          length: 5000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'idGrid.seoSubtitle4',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.subtitle' },
            { number: 5 },
          ),
          key: 'idGrid.seoSubtitle5',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage(
            { id: 'menu.grid.seo{number}.description' },
            { number: 5 },
          ),
          key: 'idGrid.seoDescription5',
          type: 'wyswyg',
          validationType: 'text',
          length: 5000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'idGrid.seoSubtitle5',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'menu.grid.page.title' }),
          key: 'idGrid.pageTitle',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage({ id: 'menu.grid.page.description' }),
          key: 'idGrid.pageDescription',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: formatMessage({ id: 'menu.grid.page.keywords' }),
          key: 'idGrid.pageKeywords',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatMenuChildDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatMenuChildDashboard);
