//TODO Delete non used interfaces (ISpeciality, ISpeciality)
export interface ISpeciality {
  id: number;
  name: string;
  type: 'Staff' | 'HCP';
}

export enum UserCenterEnum {
  DENIED = 1,
  PENDING = 2,
  GRANT = 3,
}

export enum UserStatus {
  ACTIVE = "active",
  DELETED = "deleted"
}

export const specialties: ISpeciality[] = [
  { id: 67, name: 'register.speciality.dermatologist', type: 'HCP' },
  { id: 80, name: 'register.speciality.plastic-surgeon', type: 'HCP' },
  { id: 88, name: 'register.speciality.general-practitioner', type: 'HCP' },
  { id: 83, name: 'register.speciality.physician-assistant', type: 'HCP' },
  { id: 89, name: 'register.speciality.medical-assistant', type: 'HCP' },
  { id: 84, name: 'register.speciality.nurse-practitioner', type: 'HCP' },
  { id: 90, name: 'register.speciality.aesthetician', type: 'HCP' },
  { id: 78, name: 'register.speciality.other-hcp', type: 'HCP' },
  { id: 77, name: 'register.speciality.nurse', type: 'Staff' },
  { id: 91, name: 'register.speciality.staff', type: 'Staff' },
  { id: 92, name: 'register.speciality.office-manager', type: 'Staff' },
  { id: 93, name: 'register.speciality.other-staff', type: 'Staff' },
  { id: 71, name: 'register.speciality.primary-care', type: 'HCP' },
  { id: 74, name: 'register.speciality.hygienists', type: 'HCP' },
  { id: 72, name: 'register.speciality.dentist-stomatologist', type: 'HCP' },
];

export const getUserSpecialty = (id: string, formatMessage: any) => {
  let specialty = '-';

  if (!id) return specialty;

  specialties.forEach((s) => {
    if (s.id.toString() === id) specialty = formatMessage({ id: s.name });
  });

  return specialty;
};

export const getUserType = (id: string) => {
  let specialty = '-';

  if (!id) return specialty;

  specialties.forEach((s) => {
    if (s.id.toString() === id) specialty = s.type;
  });

  return specialty;
};
