import React from 'react';
import {
  Input,
  InputNumber,
  DatePicker,
  Select,
  TimePicker,
  Checkbox,
  Switch,
  Button,
  Icon,
  // Radio
} from 'antd';

// import Autocomplete from '../fields/Autocomplete';
import config from '../config';
import './FieldStyles.css';
import customBehaviours from '../extensions/customBehaviours';
import { ICompleteField } from '../forms/formInterfaces';
import { SearchFormRenderProps } from '../forms/search/SearchFormRender';
import { EditFormRenderProps } from '../forms/edit/EditFormRender';
import { ReducersState } from '../reducers';
import { ComboData } from '../combos/ComboInterfaces';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import UploadComponent from '../challenge/Components/UploadComponent';
import { UPLOAD_ACTION_IMAGE } from '../shared';
import WYSWYGComponent from '../contentPage/components/WYSWYGComponent';
import WYSWYGLiteComponent from '../contentPage/components/WYSWYGLiteComponent';
import { getValueByPath } from '../forms/fieldRender';
import { MarkdownInputComponent } from '../contentPage/components/WebeatTextMarkdownComponents';
import { WebeatSVGSelector } from './WebeatSVGSelector/WebeatSVGSelector';

const comboRender = (
  field: ICompleteField,
  combos: ReducersState['combos'],
  componentId: string,
) => {
  const { type, key, comboId } = field;
  if (type === 'comboCustom') {
    return combos.combosCustom[comboId!] && combos.combosCustom[comboId!].data
      ? combos.combosCustom[comboId!].data.map((option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.description}
            </Select.Option>
          );
        })
      : false;
  } else {
    return combos.combos[componentId] &&
      combos.combos[componentId][key] &&
      combos.combos[componentId][key][comboId!] &&
      combos.combos[componentId][key][comboId!].data
      ? combos.combos[componentId][key][comboId!].data.map(
          (option: ComboData) => {
            return (
              <Select.Option
                key={option.value.toString()}
                value={option.value.toString()}>
                {option.colorCode ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        background: option.colorCode,
                        marginRight: 10,
                      }}></div>
                    {option.description}
                  </div>
                ) : field.comboId === 'brands' ? (
                  option.label
                ) : (
                  option.description
                )}
              </Select.Option>
            );
          },
        )
      : false;
  }
};

export default function Fields(
  field: ICompleteField,
  parentProps: SearchFormRenderProps | EditFormRenderProps,
) {
  const { props, handleChangeField } = parentProps;
  const {
    intl,
    options,
    values,
    combos,
    params,
    userPermissions,
    // selectedRow,
    // form,
    // setFormData,
    // targetId,
    // setFormStateFlag
  } = props;

  const value = values[field.key] || getValueByPath(values, field.key);

  const { componentId } = params;
  const { forceDisabled, isParentDisabled, forceBehaviours, paramsDisabled } =
    field;
  let disabled: boolean | undefined;
  let isFieldDisabled =
    isParentDisabled !== undefined
      ? isParentDisabled
      : field.disabled !== undefined
      ? field.disabled
      : undefined;
  if (forceBehaviours) {
    if (forceDisabled && isFieldDisabled) disabled = true;
    else disabled = false;
  } else {
    disabled =
      forceDisabled !== undefined
        ? forceDisabled
        : isFieldDisabled !== undefined
        ? isFieldDisabled
        : undefined;
  }

  if (values[field.key] !== undefined) {
    if (field.disabledOnEdit) disabled = true;
  }

  if (field.type === 'combo' && field.hasOwnProperty('parentValue')) {
    if (field.disabled === true) disabled = true;
  }

  if (field.alwaysDisabled) disabled = field.alwaysDisabled;
  else if ('permissions' in field) {
    if (userPermissions!.includes(field.permissions![0].name))
      disabled = field.permissions![0].disabled!;
  } else {
    disabled = customBehaviours({ field, values, disabled, componentId });
  }

  if (paramsDisabled !== undefined) {
    if (
      params.type === 'search' ||
      (params.type === 'edit' &&
        config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD)
    )
      disabled = paramsDisabled;
  }

  if (disabled === undefined) disabled = false;

  switch (field.type) {
    // case 'advancedSearch':
    //   return Autocomplete({ ...parentProps, comboRender });
    case 'text':
    case 'email':
      return (
        <Input
          type={field.type}
          title={field.title}
          size={options.fieldSize}
          disabled={disabled}
          maxLength={field.length}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value,
            })
          }
        />
      );
    case 'textarea':
      return (
        <Input.TextArea
          title={field.title}
          disabled={disabled}
          style={{ paddingTop: '3px' }}
          autosize={{
            minRows: 5,
            maxRows: 6,
          }}
          maxLength={field.length}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value,
            })
          }
        />
      );
    case 'number':
      return (
        <InputNumber
          className="InputNumber"
          // placeholder={field.title}
          title={field.title}
          size={options.fieldSize}
          precision={field.precision || 0}
          step={field.step}
          min={field.min}
          disabled={disabled}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value,
            })
          }
        />
      );
    case 'currency':
      return (
        <Input
          // placeholder={field.title}
          type="number"
          addonAfter="€"
          title={field.title}
          size={options.fieldSize}
          step={field.step || 0.01}
          min={field.min}
          disabled={disabled}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value,
            })
          }
        />
      );
    case 'percent':
      return (
        <Input
          type="number"
          // placeholder={field.title}
          addonAfter="%"
          title={field.title}
          size={options.fieldSize}
          step={field.step || 0.01}
          max={100}
          min={field.min}
          disabled={disabled}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value,
            })
          }
        />
      );
    case 'comboCustom':
    case 'combo':
    case 'selector':
      if (field.objectArraySelector) {
        let key = field.key;
        if (field.key.includes('.')) {
          const splitKey = field.key.split('.');
          key = splitKey[splitKey.length - 1];
        }
        return !field.multiSelect ? (
          <Select
            showSearch
            optionFilterProp="children"
            // allowClear={!field.hasOwnProperty('initialValue')}
            dropdownMatchSelectWidth={false}
            allowClear={!field?.hideClear}
            size={options.fieldSize}
            disabled={disabled}
            loading={
              combos.combos[componentId] &&
              combos.combos[componentId][key] &&
              combos.combos[componentId][key][field.comboId!] &&
              combos.combos[componentId][key][field.comboId!].isLoading
            }
            placeholder={
              field?.placeholder || intl.formatMessage({ id: 'checkbox.all' })
            }
            notFoundContent={intl.formatMessage({
              id: 'combo.data.notfound',
            })}
            filterOption={(input: any, option: any) =>
              option.props.children.type === 'div'
                ? option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                : option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value: any, data: any) => {
              handleChangeField({
                type: field.type,
                id: field.key,
                path: field.path,
                value:
                  value >= 0 || typeof value === 'string' ? data.key : null,
              });
            }}>
            {comboRender({ ...field, key }, combos, componentId)}
          </Select>
        ) : (
          <Select
            allowClear={!field?.hideClear}
            dropdownMatchSelectWidth={false}
            disabled={disabled}
            loading={
              combos?.combos[componentId]?.[field.key]?.[field.comboId!]
                ?.isLoading
            }
            mode="multiple"
            notFoundContent={intl.formatMessage({
              id: 'combo.data.notfound',
            })}
            optionFilterProp="children"
            showSearch
            size={options.fieldSize}
            filterOption={(input: any, option: any) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={
              field?.placeholder || intl.formatMessage({ id: 'checkbox.all' })
            }
            onChange={(value: any, data: any) => {
              handleChangeField({
                type: field.type,
                id: field.key,
                value,
                multiSelectId: field.multiSelectId,
              });
            }}>
            {comboRender(field, combos, componentId)}
          </Select>
        );
      }
      return !field.multiSelect ? (
        <Select
          showSearch
          optionFilterProp="children"
          // allowClear={!field.hasOwnProperty('initialValue')}
          dropdownMatchSelectWidth={false}
          allowClear={!field?.hideClear}
          size={options.fieldSize}
          disabled={disabled}
          loading={
            field.type === 'comboCustom'
              ? false
              : combos.combos[componentId] &&
                combos.combos[componentId][field.key] &&
                combos.combos[componentId][field.key][field.comboId!] &&
                combos.combos[componentId][field.key][field.comboId!].isLoading
          }
          placeholder={
            field?.placeholder || intl.formatMessage({ id: 'checkbox.all' })
          }
          notFoundContent={intl.formatMessage({
            id: 'combo.data.notfound',
          })}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={(value: any, data: any) => {
            handleChangeField({
              type: field.type,
              id: field.key,
              path: field.path,
              value: value >= 0 || typeof value === 'string' ? data.key : null,
            });
          }}>
          {comboRender(field, combos, componentId)}
        </Select>
      ) : (
        <Select
          allowClear={!field?.hideClear}
          dropdownMatchSelectWidth={false}
          disabled={disabled}
          loading={
            combos?.combos[componentId]?.[field.key]?.[field.comboId!]
              ?.isLoading
          }
          mode="multiple"
          notFoundContent={intl.formatMessage({
            id: 'combo.data.notfound',
          })}
          optionFilterProp="children"
          showSearch
          size={options.fieldSize}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={
            field?.placeholder || intl.formatMessage({ id: 'checkbox.all' })
          }
          onChange={(value: any, data: any) => {
            handleChangeField({
              type: field.type,
              id: field.key,
              value,
              multiSelectId: field.multiSelectId,
            });
          }}>
          {comboRender(field, combos, componentId)}
        </Select>
      );
    case 'time':
      return (
        <TimePicker
          // placeholder={field.title}
          format={field.displayFormat}
          size={options.fieldSize}
          disabled={disabled}
          onChange={(date: moment.Moment) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: date,
              format: field.format,
            })
          }
        />
      );
    case 'date':
    case 'datetime':
      return (
        <DatePicker
          showTime={field.type === 'datetime'}
          // placeholder={field.title}
          format={field.displayFormat}
          size={options.fieldSize}
          disabled={disabled}
          onChange={(date: moment.Moment | null, dateString: string) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: date,
              format: field.format,
            })
          }
        />
      );
    case 'rangerpicker':
      return (
        <DatePicker.RangePicker
          format={field.displayFormat}
          size={options.fieldSize}
          disabled={disabled}
          onChange={(
            dates: RangePickerValue,
            dateStrings: [string, string],
          ) => {
            handleChangeField({
              type: field.type,
              id: field.key,
              value: dateStrings,
              format: field.format,
            });
          }}
        />
      );
    case 'check':
      return (
        <Checkbox
          className="form__checkbox"
          checked={value || false}
          disabled={disabled}
          onChange={(e: CheckboxChangeEvent) => {
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.checked,
            });
          }}>
          <span className="form__checkLabel">{field.title}</span>
        </Checkbox>
      );
    case 'checkSelect':
      return (
        <Select
          optionFilterProp="children"
          size={options.fieldSize}
          disabled={disabled}
          onChange={(value: number) =>
            handleChangeField({ id: field.key, value, type: field.type })
          }
          placeholder={intl.formatMessage({ id: 'checkbox.all' })}
          allowClear={!field.hasOwnProperty('initialValue')}>
          <Select.Option key={field.key} value="true">
            {intl.formatMessage({ id: 'checkbox.true' })}
          </Select.Option>
          <Select.Option key={field.key} value="false">
            {intl.formatMessage({ id: 'checkbox.false' })}
          </Select.Option>
        </Select>
      );
    case 'switch':
      return (
        <Switch
          defaultChecked={field.checked}
          disabled={disabled}
          onChange={(checked: boolean) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: checked,
            })
          }
        />
      );
    case 'image':
      return UploadComponent({
        format: 'image',
        value,
        token: props.accessToken,
        disabled: field.disabled,
        uploadActions: [UPLOAD_ACTION_IMAGE],
        setValue: (value?: string | null) => {
          handleChangeField({
            type: field.type,
            id: field.key,
            value,
          });
        },
        children: (
          <Button
            className="uploadButton uploadButton__margin-top"
            disabled={field.disabled}>
            <Icon type="upload" />
            {intl.formatMessage({
              id: 'upload.buttonTitle',
            })}
          </Button>
        ),
        primaryEntityId: values?.idChallenge,
        module: 'challenge',
      });
    case 'imageConsumable':
      return UploadComponent({
        format: 'image',
        value,
        token: props.accessToken,
        disabled: field.disabled,
        uploadActions: [UPLOAD_ACTION_IMAGE],
        consumableUpload: true,
        setValue: (value?: string | null) => {
          handleChangeField({
            type: field.type,
            id: field.key,
            value,
          });
        },
        children: (
          <Button
            className="uploadButton uploadButton__margin-top"
            disabled={field.disabled}>
            <Icon type="upload" />
            {intl.formatMessage({
              id: 'upload.buttonTitle',
            })}
          </Button>
        ),
        primaryEntityId: values?.idChallenge,
        module: 'challenge',
      });
    case 'wyswyg':
      return (
        <WYSWYGComponent
          title=""
          height={300}
          handleEdit={(value) => {
            return handleChangeField({
              type: field.type,
              id: field.key,
              value,
            });
          }}
          value={value}
        />
      );
    case 'wyswygLite':
      return (
        <WYSWYGLiteComponent
          title=""
          height={300}
          handleEdit={(value) => {
            return handleChangeField({
              type: field.type,
              id: field.key,
              value,
            });
          }}
          value={value}
        />
      );
    case 'markdown':
      return (
        <MarkdownInputComponent
          title={field.title}
          disabled={disabled}
          handleEdit={(value: string) => {
            return handleChangeField({
              type: field.type,
              id: field.key,
              value,
            });
          }}
          value={value}
          editMode={!disabled}
        />
      );
    case 'SVGSelector':
      return (
        <WebeatSVGSelector
          imagesData={combos.combos.webeatMenuEdit[field.key].menuIcons.data}
          value={value}
          parentProps={parentProps}
          field={field}
        />
      );
    default:
      return (
        <Input
          type={field.type || 'text'}
          title={field.title}
          size={options.fieldSize}
          disabled={disabled}
          maxLength={field.length}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value,
            })
          }
        />
      );
  }
}
