export enum PIN_PlatformID_Enum {
  PIN_ARGENTINA = 'pin-ar',
  PIN_USA = 'pin-us',
  PIN_BRAZIL = 'pin-br',
  PIN_CHILE = 'pin-cl',
  PIN_SPAIN = 'pin-es',
  PIN_ITALY = 'pin-it',
  PIN_PERU = 'pin-pe',
  PIN_PORTUGAL = 'pin-pt',
  PIN_COLOMBIA = 'pin-co',
  PIN_MEXICO = 'pin-mx',
  PIN_GERMANY = 'pin-de',
  PIN_GUATEMALA = 'pin-gt'
}

export enum CON_PlatformID_Enum {
  CON_ARGENTINA = 'con-ar',
  CON_CHILE = 'con-cl',
  CON_COLOMBIA = 'con-co',
  CON_MEXICO = 'con-mx',
  CON_PERU = 'con-pe',
  CON_SPAIN = 'con-es',
  CON_PORTUGAL = 'con-pt',
  CON_BRAZIL = 'con-br',
  CON_ITALY = 'con-it',
  CON_DEUTSCH = 'con-de',
  // TODO fix this because webeat is not con platform.
  WEBEAT_ES = 'webeat-es',
  WEBEAT_MX = 'webeat-mx',
  WEBEAT_CL = 'webeat-cl',
  WEBEAT_CO = 'webeat-co',
}

export enum CON_NOT_SPAIN_PlatformId_Enum {
  CON_ARGENTINA = 'con-ar',
  CON_CHILE = 'con-cl',
  CON_COLOMBIA = 'con-co',
  CON_MEXICO = 'con-mx',
  CON_PERU = 'con-pe',
  CON_PORTUGAL = 'con-pt',
  CON_BRAZIL = 'con-br',
  CON_ITALY = 'con-it',
  CON_DEUTSCH = 'con-de',
}