export enum ConsumableNatureEnum {
    WELCOME,
    POS,
    PROMO,
    POST,
    AD,
    ADVOCACY,
    EVENT,
    PRODUCT,
    PRODUCT_SAP,
    DI, // DI
    DI_SAP, // DI
  }