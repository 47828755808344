import React from 'react';
import WebeatContentPageForm from './components/WebeatPageForm';
import WebeatPageComponents from './WebeatPageComponents';
import { IPageListProps } from './WebeatContentPage';
import { IPageRenderProps } from './shared';

export type IPageRenderListProps = IPageListProps & IPageRenderProps;
export default function WebeatContentPageRender(props: IPageRenderListProps) {
  const { params, refreshPageData, values } = props;

  return (
    <>
      <WebeatContentPageForm {...props} />
      {values && values.idContentPage && (
        <WebeatPageComponents
          {...{
            params,
            refreshPageData,
          }}
        />
      )}
    </>
  );
}
