import { List, notification } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import * as api from '../api';
import apiPaths from '../apiPaths';
import { IRow } from '../app/AppInterfaces';
import { appComponents } from '../components';
import { IEditField } from '../fields/FieldsInterfaces';
import {
  setFormData,
  setInitialState,
  updateChallengeData,
} from '../forms/edit/editActions';
import { IEditParams } from '../forms/formInterfaces';
import { ReducersState } from '../reducers';
import {
  fetchRow,
  getTableData,
  loadChallengeData,
  setFormStateFlag,
  setSelectedRow,
} from '../tables/tableActions';
import { getPlatformBaseUrl, isPINPlatform } from '../utils';
import './Challenge.css';
import { IResource, IResourceDetail } from './ChallengeInterfaces';
import ChallengeRender from './ChallengeRender';
import { isConExperience, validateSlug } from './ChallengeUtils';
import { ChallengeTypeEnum, ResourceTypeEnum } from './Enums';
import { ChallengeDateEnum } from './Enums/challengeDateEnum.enum';
import {
  SetChallengeLoading,
  createChallenge,
  createResourceFromTemplate,
  deleteResource,
  resetChallenge,
  setChallengeInitialState,
  setEditionChallenge,
  setEditionResource,
  setLoading,
  setQuiz,
} from './challengeActions';
import {
  getVideoTypeSelectorById,
  validateCoverVideoUrl,
} from './Components/Resource.utils';
const { TALK, FORMATION, CONGRESS, FORMATION_CON } = ChallengeTypeEnum;
const { QUIZ, QUIZ_DERMO, EVENT } = ResourceTypeEnum;

const currentPlatform = getPlatformBaseUrl();

interface IChallengeProps {
  intl: typeof InjectedIntlProps;
  params: IEditParams;
  fields: IEditField[];
}

export interface UpdateRecordProps {
  targetId: string;
  params: IEditParams;
  getTableData: Function;
  loadChallengeData?: Function;
  setFormStateFlag: Function;
  setSelectedRow: Function;
  values: IRow;
  updateChallengeData: ({
    dataPath,
    componentId,
    values,
  }: {
    dataPath: string;
    componentId: string;
    values: IRow;
  }) => (dispatch: Dispatch<SetChallengeLoading>) => Promise<any>;
}

export type IChallengeListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IChallengeProps;

class Challenge extends React.PureComponent<IChallengeListProps> {
  constructor(props: IChallengeListProps) {
    super(props);
    const { params, setInitialState } = props;
    const { componentId } = params;

    setInitialState({
      componentId,
      targetId: appComponents[componentId].targetId,
      fieldsConfig: [],
      values: {},
      selectedTab: '0',
      resetFormTrigger: false,
    });
  }

  async componentDidMount() {
    const { setChallengeInitialState, setFormData, selectedRow, params } =
      this.props;

    setChallengeInitialState();

    const code = this.formatterIzoomCode(selectedRow);

    setFormData({
      componentId: params.componentId,
      values: { ...selectedRow, code },
    });
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: IChallengeListProps) {
    const { selectedRow, params, setFormData } = this.props;

    if (prevProps.selectedRow && selectedRow !== prevProps.selectedRow) {
      const code = this.formatterIzoomCode(selectedRow);

      setFormData({
        componentId: params.componentId,
        values: { ...selectedRow, code },
      });
    }
  }

  /**
   * Formatter izoomCode
   * @example YYYY_MM_Text_ChallengeType
   * @param challengeRow
   * @returns {string}
   */
  formatterIzoomCode(challengeRow: IRow): string {
    let codeDescription: string = '';
    const code = challengeRow.code;

    const iZoomDateCode: string = challengeRow?.startDate
      ? moment(challengeRow?.startDate).format('YYYY_MM_')
      : 'YYYY_MM_';

    if (code) {
      codeDescription = challengeRow.code.substring(
        iZoomDateCode.length,
        challengeRow.code.length,
      );
    }

    return `${iZoomDateCode}${codeDescription}`;
  }

  async setFormInitialValues(fields: IEditField[]) {
    let values: IRow = {};

    fields.forEach(({ initialValue, key, mustRender, type }) => {
      if (
        (mustRender === undefined || mustRender) &&
        ['check', 'checkSelect'].includes(type)
      )
        values[key] = false;

      if (initialValue !== undefined) {
        values = { ...values, [key]: initialValue };
      }
    });

    return values;
  }

  handleChangeField = async ({
    id,
    value,
    type,
    multiSelectId,
    deleteImage,
  }: {
    id: string;
    value: any;
    type: string;
    multiSelectId?: string;
    deleteImage?: string;
  }) => {
    const {
      params,
      values,
      targetId,
      setFormStateFlag,
      setFormData,
      selectedRow,
      formHasChanged,
    } = this.props;
    const { componentId } = params;
    let formChange = false;

    const noValue = value === '' && !value[id] && value[id] !== false;
    const isSameValue = values[id] && value === values[id];
    const isDifferentValue = values[id] !== value;

    if (isSameValue) return;
    let newValues: IRow = { ...values };

    if (isDifferentValue || (!newValues[id] && value == null) || noValue) {
      switch (true) {
        case deleteImage !== undefined:
          if (typeof multiSelectId === 'string')
            newValues = {
              ...newValues,
              [id]: value,
              imagesToDelete: newValues?.imagesToDelete
                ? [...newValues.imagesToDelete, deleteImage]
                : [deleteImage],
            };
          break;

        case multiSelectId !== undefined:
          if (typeof multiSelectId === 'string')
            newValues = this.getMultiSelectValues({
              id,
              values: newValues,
              value,
              multiSelectId,
            });
          break;

        default:
          if (
            (value === '' || value === null) &&
            newValues[id] !== undefined &&
            ['check', 'checkSelect'].includes(type)
          )
            newValues[id] = false;

          if (id.includes('.')) {
            const fk = id.slice(0, id.lastIndexOf('.'));
            if (newValues[fk] === null) delete newValues[fk];
          }

          if (id === ChallengeDateEnum.STARTDATE) {
            const code = value
              ? moment(value).format('YYYY_MM_') + newValues.code.substring(8)
              : 'YYYY_MM_' + newValues.code.substring(8);

            newValues = { ...newValues, code: code };
          }

          newValues = { ...newValues, [id]: value };
      }

      setFormData({
        componentId,
        values: newValues,
      });
    }

    //FORM HAS CHANGE VERIFICATION
    switch (true) {
      case !formHasChanged && !isEmpty(selectedRow) && isDifferentValue:
        setFormStateFlag({
          componentId: targetId,
          formHasChanged: true,
        });
        break;
      case isEmpty(selectedRow) && isDifferentValue:
        formChange = true;
        setFormStateFlag({
          componentId: targetId,
          formHasChanged: formChange,
        });
        break;
      case isEmpty(selectedRow) && !isDifferentValue:
        for (let key in newValues) {
          if (
            newValues[key] ||
            newValues[key] === 0 ||
            typeof selectedRow[key] === 'object'
          )
            formChange = true;

          setFormStateFlag({
            componentId: targetId,
            formHasChanged: formChange,
          });
          break;
        }
    }
  };

  constructResources = (resources: IRow) => {
    let recursiveResource: IResource[] = [];

    let sortedResources: IResource[] = [];
    resources.sort(function (a: IResource, b: IResource) {
      return a.idResource - b.idResource;
    });

    resources.sort(function (a: IResource, b: IResource) {
      return a.order - b.order;
    });

    resources.forEach((resource: IResource) => {
      if (resource.resourceDetailList)
        resource.resourceDetailList.sort(function (
          a: IResourceDetail,
          b: IResourceDetail,
        ) {
          return a.idResourceD - b.idResourceD;
        });
      if (resource.indResource !== null)
        recursiveResource.push({ ...resource, resourceList: [] });
      else sortedResources.push({ ...resource, resourceList: [] });
    });

    recursiveResource.forEach((_resource: IResource, i: number) => {
      recursiveResource.forEach((_destResource) => {
        if (_resource.indResource === _destResource.idResource)
          _destResource.resourceList?.push(_resource);
      });
    });

    recursiveResource.forEach((_resource: IResource) => {
      sortedResources.forEach((_destResource: IResource) => {
        if (_resource.indResource === _destResource.idResource) {
          _destResource.resourceList?.push(_resource);
        }
      });
    });

    const { selectedRow } = this.props;

    let sortedArr: IResource[] = [];

    if (
      selectedRow.idChallengeType.idChallengeType === TALK ||
      selectedRow.idChallengeType.idChallengeType === CONGRESS
    ) {
      sortedArr = [
        ...sortedResources.filter(
          (_resource) => _resource.idResourceType.idResourceType === EVENT,
        ),
        ...sortedResources.filter(
          (_resource) => _resource.idResourceType.idResourceType !== EVENT,
        ),
      ];
    } else if (selectedRow.idChallengeType.idChallengeType === FORMATION) {
      sortedArr = [
        ...sortedResources.filter(
          (_resource) => _resource.idResourceType.idResourceType === QUIZ,
        ),
        ...sortedResources.filter(
          (_resource) => _resource.idResourceType.idResourceType !== QUIZ,
        ),
      ];
    } else if (selectedRow.idChallengeType.idChallengeType === FORMATION_CON) {
      sortedArr = [
        ...sortedResources.filter(
          (_resource) => _resource.idResourceType.idResourceType === QUIZ_DERMO,
        ),
        ...sortedResources.filter(
          (_resource) => _resource.idResourceType.idResourceType !== QUIZ_DERMO,
        ),
      ];
    } else sortedArr = [...sortedResources];

    sortedArr.forEach((_resource: IResource, i: number) => {
      _resource.order = i;
    });

    return sortedArr;
  };

  handleUpdateRecord = async (
    e: React.MouseEvent,
    props: UpdateRecordProps,
    form: WrappedFormUtils,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const { params, updateChallengeData } = props;
    let { values } = props;
    const { intl, fields } = this.props;
    const { componentId } = params;
    form.validateFields(async (errors) => {
      if (errors) {
        //TODO: Moves custom validations to this place
        return console.debug('Form errors:', errors);
      } else {
        let dataError: JSX.Element[] = [];

        const formErrors: string[] = [];

        fields.forEach((field: IEditField) => {
          let isFieldMandatory = field.mandatory;
          if (
            isConExperience(values.idChallengeType.idChallengeType) &&
            ['brand', 'filters'].includes(field.key)
          )
            isFieldMandatory = false;

          if (
            isFieldMandatory &&
            (!values[field.key] ||
              (Array.isArray(values[field.key]) && isEmpty(values[field.key])))
          )
            formErrors.push(field.key);
        });

        const {
          startDate,
          startVisDate,
          endDate,
          endVisDate,
          showList,
          showDuration,
          duration,
          active,
          targetAchivement,
          targetEnrollement,
          coverVideo,
          slug,
        } = values;

        const numberAchivement = Number(targetAchivement);
        const numberEnrollement = Number(targetEnrollement);
        if (showList || active) {
          if (!startVisDate) formErrors.push('startVisDate');
          if (!endDate) formErrors.push('endDate');
          if (!startDate) formErrors.push('startDate');
        }

        if (isPINPlatform()) {
          if (isNaN(numberAchivement) || numberAchivement === 0) {
            formErrors.push('targetAchivement');
          }
          if (isNaN(numberEnrollement) || numberEnrollement === 0) {
            formErrors.push('targetEnrollement');
          }
          if (!duration && showDuration)
            dataError.push(
              <div>{` ${intl.formatMessage({
                id: 'challenge.minutes.required',
              })}`}</div>,
            );

          if (slug) {
            const validatedSlug = validateSlug(slug);

            if (!validatedSlug) {
              dataError.push(
                <div>{` ${intl.formatMessage({
                  id: 'challenge.slug.validations',
                })}`}</div>,
              );
            }
          }
        }

        if (!startVisDate && endVisDate) formErrors.push('startVisDate');

        if (startVisDate && endVisDate)
          if (moment(startVisDate).isAfter(endVisDate))
            dataError.push(
              <div>{` ${intl.formatMessage({
                id: 'dateComponent.endVisDate',
              })}`}</div>,
            );

        if (startDate && endDate)
          if (moment(startDate).isAfter(endDate))
            dataError.push(
              <div>{` ${intl.formatMessage({
                id: 'dateComponent.endDate',
              })}`}</div>,
            );

        if (startVisDate && startDate)
          if (moment(startVisDate).isAfter(startDate))
            dataError.push(
              <div>{` ${intl.formatMessage({
                id: 'dateComponent.startDate',
              })}`}</div>,
            );

        if (endDate && endVisDate)
          if (moment(endDate).isAfter(endVisDate))
            dataError.push(
              <div>{` ${intl.formatMessage({
                id: 'dateComponent.endVisDate2',
              })}`}</div>,
            );

        if (coverVideo) {
          if (!coverVideo) return;
          let videoType: string = getVideoTypeSelectorById('coverVideo');
          const isNotValidUrl = validateCoverVideoUrl(coverVideo, videoType);

          if (!isNotValidUrl.isValid)
            dataError.push(
              <div>{` ${intl.formatMessage({
                id: `challenge.cover-video.${videoType}.required`,
              })}`}</div>,
            );
        }

        if (isEmpty(formErrors) && isEmpty(dataError)) {
          const result: any = await updateChallengeData({
            dataPath: appComponents[componentId].path,
            componentId,
            values,
          });

          if (result?.status === 200) {
            if (values?.imagesToDelete) {
              api
                .deleteCall({
                  dataPath: `${currentPlatform}${apiPaths.UPLOAD_MEDIA}`,
                  callConfig: { data: { path: values.imagesToDelete } },
                })
                .catch((err) => {
                  console.error(err.message);
                });
            }
            this.refreshChallengeData();
          }
        } else {
          if (formErrors) {
            const jsxErrors = formErrors.map((errorKey) => {
              let jsxError: JSX.Element;
              this.props.fields.forEach((field) => {
                if (field.key === errorKey)
                  jsxError = <div>{` ${field.title}`}</div>;
              });
              return jsxError!;
            });
            dataError = [...dataError, ...jsxErrors];
          }

          notification.error({
            message: intl.formatMessage({
              id: 'alert.title.required',
            }),
            duration: 0,
            description: (
              <List
                size="small"
                renderItem={(item: JSX.Element) => (
                  <List.Item>{item}</List.Item>
                )}
                dataSource={dataError}
              />
            ),
          });
        }
      }
    });
  };

  handleResetChallenge = () => {
    // this.props.resetChallenge();
    this.props.setFormData({
      componentId: this.props.params.componentId,
      values: this.props.selectedRow,
    });
    this.props.setFormStateFlag({
      componentId: this.props.targetId,
      formHasChanged: false,
    });
  };
  refreshChallengeData = async () => {
    const { fetchRow, params, selectedRow, setLoading, targetId } = this.props;
    const { primaryKey } = params;
    setLoading(true);

    try {
      await fetchRow({
        dataPath: appComponents[targetId].path,
        primaryKey,
        registerId: selectedRow[primaryKey],
        targetId,
      });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  getMultiSelectValues = ({
    id,
    multiSelectId,
    values,
    value,
  }: {
    id: string;
    multiSelectId: string;
    values: IRow;
    value: any;
  }) => {
    const newValues = { ...values };

    if (!isArray(value)) value = Array.from(value);

    const currentArray = newValues[id];

    switch (true) {
      case !value:
        newValues[id] = [];
        break;
      case currentArray && !Array.isArray(currentArray):
        break;
      default:
        let newArray: IRow[] = [];

        // Check current Values
        currentArray?.forEach((val: IRow) => {
          value.forEach((multiSelectVal: string) => {
            if (multiSelectVal.toString() === val[multiSelectId].toString())
              newArray.push(val);
          });
        });

        // Check rest of elements in multiSelect
        value.forEach((multiSelectVal: string) => {
          let isElementIncluded = false;
          newArray.forEach((element: IRow) => {
            if (multiSelectVal.toString() === element[multiSelectId].toString())
              isElementIncluded = true;
          });
          if (!isElementIncluded)
            newArray = [...newArray, { [multiSelectId]: multiSelectVal }];
        });

        newValues[id] = newArray;
        break;
    }

    return newValues;
  };

  render() {
    return (
      <ChallengeRender
        {...{
          ...this.props,
          constructResources: this.constructResources,
          handleChangeField: this.handleChangeField,
          handleResetChallenge: this.handleResetChallenge,
          handleUpdateRecord: this.handleUpdateRecord,
          refreshChallengeData: this.refreshChallengeData,
          getMultiSelectValues: this.getMultiSelectValues,
        }}
      />
    );
  }
}

const mapStateToProps = (state: ReducersState, ownProps: IChallengeProps) => {
  return {
    availableResources: state.challenge.availableResources,
    challengeTemplate: state.challenge.challengeTemplate,
    challengeType: state.challenge.challengeType,
    combos: state.combos.combos,
    editingChallenge: state.challenge.editingChallenge,
    editingResource: state.challenge.editingResource,
    formHasChanged:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .formHasChanged,
    isLoading: state.challenge.isLoading
      ? state.challenge.isLoading
      : state.tables[appComponents[ownProps.params.componentId].targetId]
          .isLoading,
    queryParams: state.query.params,
    selectedRow:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .selectedRow,
    sortingResources: state.challenge.sortingResources,
    targetId: state.edits[ownProps.params.componentId].targetId,
    token: state.auth.accessToken,
    values: state.edits[ownProps.params.componentId].values,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createChallenge,
      createResourceFromTemplate,
      deleteResource,
      fetchRow,
      getTableData,
      loadChallengeData,
      resetChallenge,
      setChallengeInitialState,
      setEditionChallenge,
      setEditionResource,
      setFormData,
      setFormStateFlag,
      setInitialState,
      setLoading,
      setQuiz,
      setSelectedRow,
      updateChallengeData,
    },
    dispatch,
  );

const InjectedChallenge = injectIntl(Challenge);

export default connect(mapStateToProps, mapDispatchToProps)(InjectedChallenge);
