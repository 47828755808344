import React from 'react';
import { Button, Form, Row, Icon, Popconfirm } from 'antd';
import { isEmpty } from 'lodash';

import { checkBehaviour } from '../behaviourUtils';
import config from '../../config';
import customButtonsFile from '../../extensions/customButtons';

import '../FormStyles.css';
import { EditFormRenderProps } from './EditFormRender';
import { IButtonPermission } from '../formInterfaces';
import { IRow } from '../../app/AppInterfaces';

const ButtonGroup = Button.Group;

const checkButtonPermissions = (
  button: IButtonPermission,
  userPermissions: string[],
) => {
  let disabled;
  userPermissions.forEach((permission) => {
    button.permissions.forEach((perm) => {
      if (permission === perm.name) {
        disabled = perm.disabled;
      }
    });
  });
  return disabled;
};

const checkButtonBehaviours = (button: IButtonPermission, values: IRow) => {
  let disabled;
  button.behaviours!.forEach((behaviour) => {
    if (checkBehaviour(behaviour, values)) disabled = !button.defaultDisabled;
  });
  if (disabled === undefined) return button.defaultDisabled;
  else return disabled;
};

const checkDisabledButton = (
  values: IRow,
  userPermissions: string[],
  button?: IButtonPermission | boolean,
) => {
  let disabled;

  if (typeof button === 'object') {
    button.permissions
      ? (disabled = checkButtonPermissions(button, userPermissions))
      : (disabled = checkButtonBehaviours(button, values));
    if (disabled === undefined) disabled = button.defaultDisabled;
  } else disabled = !button;

  return disabled;
};

export const buttonsContainerTop = (parentProps: EditFormRenderProps) => {
  const {
    props,
    // handleAddData,
    handleDeleteData,
  } = parentProps;
  const {
    selectedRow,
    intl,
    options,
    values,
    userPermissions,
    params,
    // queryParams
  } = props;
  const {
    // canCreate,
    canDelete,
  } = options;
  const {
    // primaryKey,
    customButtons,
  } = params;

  // const createDisabled = checkDisabledButton(
  //   values,
  //   userPermissions!,
  //   canCreate
  // );

  // const { q } = queryParams;
  // let disableCreate = false;
  // if (!isEmpty(q)) {
  //   q!.split(config.QUERY.AND).forEach(element => {
  //     if (
  //       element.split(config.QUERY.ID_OPERATOR)[0].toString() === primaryKey &&
  //       element.split(config.QUERY.ID_OPERATOR)[1]
  //     )
  //       disableCreate = true;
  //   });
  // }
  // const createButton = (
  //   <Button
  //     disabled={disableCreate || createDisabled}
  //     icon="file-add"
  //     onClick={handleAddData}
  //     title={intl.formatMessage({ id: 'form.new' })}
  //   />
  // );

  let deleteDisable = checkDisabledButton(values, userPermissions!, canDelete);

  const deleteButton = config.FEEDBACK.CONFIRM.DELETE_RECORD ? (
    <Popconfirm
      disabled={isEmpty(values) || isEmpty(selectedRow) || deleteDisable}
      title={intl.formatMessage({ id: 'pop.title.delete' })}
      icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
      onConfirm={handleDeleteData}
      okText={intl.formatMessage({ id: 'pop.accept' })}
      cancelText={intl.formatMessage({ id: 'pop.cancel' })}>
      <Button
        type="danger"
        icon="delete"
        disabled={isEmpty(values) || isEmpty(selectedRow) || deleteDisable}
        title={intl.formatMessage({ id: 'form.delete' })}
      />
    </Popconfirm>
  ) : (
    <Button
      type="danger"
      icon="delete"
      disabled={isEmpty(values) || isEmpty(selectedRow) || deleteDisable}
      title={intl.formatMessage({ id: 'form.delete' })}
      onClick={handleDeleteData}
    />
  );

  return (
    <>
      <Row>
        <ButtonGroup className="form__upperButtons">
          {/* {createButton} */}
          {canDelete !== undefined && !isEmpty(selectedRow) && deleteButton}
        </ButtonGroup>
        {customButtons
          ? customButtons.map((customButton) =>
              customButtonsFile[customButton](parentProps),
            )
          : null}
      </Row>
    </>
  );
};

export const buttonsContainerBottom = (parentProps: EditFormRenderProps) => {
  const {
    props,
    handleUpdateRecord,
    handleResetForm,
    handleCloseForm,
    handleCreateRecord,
  } = parentProps;
  const {
    selectedRow,
    isLoading,
    intl,
    options,
    values,
    userPermissions,
    setDrawerVisibility,
    dashboardId,
    formHasChanged,
  } = props;
  const {
    canDuplicate,
    createAndSelect,
    canCreateClose,
    canCreate,
    canEdit,
    canEditClose,
  } = options;

  const createDisabled = checkDisabledButton(
    values,
    userPermissions!,
    canCreate,
  );
  const editDisabled = checkDisabledButton(values, userPermissions!, canEdit);
  const editCloseDisabled = checkDisabledButton(
    values,
    userPermissions!,
    canEditClose,
  );
  const duplicateDisabled = checkDisabledButton(
    values,
    userPermissions!,
    canDuplicate,
  );
  const createAndSelectDisabled = checkDisabledButton(
    values,
    userPermissions!,
    createAndSelect,
  );
  const createCloseDisabled = checkDisabledButton(
    values,
    userPermissions!,
    canCreateClose,
  );

  const createSelectButton = createAndSelect && (
    <Form.Item className="form__bottomButtons--floatRight">
      <Button
        icon="save"
        onClick={(e: React.MouseEvent) =>
          handleCreateRecord(e, undefined, true)
        }
        title={intl.formatMessage({ id: 'form.createSelect' })}
        // size="small"
        disabled={
          isLoading ||
          /*isEmpty(values) ||*/ createAndSelectDisabled ||
          !formHasChanged
        }
        loading={isLoading}>
        {intl.formatMessage({ id: 'form.createSelect' })}
      </Button>
    </Form.Item>
  );
  const createCloseButton = canCreateClose && (
    <Form.Item className="form__bottomButtons--floatRight">
      <Button
        icon="save"
        // htmlType="submit"
        title={intl.formatMessage({ id: 'form.createClose' })}
        // size="small"
        disabled={
          isLoading ||
          /*isEmpty(values) ||*/ createCloseDisabled ||
          !formHasChanged
        }
        loading={isLoading}
        onClick={(e: React.MouseEvent) => createAndClose(e)}>
        {intl.formatMessage({ id: 'form.createClose' })}
      </Button>
    </Form.Item>
  );

  const createCleanButton = canCreate && (
    <Form.Item className="form__bottomButtons--floatRight">
      <Button
        icon="save"
        onClick={(e: React.MouseEvent) => handleCreateRecord(e, undefined)}
        title="Crear y Limpiar"
        // size="small"
        disabled={
          isLoading || /*isEmpty(values) ||*/ createDisabled || !formHasChanged
        }
        loading={isLoading}>
        {intl.formatMessage({ id: 'form.create' })}
      </Button>
    </Form.Item>
  );

  const saveAndClose = async (e: React.MouseEvent) => {
    const drawerOptions = { setDrawerVisibility, dashboardId, visible: false };
    await handleUpdateRecord(e, undefined, drawerOptions);
  };

  const createAndClose = async (e: React.MouseEvent) => {
    const drawerOptions = { setDrawerVisibility, dashboardId, visible: false };
    await handleCreateRecord(e, undefined, true, drawerOptions);
  };

  return (
    <Row className="form__bottomButtons">
      {isEmpty(selectedRow) ? (
        <>
          {createCleanButton !== undefined ? createCleanButton : null}
          {createAndSelect !== undefined ? createSelectButton : null}
          {canCreateClose !== undefined ? createCloseButton : null}
        </>
      ) : (
        <React.Fragment>
          {canDuplicate !== undefined && (
            <Form.Item className="form__bottomButtons--floatRight">
              <Button
                icon="copy"
                // htmlType="submit"
                title={intl.formatMessage({ id: 'form.duplicate.reg' })}
                // size="small"
                onClick={(e: React.MouseEvent) =>
                  handleCreateRecord(e, undefined, true)
                }
                disabled={isLoading || isEmpty(values) || duplicateDisabled}
                loading={isLoading}>
                {intl.formatMessage({ id: 'form.duplicate' })}
              </Button>
            </Form.Item>
          )}
          {canEdit !== undefined && (
            <Form.Item className="form__bottomButtons--floatRight">
              <Button
                icon="save"
                title={intl.formatMessage({ id: 'form.save.reg' })}
                // size="small"
                onClick={handleUpdateRecord}
                disabled={
                  isLoading ||
                  isEmpty(values) ||
                  editDisabled ||
                  !formHasChanged
                }
                loading={isLoading}>
                {intl.formatMessage({ id: 'form.save' })}
              </Button>
            </Form.Item>
          )}
          {canEditClose !== undefined && (
            <Form.Item className="form__bottomButtons--floatRight">
              <Button
                icon="save"
                title={intl.formatMessage({ id: 'form.save.reg.close' })}
                // size="small"
                onClick={(e: React.MouseEvent) => saveAndClose(e)}
                disabled={
                  isLoading ||
                  isEmpty(values) ||
                  editCloseDisabled ||
                  !formHasChanged
                }
                loading={isLoading}>
                {intl.formatMessage({ id: 'form.save.close' })}
              </Button>
            </Form.Item>
          )}
        </React.Fragment>
      )}
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          icon="reload"
          // size="small"
          onClick={handleResetForm}
          title={intl.formatMessage({ id: 'form.reset' })}>
          {intl.formatMessage({ id: 'pop.restore' })}
        </Button>
      </Form.Item>
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          icon="close-square-o"
          // size="small"
          onClick={handleCloseForm}
          title={intl.formatMessage({ id: 'form.edit.cancel' })}>
          {intl.formatMessage({ id: 'form.edit.cancel' })}
        </Button>
      </Form.Item>
    </Row>
  );
};
