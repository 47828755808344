import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import {
  IndexedDetail,
  IResourceDetail,
  IResourceTree,
} from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const {
  CERTIFIED_COURSE_EXTERNAL_PLATFORM_TYPE,
  CERTIFIED_COURSE_ID_COURSE,
  EXPERTS_GROUP_ICON,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE,
  CLAUSE_ID,
} = ResourceTypeDetailEnum;

type Props = ReturnType<typeof mapStateToProps> & IResourceTree;

const BasicResourceModal = ({
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  values,
  combos,
}: Props): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  const getComboValues = (resourceDetail: IResourceDetail): ComboData[] => {
    const comboMap: {
      [key in ResourceTypeDetailEnum]?: ComboData[];
    } = {
      [CERTIFIED_COURSE_EXTERNAL_PLATFORM_TYPE]:
        combos.challengechallengeEdit?.externalPlatformType
          ?.externalPlatformType?.data,
      [CERTIFIED_COURSE_ID_COURSE]:
        combos.challengechallengeEdit?.externalCertifiedCourse
          ?.externalCertifiedCourse?.data,
      [EXPERTS_GROUP_ICON]:
        combos.challengechallengeEdit?.iconType?.iconType?.data,
      [ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE]:
        combos.challengechallengeEdit?.requestTypesType?.requestTypesType?.data,
      [CLAUSE_ID]:
        combos.challengechallengeEdit?.salesforceClauses?.salesforceClauses
          ?.data,
    };
    const {
      idResourceTypeD: { idResourceTypeD },
    } = resourceDetail;

    return comboMap[idResourceTypeD] ?? [];
  };

  return (
    <>
      {details
        .filter(({ detail: { status } }) => status)
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          return RenderDetail({
            accessToken,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            combo: getComboValues(detail),
            form,
            handleDeleteImages,
            resource,
            mainIdResource: resource.idResource,
            values,
          });
        })}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};

export default connect(mapStateToProps, {})(BasicResourceModal);
