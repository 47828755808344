import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatStaticPageDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatStaticPageSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'slug',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'contentPage.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'contentPage.slug' }),
          key: 'slug',
          type: 'text',
          operand: 'like',
        },
        {
          title: formatMessage({ id: 'contentPage.type' }),
          key: 'type',
          type: 'selector',
          selectorPath: '/static-page/static-page-types',
          comboId: 'contentPageType',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatStaticPageList',
        rowKey: 'idContentPage',
        panelHeader: 'Pages List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'slug',
      },
      fields: [
        {
          title: formatMessage({ id: 'contentPage.id' }),
          key: 'idContentPage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'contentPage.slug' }),
          key: 'slug',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'contentPage.type' }),
          key: 'type',
          render: 'selector',
          comboId: 'contentPageType',
          selectorPath: '/static-page/static-page-types',
          position: 3,
          mustRender: true,
          align: 'center',
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.publicPage' }),
          key: 'publicPage',
          render: 'comboCustom',
          comboId: 'optional',
          position: 4,
          mustRender: true,
          align: 'center',
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.header' }),
          key: 'header',
          render: 'comboCustom',
          comboId: 'optional',
          position: 5,
          mustRender: true,
          align: 'center',
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.footer' }),
          key: 'footer',
          render: 'comboCustom',
          comboId: 'optional',
          position: 6,
          mustRender: true,
          align: 'center',
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'contentPage.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 7,
          mustRender: true,
          align: 'center',
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    // Dummy edit component to be replaced for custom component in DashboardComposer render flow
    {
      params: {
        type: 'edit',
        componentId: 'webeatStaticPageEdit',
        panelHeader: 'Page Edit',
        primaryKey: 'idContentPage',
        tabIndex: 0,
        isWebeatContentPage: true,
      },
      groups: [],
      fields: [
        {
          title: formatMessage({ id: 'contentPage.type' }),
          key: 'type',
          type: 'selector',
          selectorPath: '/static-page/static-page-types',
          comboId: 'contentPageType',
        },
        {
          title: formatMessage({ id: 'contentPage.idRoleType' }),
          key: 'idRoleType',
          type: 'selector',
          selectorPath: '/static-page/role-types',
          comboId: 'roleType',
        },
      ],
      settings: {},
    },
  ];

  const componentId = 'WebeatStaticPageDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatStaticPageDashboard;
