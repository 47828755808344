import React from 'react';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ReducersState } from '../reducers';
import { appComponents } from '../components';
import moment from 'moment';

import {
  fetchRow,
  getTableData,
  setFormStateFlag,
  setSelectedRow,
} from '../tables/tableActions';
import {
  setFormData,
  setInitialState,
  updateEditData,
  createEditData,
} from '../forms/edit/editActions';

import { setPageInitialState, setLoading } from './redux/contentPageActions';

import { IEditField } from '../fields/FieldsInterfaces';
import { IRow, IRecord } from '../app/AppInterfaces';
import ContentPageRender from './ContentPageRender';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import config from '../config';

import '../challenge/Challenge.css';
import './ContentPage.css';
import { ContentPageEnum, IPageProps } from './shared';

export type IPageListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IPageProps;

class ContentPage extends React.PureComponent<IPageListProps> {
  constructor(props: IPageListProps) {
    super(props);
    const { params, setInitialState } = props;
    const { componentId } = params;

    setInitialState({
      componentId,
      targetId: appComponents[componentId].targetId,
      fieldsConfig: [],
      values: {},
      selectedTab: '0',
      resetFormTrigger: false,
    });
  }

  /* ----------  SHARED DASHBOARDS FUNCTIONS ---------- */

  async componentDidMount() {
    const {
      setPageInitialState,
      setFormData,
      selectedRow,
      params,
    } = this.props;

    setPageInitialState();
    setFormData({ componentId: params.componentId, values: selectedRow });
  }

  componentDidUpdate(prevProps: IPageListProps) {
    const { selectedRow, params, setFormData } = this.props;
    const { componentId } = params;
    if (prevProps.selectedRow && selectedRow !== prevProps.selectedRow) {
      setFormData({ componentId, values: selectedRow });
    }
  }

  async setFormInitialValues(fields: IEditField[]) {
    let values: IRow = {};

    fields.forEach((field) => {
      if (
        (field.mustRender === undefined || field.mustRender) &&
        (field.type === 'check' || field.type === 'checkSelect')
      ) {
        values[field.key] = false;
      }

      if (field.initialValue !== undefined) {
        values = { ...values, [field.key]: field.initialValue };
      }
    });

    return values;
  }

  handleChangeField = async ({
    id,
    value,
    type,
  }: {
    id: string;
    value: any;
    type: string;
  }) => {
    const {
      params,
      values,
      targetId,
      setFormStateFlag,
      setFormData,
      selectedRow,
      formHasChanged,
    } = this.props;
    const { componentId } = params;
    let formChange = false;

    if (values[id] && value === values[id]) return;

    if (
      value === '' &&
      (values[id] === undefined || values[id] === null || values[id] === '')
    )
      return;

    if (id === 'type' && values.type !== value) {
      delete values.idSpecialty;
    }

    if (
      value &&
      id === 'type' &&
      values.type !== value &&
      [ContentPageEnum.HOME, ContentPageEnum.HOME_PUBLIC].includes(
        Number(value),
      )
    )
      values.slug = '/';

    if (values[id] !== value || (!values[id] && value == null)) {
      if ((value === '' || value === null) && values[id] !== undefined) {
        if (type === 'check' || type === 'checkSelect') values[id] = false;
        // else delete values[id];
        setFormData({
          componentId,
          values: {
            ...values,
            [id]: value,
          },
        });
      } else {
        if (id.includes('.')) {
          const fk = id.slice(0, id.lastIndexOf('.'));
          if (values[fk] === null) delete values[fk];
        }
        setFormData({
          componentId,
          values: {
            ...values,
            [id]: value,
          },
        });
      }
    }

    if (!isEmpty(selectedRow)) {
      if (value !== values[id] && !formHasChanged) {
        setFormStateFlag({
          componentId: targetId,
          formHasChanged: true,
        });
      }
    } else if (isEmpty(selectedRow)) {
      if (value && !values[id]) formChange = true;
      else
        for (let key in values) {
          if (
            values[key] ||
            values[key] === 0 ||
            typeof selectedRow[key] === 'object'
          )
            formChange = true;
        }
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: formChange,
      });
    }
  };

  transformMomentToUTC = (values: IRow) => {
    Object.keys(values).forEach((key) => {
      if (moment.isMoment(values[key])) {
        values[key] = values[key].toISOString();
      }
    });
    return values;
  };

  /* ----------  CUSTOM PAGE COMPONENTS FUNCTIONS ---------- */

  refreshPageData = async () => {
    const { fetchRow, params, selectedRow, setLoading, targetId } = this.props;
    const { primaryKey } = params;
    setLoading(true);

    try {
      await fetchRow({
        dataPath: appComponents[targetId].path,
        primaryKey,
        registerId: selectedRow[primaryKey],
        targetId,
      });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  fetchNewData = async (row: any, dataPath: string) => {
    const {
      formHasChanged,
      getTableData,
      params,
      queryParams,
      setSelectedRow,
      setFormStateFlag,
      targetId,
    } = this.props;
    const { primaryKey } = params;

    const newRecords: any = await getTableData({
      dataPath,
      componentId: targetId,
      queryParams,
    });

    let newSelectedRow: IRow = {};
    'data' in newRecords &&
      newRecords.data.content.forEach((record: IRecord) => {
        if (record[primaryKey] === row[primaryKey]) newSelectedRow = record;
      });

    setSelectedRow({
      componentId: targetId,
      selectedRow: newSelectedRow,
    });

    if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged)
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: false,
      });
  };

  handleSaveRecord = (e: React.MouseEvent, form: WrappedFormUtils<any>) => {
    const {
      createEditData,
      params,
      targetId,
      updateEditData,
      values,
    } = this.props;
    const { componentId, primaryKey } = params;

    e.preventDefault();
    e.stopPropagation();

    form.validateFields(async (err, formValues) => {
      if (!err) {
        let result: any;

        if (!values.idContentPage) {
          // CREATE
          result = await createEditData({
            dataPath: appComponents[componentId].path,
            componentId,
            values,
          });
          if ('status' in result && result['status'] === 200)
            await this.fetchNewData(
              result.data,
              `${appComponents[targetId].path}/Page/${result['data'][primaryKey]}`,
            );
        } else {
          result = await updateEditData({
            dataPath: appComponents[componentId].path,
            componentId,
            values: { ...values, ...formValues },
          });
          if ('status' in result && result['status'] === 200)
            await this.fetchNewData(result.data, appComponents[targetId].path);
        }
      }
    });
  };

  handleResetPage = () => {
    const {
      params,
      selectedRow,
      setFormData,
      setFormStateFlag,
      targetId,
    } = this.props;

    setFormData({
      componentId: params.componentId,
      values: selectedRow,
    });
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: false,
    });
  };

  render() {
    return (
      <ContentPageRender
        {...{
          ...this.props,
          handleChangeField: this.handleChangeField,
          handleResetPage: this.handleResetPage,
          handleSaveRecord: this.handleSaveRecord,
          refreshPageData: this.refreshPageData,
        }}
      />
    );
  }
}

const mapStateToProps = (state: ReducersState, ownProps: IPageProps) => {
  return {
    combos: state.combos,
    editComponent: state.contentPages.editComponent,
    formHasChanged:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .formHasChanged,
    isLoading: state.contentPages.isLoading
      ? state.contentPages.isLoading
      : state.tables[appComponents[ownProps.params.componentId].targetId]
          .isLoading,
    queryParams: state.query.params,
    selectedRow:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .selectedRow,
    sortingComponents: state.contentPages.sortingComponents,
    targetId: state.edits[ownProps.params.componentId].targetId,
    token: state.auth.accessToken,
    values: state.edits[ownProps.params.componentId].values,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createEditData,
      fetchRow,
      getTableData,
      setPageInitialState,
      setFormData,
      setFormStateFlag,
      setInitialState,
      setLoading,
      setSelectedRow,
      updateEditData,
    },
    dispatch,
  );

const InjectedPage = injectIntl(ContentPage);

export default connect(mapStateToProps, mapDispatchToProps)(InjectedPage);
