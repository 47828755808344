import React, { FC, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Dropdown,
  Icon,
  Divider,
  Select,
} from 'antd';
import { InjectedIntlProps } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form';
import config from '../config';

const ButtonGroup = Button.Group;
interface SearchProps extends FormComponentProps {
  intl: typeof InjectedIntlProps;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  query: string;
  searchPlaceHolder: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setFilterType: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
  isPinEmbraceUsers?: boolean;
}

const SearchForm: FC<SearchProps> = ({
  form,
  handleSubmit,
  intl,
  query,
  searchPlaceHolder,
  setQuery,
  setFilterType,
  setFilterStatus,
  isPinEmbraceUsers = false,
}) => {
  const [isDisabledInputText, setIsDisabledInputText] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [customSearchPlaceHolder, setCustomSearchPlaceHolder] =
    useState(searchPlaceHolder);

  // Dropdown component visibility
  const handleFilterVisibility = () => {
    setShowFilter(true);
  };

  // Configure search: by 'email' or (user) 'name'
  const handleSetSearchType = (value: string) => {
    // Capitalize first character
    setFilterType(`${value.charAt(0).toUpperCase()}${value.slice(1)}`);

    value === '' ? setIsDisabledInputText(true) : setIsDisabledInputText(false);

    if (isPinEmbraceUsers)
      setCustomSearchPlaceHolder(`Introduce user ${value}...`);
  };

  const handleSetFilterStatus = (value: string) => {
    setFilterStatus(value);
  };

  const handleButtonCancel = () => {
    setShowFilter(false);
  };

  const handleButtonApply = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setShowFilter(false);
  };

  // Button 'Filter'
  const dropDownButton = () => {
    return (
      <Dropdown
        overlay={dropDownFields()}
        trigger={['click']}
        placement="bottomCenter"
        overlayClassName="formDropdown"
        visible={showFilter}>
        <Button size="default" onClick={() => handleFilterVisibility()}>
          {window.innerWidth > config.BREAKPOINTS.MD &&
            intl.formatMessage({ id: 'form.filter' })}
          <Icon type="sliders" />
        </Button>
      </Dropdown>
    );
  };

  const dropDownFields = () => {
    return (
      <>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select search type"
          optionFilterProp="children"
          onChange={(value: string) => handleSetSearchType(value)}>
          <Select.Option value="email">Email</Select.Option>
          <Select.Option value="name">Name</Select.Option>
        </Select>

        {isPinEmbraceUsers && (
          <Select
            showSearch
            style={{ width: 200, paddingLeft: 20 }}
            placeholder="Select Status"
            defaultValue={'active'}
            optionFilterProp="children"
            onChange={(value: string) => handleSetFilterStatus(value)}>
            <Select.Option value={'active'}>Active</Select.Option>
            <Select.Option value={'deleted'}>Deleted</Select.Option>
          </Select>
        )}

        <Divider className="form__divider" />

        <Row className="form__bottomButtons">
          <Form.Item className="form__bottomButtons--floatRight">
            <Button
              title={intl.formatMessage({ id: 'form.search.close' })}
              disabled={false}
              onClick={handleButtonCancel}>
              {intl.formatMessage({ id: 'form.search.close' })}
            </Button>
          </Form.Item>
          <Form.Item className="form__bottomButtons--floatRight">
            <Button
              title={intl.formatMessage({ id: 'form.search.apply' })}
              type="primary"
              disabled={false}
              onClick={(e: React.MouseEvent) => handleButtonApply(e)}>
              {intl.formatMessage({ id: 'form.apply' })}
            </Button>
          </Form.Item>
        </Row>
      </>
    );
  };

  return (
    <Row>
      <Form
        className="form"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }>
        <Row className="form__front">
          <Col xs={12} sm={10} md={8} lg={6} xl={6} xxl={6}>
            <Form.Item>
              {form.getFieldDecorator('global_search', {
                initialValue: query,
              })(
                <Input.Search
                  type="text"
                  title="Text search"
                  style={{ verticalAlign: 'top' }}
                  placeholder={customSearchPlaceHolder}
                  disabled={isPinEmbraceUsers ? isDisabledInputText : false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQuery(e.target.value)
                  }
                  enterButton={
                    <Button
                      title={intl.formatMessage({
                        id: 'form.search',
                      })}
                      icon="search"
                      htmlType="submit"
                    />
                  }
                  allowClear
                />,
              )}
            </Form.Item>
          </Col>
          {isPinEmbraceUsers && (
            <ButtonGroup style={{ marginTop: '-1.5px' }}>
              {dropDownButton()}
            </ButtonGroup>
          )}
        </Row>
      </Form>
    </Row>
  );
};

export default Form.create<SearchProps>({ name: 'custom_search_form' })(
  SearchForm,
);
