import { Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import * as api from '../api';

import '../forms/FormStyles.css';
import CustomSearch from './CustomSearch';
import CustomTable from './CustomTable';
import './IsdinCustom.css';

import { IRecord } from '../app/AppInterfaces';
import { UserStatus } from './Shared';

interface Props {
  intl: typeof InjectedIntlProps;
  columns: ColumnProps<IRecord>[];
  customButtons?: Array<
    (
      selectedRow: IRecord,
      loading: boolean,
      reloadData: () => Promise<void>,
    ) => JSX.Element
  >;
  path: string;
  rowKey: string;
  searchField: string;
  entityKey: string;
  searchPlaceHolder: string;
  filter?: string;
  isPinEmbraceUsers?: boolean;
}

export const CUSTOM_DEFAULT_SIZE = 50;

const getDefaultSortName = (columns: ColumnProps<IRecord>[]) => {
  const defaultColumn = columns.filter((column) => column.defaultSortOrder);
  return defaultColumn[0].dataIndex || '';
};

export function CustomDashboard({
  columns,
  customButtons,
  entityKey,
  intl,
  path,
  rowKey,
  searchField,
  searchPlaceHolder,
  filter = '',
  isPinEmbraceUsers = false,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<IRecord[]>([]);
  const [selectedRow, setSelectedRow] = useState<IRecord>({});

  const [query, setQuery] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState<string>(UserStatus.ACTIVE);
  const [page, setPage] = useState(0);
  let statusUsers = '';
  const [sort, setSort] = useState<{ name: string; order: 'ASC' | 'DESC' }>({
    name: getDefaultSortName(columns),
    order: 'ASC',
  });

  const defaultOffset = { page: 0, offset: '' };

  const [savedOffsets, setSavedOffset] = useState<
    { page: number; offset: string }[]
  >([defaultOffset]);

  const fetchData = async ({
    params,
    showMore,
    showLess,
    reset,
    isSubmit,
  }: {
    params: string;
    showMore?: boolean;
    showLess?: boolean;
    reset?: boolean;
    isSubmit?: boolean;
  }) => {
    try {
      setLoading(true);

      let response = await api.getDataCall({
        dataPath: `${path}/${params}`,
        callConfig: {},
      });

      if (Array.isArray(response.data?.[entityKey]))
        setDataSource(response.data[entityKey]);

      if (response.data?.offset) {
        const fetchedOffset: string = response.data.offset;

        if (isSubmit) {
          let newOffsets = savedOffsets.slice();

          newOffsets[newOffsets.length - 1].offset = fetchedOffset;

          setSavedOffset(newOffsets);
        }

        if (reset) {
          setSavedOffset([{ page: 0, offset: fetchedOffset }]);
        }

        if (showMore) {
          const newPage: number = page + 1;

          setSavedOffset([
            ...savedOffsets,
            { page: newPage, offset: fetchedOffset },
          ]);
        }

        if (showLess) {
          const newOffsets = JSON.parse(JSON.stringify(savedOffsets));

          newOffsets.splice(savedOffsets.length - 1, 1);

          setSavedOffset(newOffsets);
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.group(err);
    }
  };

  const getParams = ({
    newSort,
    offset,
  }: {
    newSort?: { name: string; order: 'ASC' | 'DESC' };
    offset: string;
  }) => {
    const order = newSort?.order ?? sort.order;
    const name = newSort?.name ?? sort.name;

    if (isPinEmbraceUsers) {
      statusUsers = filterStatus === UserStatus.ACTIVE ? 'true' : 'false';
      if (filterType !== '') searchField = `user${filterType}`;
    }

    let queryParams = `?q=${filter}${searchField}:${query}&sort=${order}&field=${name}&size=${CUSTOM_DEFAULT_SIZE}&offset=${offset}&platformStatus=${statusUsers}`;

    return queryParams;
  };

  const handleSearchMore = async () => {
    const newPage = page + 1;

    const params = getParams({
      offset: savedOffsets[savedOffsets.length - 1].offset,
    });
    await fetchData({ params, showMore: true });
    setPage(newPage);
  };

  const handleSearchLess = async () => {
    const newPage = page - 1;

    const newOffset =
      savedOffsets.length >= 3
        ? savedOffsets[savedOffsets.length - 3].offset
        : '';

    const params = getParams({
      offset: newOffset,
    });

    await fetchData({ params, showLess: true });

    setPage(newPage);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    await refreshGrid();
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: {
      field: string;
      order: string;
    },
  ) => {
    const field: string = sorter.field;
    const order: string = sorter.order;

    setPage(0);
    setSavedOffset([defaultOffset]);

    const newSort: { name: string; order: 'ASC' | 'DESC' } = {
      name: field,
      order: order === 'ascend' ? 'ASC' : 'DESC',
    };

    setSort({ ...newSort });

    const params = getParams({ newSort, offset: '' });
    await fetchData({ params, reset: true });
  };

  const refreshGrid = async () => {
    const offset =
      savedOffsets.length > 1
        ? savedOffsets[savedOffsets.length - 1].offset
        : '';

    const params = getParams({
      offset,
    });

    await fetchData({ params, isSubmit: true });
  };

  return (
    <Row>
      <CustomSearch
        {...{
          intl,
          query,
          setQuery,
          handleSubmit,
          searchPlaceHolder,
          setFilterType,
          setFilterStatus,
          isPinEmbraceUsers,
        }}
      />
      {customButtons && (
        <Row style={{ marginBottom: '10px' }}>
          {customButtons.map((button) =>
            button(selectedRow, loading, refreshGrid),
          )}
        </Row>
      )}
      <CustomTable
        {...{
          columns,
          dataSource,
          handleTableChange,
          loading,
          page,
          rowKey,
          selectedRow,
          setSelectedRow,
          handleSearchMore,
          handleSearchLess,
        }}
      />
    </Row>
  );
}
