import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import { Menu, Spin } from 'antd';

import IconMenu from '../iconMenu';

import { MenuItem } from './AppInterfaces';

import './SideMenu.css';

const { Item, SubMenu } = Menu;

interface TSideMenuListProps {
  menu: MenuItem[] | null;
  pathname: string;
  navigate: Function;
}

const sortByName = (a: MenuItem, b: MenuItem) => {
  if (a.nameOfMenu < b.nameOfMenu) {
    return -1;
  }
  if (a.nameOfMenu > b.nameOfMenu) {
    return 1;
  }
  return 0;
};

export class SideMenu extends React.PureComponent<TSideMenuListProps, {}> {
  renderMenu = (menu: MenuItem[]) =>
    menu
      .filter((menuItem) => menuItem.mustRender)
      .sort(sortByName)
      .sort((a: MenuItem, b: MenuItem) => a.order - b.order)
      .map(({ id, route, nameOfMenu, icon, subMenu, disabled }) =>
        isEmpty(subMenu) ? (
          <Item
            key={route || nameOfMenu}
            disabled={disabled}
            onClick={() =>
              this.props.navigate({
                path: route,
                name: nameOfMenu,
                icon,
                absolute: true,
              })
            }>
            <>
              <div className="anticon iconSideMenu">
                {IconMenu({ id: icon })}
              </div>
              <span>{nameOfMenu}</span>
            </>
          </Item>
        ) : (
          <SubMenu
            key={route || nameOfMenu}
            disabled={disabled}
            title={
              <Fragment>
                <div className="anticon iconSideMenu">
                  {IconMenu({ id: icon })}
                </div>
                <span>{nameOfMenu}</span>
              </Fragment>
            }>
            {this.renderMenu(subMenu)}
          </SubMenu>
        ),
      );

  render() {
    return (
      <Menu
        mode="inline"
        theme="dark"
        selectedKeys={[this.props.pathname]}
        defaultSelectedKeys={[this.props.pathname]}>
        {this.props.menu ? this.renderMenu(this.props.menu) : <Spin />}
        <div style={{ height: 45 }} />
      </Menu>
    );
  }
}

export default SideMenu;
