import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const MenuDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'bomenumenuSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'nameOfMenu',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'welcome',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'disabled',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: false,
                  width: 6,
                },
                {
                  key: 'icon',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: false,
                  width: 6,
                },
                {
                  key: 'id',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: false,
                  width: 6,
                },
                {
                  key: 'idPermissions.idPermissions',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: false,
                  width: 6,
                },

                {
                  key: 'optimisticLocking',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: false,
                  width: 6,
                },
                {
                  key: 'parentId',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: false,
                  width: 6,
                },
                {
                  key: 'route',
                  yPosition: 2,
                  xPosition: 4,
                  mustRender: false,
                  width: 6,
                },

                {
                  key: 'visible',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: false,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'menu.disabled' }),
          key: 'disabled',
          type: 'checkselect',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.icon' }),
          key: 'icon',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.id' }),
          key: 'id',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.permission' }),
          key: 'idPermissions.idPermissions',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'bopermissions',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.name' }),
          key: 'nameOfMenu',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.parent' }),
          key: 'parentId',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'bomenu',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.route' }),
          key: 'route',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          initialValue: 'true',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.visible' }),
          key: 'visible',
          type: 'checkselect',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.welcome' }),
          key: 'welcome',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'bomenumenuList',
        rowKey: 'id',
        panelHeader: formatMessage({ id: 'menu.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'nameOfMenu',
      },
      fields: [
        // {
        //   title: 'Disabled',
        //   key: 'disabled',
        //   position: 0,
        //   mustRender: false,
        //   align: 'left',
        //   sorter: false,
        //   visible: true,
        // },
        {
          title: formatMessage({ id: 'menu.id' }),
          key: 'id',
          position: 0,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'menu.name' }),
          key: 'nameOfMenu',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'menu.icon' }),
          key: 'icon',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: 80,
        },
        {
          title: formatMessage({ id: 'menu.route' }),
          key: 'route',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'menu.permission' }),
          key: 'idPermissions.name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        // {
        //   title: 'Optimistic Locking',
        //   key: 'optimisticLocking',
        //   position: 5,
        //   mustRender: false,
        //   align: 'left',
        //   sorter: false,
        //   visible: true,
        // },
        {
          title: formatMessage({ id: 'menu.parent' }),
          key: 'parentId',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'bomenu',
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'menu.status' }),
          key: 'status',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: SMALL,
        },
        // {
        //   title: 'Visible',
        //   key: 'visible',
        //   position: 9,
        //   mustRender: false,
        //   align: 'left',
        //   sorter: false,
        //   visible: true,
        // },
        {
          title: formatMessage({ id: 'menu.welcome' }),
          key: 'welcome',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'comboCustom',
          comboId: 'optional',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'bomenumenuEdit',
        panelHeader: formatMessage({ id: 'menu.edit' }),
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'nameOfMenu',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'route',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'icon',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },

                {
                  key: 'parentId',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'idPermissions.idPermissions',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'textMachine',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },

                {
                  key: 'disabled',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'visible',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'welcome',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'menu.disabled' }),
          key: 'disabled',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.icon' }),
          key: 'icon',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.permission' }),
          key: 'idPermissions.idPermissions',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'bopermissions',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.name' }),
          key: 'nameOfMenu',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.parent' }),
          key: 'parentId',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'bomenu',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.route' }),
          key: 'route',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'id',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'menu.visible' }),
          key: 'visible',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'menu.welcome' }),
          key: 'welcome',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'MenuDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default MenuDashboard;
