import React, { useEffect, useState } from 'react';
import { ReducersState } from '../reducers';
import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { appComponents } from '../components';
import {
  constructPage,
  ContentPageEnum,
  IPageComponent,
  isChallengeComponent,
  PageComponentChallengeRestrictions,
  PageComponentEnum,
  usePrevious,
} from './shared';
import { isEmpty } from 'lodash';
import {
  addDetail,
  createComponent,
  deleteComponent,
  deleteDetail,
  editDetail,
  resetComponent,
  saveSorting,
  setComponentHasChanged,
  setEditComponent,
  setSelectedComponent,
  setSortingComponents,
  updateComponent,
} from './redux/contentPageActions';
import { Button, Card, Col, Icon, Popover, Row } from 'antd';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { IEditParams } from '../forms/formInterfaces';
import { ComponentForm } from './components/ComponentForm';
import * as api from '../api';
import { capitalize } from 'lodash';
import { isConPlatform } from '../utils';

const { HOME, STATIC_MEDIA, ONLY_WYSWYG, HOME_PUBLIC } = ContentPageEnum;
const {
  BANNER,
  CAROUSEL,
  FAQ,
  GRID,
  LINKED_IMAGE,
  PRODUCT_SECTION,
  REDEEM_POINTS,
  REGISTER_SECTION,
  SCAN_CTA,
  TEXT,
  VIDEO_SECTION,
  WYSWYG,
  CHALLENGE_TYPE,
  USER_POINTS,
} = PageComponentEnum;
const { ANNUAL_CAMPAIGN } = PageComponentChallengeRestrictions;

interface OwnProps {
  params: IEditParams;
  refreshPageData: () => Promise<void>;
}

interface IComponentType {
  value: number;
  description: string;
}

export type IPageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

function PageComponents(props: IPageProps) {
  const [components, setComponents] = useState<IPageComponent[]>([]);
  const {
    createComponent,
    editComponent,
    formHasChanged,
    params,
    refreshPageData,
    saveSorting,
    selectedRow,
    setSortingComponents,
    sortingComponents,
  } = props;
  const [componentTypes, setComponentTypes] = useState<IComponentType[]>([]);
  const { primaryKey } = params;
  const { formatMessage } = useIntl();

  const prevSelectedRow = usePrevious(selectedRow);
  const prevComponents = usePrevious(components);

  const resetComponents = () => {
    setComponents(constructPage(selectedRow.pageComponents));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.getCombo({ id: 'challenge_type' });

        const dynamicTypes = response.data
          .filter((key: any) => key.value !== ANNUAL_CAMPAIGN)
          .map((challengeType: any) => {
            const multiplier = challengeType.value < 10 ? 10 : 100;

            const filterNumber = CHALLENGE_TYPE * multiplier;

            return {
              value: challengeType.value + filterNumber,
              description: challengeType.description,
            };
          });

        const fixedTypes = Object.keys(PageComponentEnum)
          .filter((key: any) => !isNaN(key))
          .filter((key: any) => key !== CHALLENGE_TYPE.toString())
          .map((key: any) => ({
            value: Number(key),
            description: PageComponentEnum[key],
          }));

        setComponentTypes([...fixedTypes, ...dynamicTypes]);
      } catch (err) {
        console.error(err);
        return [];
      }
    })();
  }, []);

  useEffect(() => {
    if (!selectedRow || isEmpty(selectedRow)) {
      setComponents([]);
    } else {
      if (!prevComponents || prevSelectedRow !== selectedRow) {
        resetComponents();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, components]);

  /* ----------  SORTING - HELPER FUNCTIONS ---------- */

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgrey' : 'transparent',
    ...draggableStyle,
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    if (result.destination) {
      const origenIndex = result.source.index;
      const destIndex = result.destination.index;

      let componentsArray: IPageComponent[] = JSON.parse(
        JSON.stringify(components),
      );

      componentsArray[origenIndex].order = destIndex;

      if (destIndex < origenIndex) {
        for (let i = destIndex; i < origenIndex; i++) {
          componentsArray[i].order += 1;
        }
        setComponents(componentsArray);
      } else if (destIndex > origenIndex) {
        for (let i = destIndex; i > origenIndex; i--) {
          componentsArray[i].order -= 1;
        }

        setComponents(componentsArray);
      }

      return;
    }
  };

  const handleSaveSorting = async () => {
    const componentsPayload: {
      idContentComponent: number;
      order: number;
    }[] = [];
    components.forEach((_component: IPageComponent) => {
      componentsPayload.push({
        idContentComponent: _component.idContentComponent,
        order: _component.order,
      });
    });

    try {
      await saveSorting(
        selectedRow[primaryKey],
        primaryKey,
        'contentPagecontentPageList',
        componentsPayload,
      );
    } catch (err) {
      console.group('Error while saving components', err);
    }
  };

  const handleCancelSorting = () => {
    resetComponents();
    setSortingComponents(false);
  };

  const isAvailableComponent = (key: string) => {
    const commonComponents = [BANNER, CAROUSEL, LINKED_IMAGE, TEXT, WYSWYG];

    const publicComponentsPin = [...commonComponents];
    const publicComponentsCon = [
      ...commonComponents,
      CHALLENGE_TYPE,
      FAQ,
      GRID,
      SCAN_CTA,
      USER_POINTS,
      PRODUCT_SECTION,
      REGISTER_SECTION,
      VIDEO_SECTION,
    ];

    const publicComponents = isConPlatform()
      ? publicComponentsCon
      : publicComponentsPin;

    const privateComponentsPin = [
      ...commonComponents,
      REDEEM_POINTS,
      CHALLENGE_TYPE,
    ];
    const privateComponentsCon = [
      ...commonComponents,
      REDEEM_POINTS,
      CHALLENGE_TYPE,
      FAQ,
      GRID,
      SCAN_CTA,
      USER_POINTS,
      PRODUCT_SECTION,
      REGISTER_SECTION,
      VIDEO_SECTION,
    ];

    const privateComponents = isConPlatform()
      ? privateComponentsCon
      : privateComponentsPin;

    if (isChallengeComponent(Number(key))) key = CHALLENGE_TYPE.toString();
    switch (true) {
      case selectedRow.type === HOME && !selectedRow.publicPage:
      case selectedRow.type === STATIC_MEDIA:
        return privateComponents.includes(Number(key));
      case selectedRow.type === ONLY_WYSWYG:
        return Number(key) === WYSWYG;
      case selectedRow.type === HOME_PUBLIC:
      case selectedRow.type === HOME && selectedRow.publicPage:
        return publicComponents.includes(Number(key));
    }

    return false;
  };

  const getComponentIcon = (id: number) => {
    if (isChallengeComponent(id)) id = CHALLENGE_TYPE;

    switch (id) {
      case TEXT:
        return 'font-size';
      case CAROUSEL:
        return 'play-circle';
      case BANNER:
        return 'picture';
      case FAQ:
        return 'question-circle';
      case GRID:
        return 'appstore';
      case LINKED_IMAGE:
        return 'file-image';
      case CHALLENGE_TYPE:
        return 'star';
      case WYSWYG:
        return 'pic-left';
      case SCAN_CTA:
        return 'qrcode';
      case REDEEM_POINTS:
        return 'gift';
      case REGISTER_SECTION:
        return 'login';
      case PRODUCT_SECTION:
        return 'shop';
      case VIDEO_SECTION:
        return 'video-camera';
      case USER_POINTS:
        return 'user';
      default:
        return 'question';
    }
  };

  const handleAddComponent = async (type: number) => {
    const order =
      selectedRow.pageComponents !== undefined
        ? selectedRow.pageComponents.length
        : 0;

    const newComponent: any = await createComponent({
      idContentPage: selectedRow.idContentPage,
      order,
      type,
    });

    if (
      'status' in newComponent &&
      newComponent.status === 200 &&
      newComponent.data
    )
      refreshPageData();
  };

  const formatCardTitle = (component: any) => {
    if (!isChallengeComponent(component.value)) {
      return formatMessage({
        id: `component-type.${component.description.toLowerCase()}`,
      });
    }

    if (component.description === 'FORMATION_CON')
      component.description = 'FORMATION';

    return capitalize(component.description);
  };

  const validateWYSWYGPage = (): boolean => {
    return (
      selectedRow?.type === ONLY_WYSWYG &&
      components.filter(({ type }) => type === WYSWYG).length === 1
    );
  };

  /* ----------  SORTING - RENDER COMPONENTS ---------- */
  const shouldAddComponents =
    editComponent ||
    formHasChanged ||
    sortingComponents ||
    validateWYSWYGPage();

  const componentsSelector = shouldAddComponents ? (
    <Button className="buttonNoFrame" disabled type="primary" size="small">
      {formatMessage({ id: 'component-type.add-component' })}
    </Button>
  ) : (
    <Popover
      placement={isEmpty(components) ? 'top' : 'bottom'}
      content={
        <Row className="CardPopover__container" type="flex" gutter={[16, 16]}>
          {componentTypes
            .filter((component) =>
              isAvailableComponent(component.value.toString()),
            )
            .map((component: IComponentType, i: number) => (
              <Col span={6} key={i}>
                <Card
                  onClick={() => handleAddComponent(component.value)}
                  className={'CardPopover__card '}
                  key={i}
                  cover={<Icon type={getComponentIcon(component.value)} />}>
                  <Card.Meta title={formatCardTitle(component)} />
                </Card>
              </Col>
            ))}
        </Row>
      }>
      <Button type="primary" size="small" className="buttonNoFrame">
        {formatMessage({ id: 'component-type.add-component' })}
      </Button>
    </Popover>
  );

  if (components) {
    return (
      <Row className="ResourcesRender">
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          className="collapseFrame">
          <div className="ResourcesRender__title">
            {formatMessage({ id: 'contentPage.components' })}
          </div>
          <div className="ResourcesRender__titleButtons">
            {sortingComponents ? (
              <>
                <Button
                  className="buttonNoFrame"
                  type="primary"
                  size="small"
                  onClick={async () => handleSaveSorting()}>
                  {formatMessage({ id: 'form.save' })}
                </Button>
                <Button
                  className="buttonNoFrame"
                  type="primary"
                  size="small"
                  onClick={() => handleCancelSorting()}>
                  {formatMessage({ id: 'form.edit.cancel' })}
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={editComponent || formHasChanged}
                  size="small"
                  type="primary"
                  className="buttonNoFrame"
                  onClick={() => setSortingComponents(true)}>
                  {formatMessage({ id: 'contentPage.order' })}
                </Button>
                {componentsSelector}
              </>
            )}
          </div>
        </Row>
        {sortingComponents ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Row className="ResourcesRender__resource">
                    {components
                      .sort(
                        (a: IPageComponent, b: IPageComponent) =>
                          a.idContentComponent - b.idContentComponent,
                      )
                      .sort(
                        (a: IPageComponent, b: IPageComponent) =>
                          a.order - b.order,
                      )
                      .map(
                        (component: IPageComponent, index: number) =>
                          component.status && (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}>
                              {(
                                provided: DraggableProvided,
                                snapshot: DraggableStateSnapshot,
                              ) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}>
                                  <ComponentForm {...{ component, ...props }} />
                                </div>
                              )}
                            </Draggable>
                          ),
                      )}
                    {provided.placeholder}
                  </Row>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Row className="ResourcesRender__resource">
            {components
              .sort(
                (a: IPageComponent, b: IPageComponent) =>
                  a.idContentComponent - b.idContentComponent,
              )
              .sort((a: IPageComponent, b: IPageComponent) => a.order - b.order)
              .map(
                (component: IPageComponent, index: number) =>
                  component.status && (
                    <ComponentForm key={index} {...{ component, ...props }} />
                  ),
              )}
          </Row>
        )}
      </Row>
    );
  } else return null;
}

const mapStateToProps = (state: ReducersState, ownProps: OwnProps) => {
  return {
    accessToken: state.auth.accessToken,
    componentHasChanged: state.contentPages.componentHasChanged,
    editComponent: state.contentPages.editComponent,
    formHasChanged:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .formHasChanged,
    isLoading: state.contentPages.isLoading
      ? state.contentPages.isLoading
      : state.tables[appComponents[ownProps.params.componentId].targetId]
          .isLoading,
    selectedComponent: state.contentPages.selectedComponent,
    selectedRow:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .selectedRow,
    sortingComponents: state.contentPages.sortingComponents,
    challengeTypeCombo: state.contentPages.challengeTypeCombo,
    productsCombo: state.contentPages.productsCombo,
    gridProductsCombo: state.contentPages.gridProductsCombo,
    values: state.edits[ownProps.params.componentId].values,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      addDetail,
      createComponent,
      deleteComponent,
      deleteDetail,
      editDetail,
      resetComponent,
      saveSorting,
      setComponentHasChanged,
      setEditComponent,
      setSelectedComponent,
      setSortingComponents,
      updateComponent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PageComponents);
